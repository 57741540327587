import React, { Component } from 'react'
import Divider from 'material-ui/Divider'
import FontIcon from 'material-ui/FontIcon'
import MenuItem from 'material-ui/MenuItem'
import { charcoal } from '../../styles/colors'
import { menuFontSize } from '../../styles/typography'
import { navBarHeight } from '../../styles/dimensions'
import { ROUTES } from '../../lib/constants'
import { withRouter } from 'react-router-dom'
import { logout, getAdminNoteLink } from '../../lib/util'

const styles = {
  userMenu: {
    color: charcoal,
    fontSize: menuFontSize,
    height: '100%',
    minHeight: 0,
    lineHeight: navBarHeight,
    fontWeight: 500
  },
  inner: {
    paddingRight: 42
  },
  arrow: {
    fontSize: 48,
    margin: 0,
    height: navBarHeight,
    width: navBarHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuItem: {
    color: charcoal
  },
  disabledMenuItem: {
    color: '#DCDCDC',
    cursor: 'default'
  },
  divider: {
    backgroundColor: '#DCDCDC'
  }
}

class UserMenu extends Component {
  renderMenu = () => {
    const menuItems = []
    const navItems = [
      <MenuItem
        value='notes-to-process'
        primaryText='Notes to Process'
        style={styles.menuItem}
        onClick={this.handleToScribeNotes}
        key='notes-to-process'
        data-cy='menu-item-ntp'
      />,
      <MenuItem
        value='verbatim-transcriptions'
        primaryText='Verbatim Transcriptions'
        style={styles.menuItem}
        onClick={this.handleToScribeVerbatimNotes}
        key='verbatim-transcriptions'
        data-cy='menu-item-verbatim-notes'
      />,
      <Divider style={styles.divider} key='divider-1' />
    ]

    menuItems.push.apply(menuItems, navItems)

    // DISABLED: let them use the link from the note view
    // Can't tell if the note is patientnote or not from here
    // if (isNoteView() && isAdmin()) {
    //   menuItems.push(
    //     <MenuItem
    //       value='admin-view'
    //       primaryText='See in Admin View'
    //       style={styles.menuItem}
    //       onClick={this.handleToAdminNote}
    //       data-cy={'menu-item-see-in-admin-view'}
    //     />
    //   )
    //   menuItems.push(<Divider style={styles.divider} />)
    // }

    menuItems.push(
      <MenuItem
        value='signout'
        primaryText='Sign Out'
        style={styles.menuItem}
        onClick={() => logout()}
        data-cy='menu-item-log-out'
      />
    )

    return menuItems
  }

  handleToScribeNotes = () => {
    this.props.history.push(ROUTES.NOTES_TO_PROCESS)
  }

  handleToScribeVerbatimNotes = () => {
    this.props.history.push(ROUTES.VERBATIM_TRANSCRIPTS)
  }

  handleToAdminNote = () => {
    window.open(getAdminNoteLink(false))
  }

  render () {
    const primaryText = <span>{this.props.user}</span>
    return (
      <div id='topNavUserMenu' style={this.props.style}>
        <MenuItem
          style={styles.userMenu}
          innerDivStyle={styles.inner}
          primaryText={primaryText}
          rightIcon={<FontIcon className='material-icons' color={charcoal} style={styles.arrow}>arrow_drop_down</FontIcon>}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          menuItems={this.renderMenu()}
          data-cy='top-nav-menu'
        />
      </div>
    )
  }
}

export default withRouter(UserMenu)
