import gql from 'graphql-tag'

// USED IN: NOTES_TO_PROCESS
export const GetAllOrganizations = gql`
  query GetAllOrganizations($ids: [ID!]) {
    organizationsNBP(input: {
      ids: $ids
    }) {
      count,
      results {
        id,
        name
      }
    }
  }
`
