import gql from 'graphql-tag'

// SENDS LOGS TO SERVER
export const SendLogToServer = gql`
  mutation SendLogToServer(
    $session: String!,
    $role: String!,
    $user: String!,
    $ua: String,
    $level: LogLevel!,
    $message: [String!]!,
    $timestamp: String!
  ) {
    logMessage(input: {
      session: $session,
      role: $role,
      user: $user,
      ua: $ua,
      level: $level,
      message: $message,
      timestamp: $timestamp
    }) {
      done
    }
  }
`

export const LogStory = gql`
  mutation LogStory($date: String!, $events: [HalfStoryEvent!]!) {
    logStory(input: {
      date: $date,
      events: $events
    }) {
      success,
      message
    }
  }
`
