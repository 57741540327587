export const selectorFormat = str => {
  return str.replace(/\W/g, '-').toLowerCase()
}

export const snakeCapsToEnglish = str => {
  const snakeCapsArr = str.split('_')
  const englishArr = snakeCapsArr.map((word) => {
    // EdgeCases

    // N/A case
    if (word === 'NA') {
      return 'N/A'
    }

    // lowercase conjuctions
    if (['FOR', 'AND', 'NOR', 'BUT', 'OR', 'YET', 'SO'].includes(word)) {
      return word.toLowerCase()
    }

    // Else, uppercase first letter
    return (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  })
  return englishArr.join(' ')
}
