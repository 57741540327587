import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { withOktaAuth } from '@okta/okta-react'

import Login from './Login'
import loSto from '../config/loSto'
import { LO_STO, ROUTES } from '../lib/constants'

class Root extends Component {
  constructor (props) {
    super(props)

    const authenticated = props?.authState?.isAuthenticated

    this.state = { authenticated }
  }

  componentDidUpdate () {
    this.checkAuthentication()
  }

  checkAuthentication = () => {
    const { authenticated } = this.state
    const { authState: { isAuthenticated } } = this.props
    if (isAuthenticated !== authenticated) {
      this.setState({ authenticated: isAuthenticated })
    }
  }

  checkIfUserExist = () => {
    if (loSto.get(LO_STO.ALTAIS_ERROR)) {
      window.alert('This user does not exist in Suki’s system. Please contact support@suki.ai.')
      loSto.set(LO_STO.ALTAIS_ERROR, false)
    }
  }

  render () {
    const { authenticated } = this.state

    this.checkIfUserExist()

    if (authenticated === null) return null

    return authenticated ? <Redirect to={ROUTES.NOTES_TO_PROCESS} /> : <Login />
  }
}

export default withOktaAuth(Root)
