export const CLEAR_ACTION_REQUESTED = 'CLEAR_ACTION_REQUESTED'
export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR'
export const SET_ACTION_REQUESTED = 'SET_ACTION_REQUESTED'
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR'

export const clearActionRequested = () => ({
  type: CLEAR_ACTION_REQUESTED
})

export const clearServerError = () => ({
  type: CLEAR_SERVER_ERROR
})

export const setActionRequested = (name, data) => ({
  type: SET_ACTION_REQUESTED,
  name,
  data
})

export const setServerError = error => ({
  type: SET_SERVER_ERROR,
  error
})
