import React, { Component } from 'react'

import { SearchInput } from './styled'
import { loSto } from '../../config'
import { LO_STO } from '../../lib/constants'
import { GetCompositionOrNoteSms } from '@sukiai/gql/scribe'
import get from 'lodash.get'
import client from '../../apollo'
import { withRouter } from 'react-router'

class SearchBar extends Component {
  state = {
    searchQuery: loSto.session(LO_STO.NTP_FILTER) || '',
    isLoading: false
  }

  handleChange = (e) => {
    const val = e.target.value
    const { onSearchChange } = this.props
    this.setState({ searchQuery: val })
    onSearchChange(val)
  }

  handleKeyPress = (e) => {
    const key = e.key

    if (key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { searchQuery } = this.state
    this.setState({ isLoading: true })
    const errorMessage = 'Could not find an in-progress or saved note with that ID.'
    client
      .query({
        query: GetCompositionOrNoteSms,
        skip: !searchQuery,
        variables: { id: searchQuery.trim() }
      })
      .then(
        ({ data }) => {
          const compositionOrNote = get(data, 'compositionOrNoteSms')
          const orgId = get(compositionOrNote, 'composition.metadata.user.organizationId') ||
                        get(compositionOrNote, 'composition.organizationId')
          const noteId = get(compositionOrNote, 'composition.id') || get(compositionOrNote, 'composition.noteId')
          const userId = get(compositionOrNote, 'composition.metadata.user.id') || get(compositionOrNote, 'composition.userId')

          if ([orgId, noteId, userId].some(val => !val)) {
            this.setState({ isLoading: false, errMsg: errorMessage })
          } else {
            loSto.session(LO_STO.NTP_FILTER, '')
            this.props.history.push(`${orgId}/${userId}/note/${noteId}`)
          }
        },
        err => {
          this.setState({ isLoading: false, errMsg: errorMessage })
          console.error('Error finding note: ' + err)
        }
      )
  }

  render () {
    return (
      <SearchInput
        data-cy='search-by'
        onChange={this.handleChange}
        onKeyUp={this.handleKeyPress}
        placeholder='Search by doctor, patient or note id'
        type='text' value={this.state.searchQuery}
      />
    )
  }
}

export default withRouter(SearchBar)
