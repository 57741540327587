export const EDITOR_TYPE = {
  SCRIPT: 'SCRIPT',
  NOTETYPE: 'NOTETYPE',
  NOTE: 'NOTE',
  OPS: 'OPS',
  OPS_PROCESSED: 'OPS_PROCESSED',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  VERBATIM: 'VERBATIM',
  DETAILED: 'DETAILED'
}

export const PERSONA = {
  PHYSICIAN: 'PHYSICIAN',
  SCRIBE: 'SCRIBE',
  ADMIN: 'ADMIN'
}

export const INLINE_TYPE = {
  PREPOPULATED: 'prepopulated',
  ASR_TRANSCRIPT: 'asr_transcript',
  DOCTOR_TYPED: 'doctor_typed',
  OPS_TYPED: 'ops_typed',
  OPS_TO_PROCESS: 'ops_to_process',
  SCRIPT_CONTENT: 'script_content',
  DEFAULT: 'default',

  // New inline types
  ASR_NVOQ: 'ASR_NVOQ',
  ASR_GOOGLE: 'ASR_GOOGLE',
  ASR_HOMEGROWN: 'ASR_HOMEGROWN',
  TYPED_DOCTOR: 'TYPED_DOCTOR',
  TYPED_ADMIN: 'TYPED_ADMIN',
  TYPED_SCRIBE: 'TYPED_SCRIBE',
  SCRIPT: 'SCRIPT',
  COPY_FORWARD: 'COPY_FORWARD',
  NOTETYPE_PREFILLED: 'NOTETYPE_PREFILLED',
  NOTE_PULL: 'NOTE_PULL',
  DYNAMIC_VITALS: 'DYNAMIC_VITALS',
  DYNAMIC_VITALS_TEMPLATE: 'DYNAMIC_VITALS_TEMPLATE',
  UNKNOWN: 'UNKNOWN'
}

// TODO: commenting new chips as part of release 13.0
export const DYNAMIC_CHIP_MAPPING = {
  DEFAULT: 'Default',
  BLOOD_PRESSURE: 'Blood Pressure',
  HEART_RATE: 'Heart Rate',
  TEMPERATURE: 'Temperature',
  RESPIRATORY_RATE: 'Respiratory Rate',
  PULSE_OXIMETRY: 'Pulse Oximetry',
  HEIGHT: 'Height',
  WEIGHT: 'Weight',
  BMI: 'BMI',
  PAST_MEDICAL_HISTORY: 'Past Medical History',
  PAST_SURGICAL_HISTORY: 'Past Surgical History',
  ALLERGIES: 'Allergies',
  CURRENT_MEDICATION: 'Current Medications',
  LEUKOCYTES: 'WBC',
  ERYTHROCYTES: 'RBC',
  HEMOGLOBIN: 'Hemoglobin',
  HEMATOCRIT: 'Hematocrit',
  PLATELETS: 'Platelets',
  GLUCOSE: 'Glucose',
  UREA: 'BUN',
  CREATININE: 'Creatinine',
  SODIUM: 'Sodium',
  POTASSIUM: 'Potassium',
  CHLORIDE: 'Chloride',
  CARBON_DIOXIDE: 'CO2',
  PROTEIN: 'Protein',
  ALBUMIN: 'Albumin',
  BILIRUBIN_TOTAL: 'TotalBilirubin',
  BILIRUBIN_DIRECT: 'DirectBilirubin',
  ALKALINE_PHOSPHATASE: 'ALP',
  ASPARTATE_AMINOTRANSFERASE: 'AST',
  THYROXINE: 'Thyroxine',
  THYROTROPIN: 'TSH',
  TOTAL_CHOLESTEROL: 'TotalCholesterol',
  CHOLESTEROL_HDL: 'HDLCholesterol',
  CHOLESTEROL_LDL: 'LDLCholesterol',
  TRIGLYCERIDES: 'Triglycerides',
  PROSTATE_ANTIGEN: 'PSA',
  HEMOGLOBIN_A1C: 'A1C',
  INR: 'INR',
  ALANINE_AMINOTRANSFERASE: 'ALT',
  XRAY_NARRATIVE: 'XRAY Narrative',
  MRI_NARRATIVE: 'MRI Narrative',
  CT_NARRATIVE: 'CT Narrative',
  PAST_MEDICAL_HISTORY_SHOW_ME: 'Show Past Medical History',
  PAST_SURGICAL_HISTORY_SHOW_ME: 'Show Past Surgical History'
}

export const KEY = {
  BACKSPACE: 'Backspace',
  SPACE: ' ',
  M: 'm',
  ENTER: 'Enter'
}

export const KEY_CODE = {
  CTRL: 17,
  CMD: 91,
  C: 67,
  V: 86,
  X: 88
}

export const ERROR_CONTENT = {
  ops: [
    { insert: 'This content format is not yet supported and is not editable' },
    { insert: '\n' }
  ]
}

export const V2_DEFAULT_CONTENT = `{
  "object": "value",
  "document": {
    "object": "document",
    "data": {"editorVersion": "2.0.0"},
    "nodes": [
      {
        "object": "block",
        "type": "section",
        "data": {},
        "nodes": [
          {
            "object": "text",
            "leaves": [
              {
                "object": "leaf",
                "text": "",
                "marks": []
              }
            ]
          }
        ]
      }
    ]
  }
}`

export const EMPTY_TEXT_NODE = {
  'object': 'text',
  'leaves': [
    {
      'object': 'leaf',
      'text': '',
      'marks': []
    }
  ]
}

export const EMPTY_DELTA = {
  ops: [{ insert: '\n' }]
}

// A whitelist of allowed formats in the editor.
// See https://quilljs.com/docs/formats/ for a full list of formats supported
// by Quill
export const FORMATS = ['bold', 'italic', 'color', 'background', 'type', 'blockType', 'mention']

// NodeTypes
// see https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
export const NODE_TYPES = {
  TEXT_NODE: 3
}
