import React, { Component } from 'react'
import { withRouter } from 'react-router'
import ReactGA from 'react-ga'
import { loSto, CONFIG } from '../config'
import { LO_STO } from '../lib/constants'
import { setUserId } from './'

ReactGA.initialize(CONFIG.analytics.trackingId, {
  debug: true,
  gaOptions: {
    appVersion: loSto(LO_STO.VERSION)
  }
})

// On page refresh, set the UserID
if (loSto.session(LO_STO.USER_ID) !== null) {
  setUserId(loSto.session(LO_STO.USER_ID))
}

// Wraps a component with Google Analytics to register pageviews
// More info: https://github.com/react-ga/react-ga/issues/122
const withAnalytics = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    })
    ReactGA.pageview(page)
  }

  const HOC = class extends Component {
    componentDidMount () {
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentWillReceiveProps (nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  return withRouter(HOC)
}

export default withAnalytics
