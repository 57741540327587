import styled from 'styled-components'

const StyledButton = styled.button`
      position:relative;
      padding-left: 20px;
      padding-right: 20px;
      vertical-align: middle;
      letter-spacing: 0px;
      text-transform: uppercase;
      color: #417505; 
      font-weight: 700;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      background-color: #FFFFFF;
      border: none;
      font-family: "Open Sans", sans-serif;
  `

export default StyledButton
