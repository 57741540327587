import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getFullName } from '@sukiai/utils'
import { copyText, getOrgIdFromPath } from '../../lib/util'
import docNotInNote from '../../images/doc-not-in-note.svg'
import get from 'lodash.get'
import { GetAllOrganizations } from '@sukiai/gql/scribe'
import {
  FETCH_POLICY
} from '../../lib/constants'

import styled from 'styled-components'

const DoctorNameContainer = styled.div`
  display:flex;
  width: 100%;
`

const DoctorIcon = styled.div`
    margin: 4;
    min-height: 40;
    min-width: 40;
    display: flex;
    justify-content: center;
    align-items: center;
`

const DoctorText = styled.div`
    margin-left:10px;
    display: flex;
    flex-direction: column;
`

const DoctorName = styled.div`
  font-size:16px;
  font-weight:600;
`

const DoctorOrg = styled.div`
  font-size:14px;
`

const MetaContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 40px;
  padding: 20px 0px;
  min-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  width:200px;
`

class SidePanel extends Component {
  copy = (e, text) => {
    e.preventDefault()
    copyText(text)
  }

  render () {
    const {
      note,
      doctor,
      doctorOrgName
    } = this.props

    const doctorName = doctor && doctor.person && `Dr. ${getFullName(doctor.person)}`

    return (
      <MetaContainer>
        <DoctorNameContainer data-cy='note-sidebar-footer'>
          <DoctorIcon>
            <img src={note && docNotInNote} alt='doctor name icon' height={note && 34} width={note && 34} />
          </DoctorIcon>
          <DoctorText>
            <DoctorName>{doctorName}</DoctorName>
            <DoctorOrg>{doctorOrgName}</DoctorOrg>
          </DoctorText>
        </DoctorNameContainer>
      </MetaContainer>
    )
  }
}

SidePanel.propTypes = {
  noteOccupants: PropTypes.array,
  hasIcons: PropTypes.bool
}

SidePanel.defaultProps = {
  noteOccupants: [],
  hasIcons: true
}

const mapOrgsToProps = ({ data }) => {
  const doctorOrgName = get(data, 'organizationsNBP.results[0].name')
  return {
    doctorOrgName
  }
}

export default compose(
  withRouter,
  graphql(GetAllOrganizations, {
    skip: () => !getOrgIdFromPath(),
    options: () => ({
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      variables: {
        ids: [getOrgIdFromPath()]
      }
    }),
    props: mapOrgsToProps
  })
)(SidePanel)
