import gql from 'graphql-tag'

// USED IN: UTIL
export const GetUserById = gql`
  query GetUserById($id: ID!) {
    users(input: {
      ids: [$id]
    }) {
      results {
        id,
        roles {
          type
        },
        person {
          firstName,
          middleName,
          lastName,
          prefix,
          suffix
        },
        userType
      }
    }
  }
`

// USED IN: NOTE
export const GetUserByIdAndOrg = gql`
  query GetUsersById($organizationId: ID!, $id: ID!) {
    users(input: { organizationId: $organizationId, ids: [$id] }) {
      results {
        id
        person {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`
