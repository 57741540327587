import React, { Component } from 'react'
import OktaAuth from '@okta/okta-auth-js'
import { casal } from '../../styles/colors'
import { LO_STO } from '../../lib/constants'
import { OktaConfig, loSto } from '../../config'
import styled from 'styled-components'
import Loading from '../Loading'
import './Login.css'

const styles = {
  container: {
    backgroundColor: 'transparent',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  textfield: {
    height: '72px'
  },
  text: {
    color: 'white',
    fontSize: 32,
    fontWeight: 200
  },
  hintText: {
    color: 'white',
    fontSize: 32,
    fontWeight: 200,
    marginBottom: '12px'
  },
  menuItem: {
    fontSize: 26,
    fontWeight: 'normal'
  },
  selected: {
    color: 'black'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
    width: 170
  }
}

const Button = styled.button`
    background-color: ${casal};
    border-radius: 50px;
    color: white;
    cursor: pointer;
    font-size: 18px;
    height: 42px;
    width: 126px;
    margin-top: 38px;
    outline: 0;
`

const Input = styled.input`
  width:100%;
  background: transparent;
  border-width: 0 0 1px;
  border-color: black;
  outline: none;
  padding: 8px;
  margin: 10px 0;
  font-size: 28px;
  font-weight: 300;
  color: black;
`

const ENTER_KEY = 'Enter'

class LoginModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      loading: false,
      error: false
    }

    this.oktaAuth = new OktaAuth({
      url: OktaConfig.baseURL,
      issuer: OktaConfig.auth.issuer,
      clientId: OktaConfig.auth.client_id,
      redirectUri: OktaConfig.auth.redirect_uri,
      pkce: false,
      onAuthRequired: OktaConfig.auth.onAuthRequired
    })
  }

  handleUsernameChange = (event) => {
    this.setState({
      username: event.target.value
    })
  }

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  handleLogin = () => {
    this.setState({ loading: true })
    this.oktaAuth.signIn({
      username: this.state.username,
      password: this.state.password
    }).then(res => {
      if (res.status === 'SUCCESS') {
        loSto.set(LO_STO.SESSION_TOKEN, res.sessionToken)
        this.oktaAuth.token.getWithRedirect({
          responseType: 'id_token',
          responseMode: 'fragment',
          sessionToken: res.sessionToken,
          scopes: ['openid', 'email', 'profile']
        })
      } else {
        this.setState({
          loading: false,
          error: true
        })
        console.error('Authentication error:', res)
      }
    }).catch(err => {
      this.setState({
        loading: false,
        error: true
      })
      console.error('Login error:', err)
    })
  }

  handleTouchTap = () => {
    this.handleLogin()
  }

  handleKeyPress = e => {
    if (e.key === ENTER_KEY) {
      this.handleLogin()
    }
  }

  render () {
    const { loading } = this.state
    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        <Input
          id='usernameTextField'
          placeholder='Username'
          onKeyPress={this.handleKeyPress}
          onChange={this.handleUsernameChange}
          value={this.state.username}
          data-cy='textfield-username'
        />
        <Input
          id='passwordTextField'
          placeholder='Password'
          type='password'
          onKeyPress={this.handleKeyPress}
          onChange={this.handlePasswordChange}
          value={this.state.password}
          data-cy='textfield-password'
        />
        <div style={styles.buttonContainer}>
          <Button
            id='loginBtn'
            onClick={this.handleTouchTap}
            data-cy='btn-login'
          >
            {loading ? <Loading size={20} /> : 'Log In'}
          </Button>
        </div>

      </div>
    )
  }
}

export default LoginModal
