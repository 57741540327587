import React, { Component } from 'react'
import Checkbox from 'material-ui/Checkbox'
import { PlaybackList } from '../Audio'
import { compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import client from '../../apollo'
import { UpdateComposition, AudioFilesBySection } from '@sukiai/gql/scribe'
import { noteSectionPadding, sectionContentSize } from '../../styles/dimensions'
import { scribeSectionBorder } from '../../styles/colors.js'
import {
  EDITOR_TYPE,
  LO_STO,
  USER_PERSONA,
  OPS_STATUS
} from '../../lib/constants'
import { loSto } from '../../config'
import { isProcessed, setError, getEditorVersion, isContentErrorS2, isHTMLString, sanitizeHTML } from '../../lib/util'
import get from 'lodash.get'
import gql from 'graphql-tag'
import Loading from '../Loading'
import Editor from '@sukiai/quill-editor'
import omitDeep from 'omit-deep-lodash'
import { ContentErrorsDialog } from './index'
// STYLES of TRANSCRIPT data|text
const styles = {
  sectionTextStyle: {
    display: 'flex',
    flexFlow: 'column',
    fontSize: sectionContentSize,
    wordWrap: 'break-word',
    paddingLeft: noteSectionPadding,
    boxSizing: 'border-box'
  },
  sectionAudioControls: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '82%'
  },
  unprocessedEditor: {
    border: `1px solid ${scribeSectionBorder}`,
    cursor: 'text',
    padding: '10px'
  },
  sectionActions: {
    position: 'relative',
    margin: '12px 0px'
  },
  checkbox: {
    position: 'absolute',
    right: 0,
    width: 'auto',
    fontSize: 21,
    wordWrap: 'normal',
    borderRadius: '25px',
    padding: '10px 15px'
  },
  combineAudioCheckbox: {
    fontSize: 18,
    marginBottom: '15px'
  },
  loadingContainer: {
    width: '200px'
  },
  audioContainer: {
    width: 250
  }
}

const parseToS2 = (content) => {
  let contentJSON = {}
  let finalPlainText = ''
  try {
    contentJSON = JSON.parse(content)
    const finalContentJSONContents = get(contentJSON, 'content')
    finalContentJSONContents.forEach(cj => {
      finalPlainText += cj.string
    })
  } catch (err) {
    console.info('Error converting content to JSON', err)
  }
  return {
    content: JSON.stringify(contentJSON),
    plainText: finalPlainText,
    cursorPosition: contentJSON.total_string_length
  }
}

class EditableTranscriptSection extends Component {
  state = {
    processed: isProcessed(this.props.opsStatusFlag),
    waitingForAudio: false,
    audioTranscripts: null,
    isAudioCombined: false,
    editorVersion: getEditorVersion(this.props.text),
    content: this.props.text,
    hasContentError: false
  }

  componentWillMount () {
    const { client, note, id, match } = this.props
    const { orgId } = get(match, 'params')
    if (!note || !note.id || !id || !orgId) return

    client.query({
      query: AudioFilesBySection,
      variables: {
        organizationId: orgId,
        compositionId: note.id,
        sectionId: id,
        concatAudio: false
      }
    }).then(({ data }) => {
      const audioTranscripts = get(data, 'transcripts.results')
      this.setState({
        errorFetchingAudio: data.error,
        loadingAudio: data.loading,
        audioTranscripts: audioTranscripts
      })
    }).catch(err => {
      setError(err)
      console.error(err)
    })
  }

  componentWillReceiveProps (nextProps) {
    if (get(nextProps, 'text') !== this.props.text) {
      this.setState({ content: nextProps.text })
    }
  }

  setContentErrors = hasContentError => {
    this.setState({ hasContentError })
  }

  handleToggleProcessed = () => {
    const { content } = this.state

    const processed = !this.state.processed
    this.setState({ processed })
    if (processed) {
      // make sure section doesnt have unsupported chars
      const hasContentError = isContentErrorS2(content)
      if (hasContentError) {
        // reset processed check
        this.setState({ hasContentError, processed: false })
        return
      }
    }
    this.updateSectionText(processed)
  }

  updateSectionText = (processed) => {
    const { note, section, match, refetchNote } = this.props
    const { content } = this.state

    // The update requires the entire section entity sans __typename which is a field
    // added by apollo
    const sectionToUpdate = omitDeep(section, '__typename')

    const parsedS2 = parseToS2(content)
    sectionToUpdate.content = parsedS2.content
    sectionToUpdate.plainText = parsedS2.plainText
    sectionToUpdate.cursorPosition = parsedS2.cursorPosition
    sectionToUpdate.opsStatusFlag = processed ? OPS_STATUS.OPS_PROCESSED : OPS_STATUS.NEEDS_OPS_PROCESSING

    const { diagnosisEntry } = sectionToUpdate
    if (diagnosisEntry?.diagnosis?.description === null) {
      diagnosisEntry.diagnosis.description = ''
    }
    sectionToUpdate.diagnosisEntry = diagnosisEntry

    // Do one update with the current changes
    setTimeout(() => {
      const { orgId } = get(match, 'params')
      client.mutate({
        mutation: UpdateComposition,
        variables: {
          organizationId: orgId,
          id: note.id,
          sectionsS2: [sectionToUpdate]
        }
      })
        .then(() => {
          refetchNote()
        })
        .catch(err => {
          setError(err)
          console.error(err)
          // If the call fails, unprocess the section
          this.setState({ processed: false })
        })
    }, 500)
  }

  getMacroToPaste = () => {
    if (!loSto.session(LO_STO.SCRIPT_CLIPBOARD)) return
    const macroFragment = `Macro:${loSto.session(LO_STO.SCRIPT_CLIPBOARD)}.blocks.0`
    loSto.session.remove(LO_STO.SCRIPT_CLIPBOARD)
    const macro = this.props.client.readFragment({
      id: macroFragment,
      fragment: gql`
        fragment macro on Block {
          content
        }
      `
    })
    return macro
  }

  // Editor calls this on changes
  handleChange = newValue => {
    // if (this.state.processed) {
    //   this.handleToggleProcessed()
    // }
    this.setState({ content: newValue })
  }

  handleToggleCombinedAudio = () => {
    const { client, note, id, match } = this.props
    const { isAudioCombined } = this.state
    const { orgId } = get(match, 'params')

    this.setState({ isAudioCombined: true, waitingForAudio: true })
    client.query({
      query: AudioFilesBySection,
      variables: {
        organizationId: orgId,
        compositionId: note.id,
        sectionId: id,
        concatAudio: !isAudioCombined
      }
    }).then(({ data }) => {
      const audioTranscripts = get(data, 'transcripts.results')
      this.setState({ audioTranscripts, isAudioCombined: !isAudioCombined, waitingForAudio: false })
    }).catch(err => {
      setError(err)
      console.error(err)
    })
  }

  renderEditor = () => {
    const { processed, content } = this.state
    const { s2Mode } = this.props

    // [Parial dictation]: To render html section in non editable mode
    const text = content && JSON.parse(content).total_string
    const isHTML = isHTMLString(text)

    return (
      <div
        style={processed ? { color: '#a8a8a8' } : styles.unprocessedEditor}
        onFocus={() => !isHTML && processed && this.handleToggleProcessed()}
      >
        {isHTML ? (
          <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
        ) : (
          <Editor
            value={content}
            s2Mode={s2Mode}
            onChange={this.handleChange}
            editorType={EDITOR_TYPE.OPS}
            persona={USER_PERSONA.SCRIBE}
            enablePunct
            getMacroToPaste={this.getMacroToPaste}
          />
        )}
      </div>
    )
  }

  render () {
    const { audioTranscripts, waitingForAudio, processed, isAudioCombined, hasContentError, content } = this.state
    const { dictateAtCursorUsed, focused, note, section } = this.props

    const hasAudio = get(audioTranscripts, 'length', 0) > 0
    const hasFocus = hasAudio || focused || !processed
    const sectionTag = section.name.replace(/\W/g, '-').toLowerCase()
    const isPartialDictationComposition = get(note, 'metadata.partialDictation.partialDictationComposition', false)

    return (
      <div style={styles.sectionTextStyle}>
        {hasFocus &&
          <div style={hasAudio ? styles.sectionActions : { ...styles.sectionActions, paddingTop: 28 }}>
            <Checkbox
              label='Processed'
              checked={processed}
              disabled={isPartialDictationComposition && section.readOnly}
              data-is-checked={processed}
              onCheck={this.handleToggleProcessed}
              style={hasAudio ? styles.checkbox : { ...styles.checkbox, bottom: 15 }}
              data-cy={`section-checkbox-${sectionTag}`}
            />
            {hasAudio &&
              <div style={styles.audioContainer}>
                <Checkbox
                  label='Combined Audio'
                  checked={isAudioCombined}
                  data-is-checked={processed}
                  onCheck={this.handleToggleCombinedAudio}
                  style={styles.combineAudioCheckbox}
                  data-cy={`combine-audio-${sectionTag}`}
                />
                <div style={styles.sectionAudioControls}>
                  {waitingForAudio
                    ? (
                      <div style={styles.loadingContainer}>
                        <Loading />
                      </div>
                    )
                    : (
                      <PlaybackList
                        audioTranscripts={audioTranscripts}
                        sectionId={section.id}
                        isCurrentSection={focused}
                        sectionTag={sectionTag}
                        dictateAtCursorUsed={dictateAtCursorUsed}
                      />
                    )}
                </div>
              </div>}
          </div>}
        {this.renderEditor()}
        {hasContentError &&
          <ContentErrorsDialog
            open={hasContentError}
            content={content}
            closeDialog={() => this.setContentErrors(false)}
          />}
      </div>
    )
  }
}

export default compose(
  withRouter,
  withApollo
)(EditableTranscriptSection)
