export const SET_CURRENT_SECTION = 'SET_CURRENT_SECTION'
export const SET_NOTE_ID = 'SET_NOTE_ID'

export const setCurrentSection = id => ({
  type: SET_CURRENT_SECTION,
  id
})

export const setNoteId = id => ({
  type: SET_NOTE_ID,
  id
})
