import React, { Component } from 'react'
import { Route, Router } from 'react-router-dom'
import client from './apollo'
import history from './history'
import { ROUTES, LO_STO } from './lib/constants'
import { isValidPathname, initializeUser } from './lib/util'
import { ApolloProvider } from 'react-apollo'
import Auth from './okta'
import { loSto } from './config'
import { Security, LoginCallback } from '@okta/okta-react'
import SecureRoute from './components/SecureRoute'
import withAnalytics from './analytics/withAnalytics'
import NotificationsOverlay from './components/NotificationsOverlay'
import ErrorModal from './components/Error/ErrorModal'
import NotFound from './components/NotFound'
import Root from './containers/Root'
import NotesToProcess from './containers/NotesToProcess'
import VerbatimNote from './containers/VerbatimNote'
import Note from './containers/Note'
import Veil from './containers/Veil'
import { fontFamily } from './styles/typography'

const style = {
  fontFamily: fontFamily
}

const getComponent = C => withAnalytics(C)

// To make sure we don't expose routes outside other than the Login & Logout
// please add PrivateRoutes instead
class Routes extends Component {
  state = {
    userLoaded: loSto.session(LO_STO.USER_ID)
  }

  alwaysOnComponents = props => ([
    <ErrorModal key='error-modal' history={history} />,
    <NotificationsOverlay key='notif-overlay' history={history} />,
    <Veil key='veil' history={history} />
  ])

  loadUser = async () => {
    await initializeUser(Auth)
    this.setState({ userLoaded: true })
  }

  checkFragment = () => {
    const hash = history?.location?.hash
    if (hash.includes('error=access_denied')) {
      loSto.set(LO_STO.ALTAIS_ERROR, true)
    }

    return null
  }

  validateRoute = () => {
    if (!this.state.userLoaded) {
      this.loadUser()
      return null
    }

    return isValidPathname(window.location.pathname)
      ? this.alwaysOnComponents()
      : <NotFound />
  }

  render () {
    return (
      <Router history={history}>
        <Security authService={Auth}>
          <ApolloProvider client={client}>
            <div style={style}>
              {/* Authentication */}
              <Route exact path={ROUTES.ROOT} component={Root} />

              {/* Always on */}
              <SecureRoute path={ROUTES.SCRIBE_VIEW} render={this.validateRoute} />

              {/* Contextual */}
              {this.state.userLoaded &&
                [
                  <SecureRoute exact path={ROUTES.NOTES_TO_PROCESS} component={getComponent(NotesToProcess)} key='notes-to-process' />,
                  <SecureRoute exact path={ROUTES.VERBATIM_TRANSCRIPTS} component={getComponent(VerbatimNote)} key='verbatim-transcripts' />,
                  <SecureRoute exact path={`/:orgId/:userId${ROUTES.NOTE}/:id`} component={getComponent(Note)} key='scribe-note' />
                ]}

              {/* Developer */}
              <Route path={ROUTES.IMPLICIT_CALLBACK} component={LoginCallback} />
              <Route path={ROUTES.IMPLICIT_CALLBACK} render={this.checkFragment} />
            </div>
          </ApolloProvider>
        </Security>
      </Router>
    )
  }
}

export default Routes
