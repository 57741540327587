import { push, replace } from 'react-router-redux'
import { getFullName, isCharUnsupported } from '@sukiai/utils'
import Auth from '../../okta'
import store from '../../store'
import { success } from 'react-notification-system-redux'
import { LO_STO, ROUTES, CLIENT_TYPE, SUBMISSION_PRIORITY, NOTE_QUEUE_STATUS, OPS_STATUS } from '../../lib/constants'
import { setServerError } from '../../actions'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import { getIntercomID, loSto } from '../../config'
import get from 'lodash.get'
import { RecordNoteEvent } from '@sukiai/gql/common'
import client from '../../apollo'
import { GetNoteFromQueue, LockNoteForEdit, ReleaseNoteFromQueue } from '@sukiai/gql/scribe'

const jwtDecode = require('jwt-decode')

// Use the route pathname to figure out org ID context !!FOR USE IN NOTE!!
export const getOrgIdFromPath = () => window.location.pathname.split('/')[1]

// Use the route pathname to figure out note ID context !!FOR USE IN NOTE!!
export const getNoteIdFromPath = () => window.location.pathname.split('/')[4]

// Use the route pathname to figure out user ID context !!FOR USE IN NOTE!!
export const getUserIdFromPath = () => window.location.pathname.split('/')[2]

export const isAdmin = () => {
  const userRoles = loSto.session(LO_STO.CURRENT_USER_ROLES)
  return userRoles && userRoles.some(role => role === 'ADMIN')
}

export const isNoteView = () => {
  return window.location.pathname.includes(`${ROUTES.NOTE}/`)
}

export const getAdminNoteLink = (isPatientNote, actualPatientNoteId) => {
  let adminNoteURL
  const url = window.location.href
  if (url.includes('localhost')) {
    adminNoteURL = url.replace('localhost:3004', 'localhost:3005')
  }
  if (url.includes('.suki-dev') || url.includes('.suki-stage') || url.includes('.suki-test') ||
    url.includes('.suki.ai')) {
    adminNoteURL = url.replace('ops.suki', 'manage.suki')
  }

  // Read-only notes will have this flag set
  if (isPatientNote && adminNoteURL) {
    adminNoteURL = adminNoteURL.replace('/note', '/patientnote')
  }

  let finalURL = adminNoteURL

  if (actualPatientNoteId && adminNoteURL) {
    // if patientnote id is set explicitly use that
    // uuid-v4 has 36 characters
    finalURL = `${adminNoteURL.substring(0, adminNoteURL.length - 36)}${actualPatientNoteId}`
  }

  return finalURL
}

export const getEditorVersion = contentStr => {
  let parsedStr = ''
  try {
    parsedStr = JSON.parse(contentStr)
  } catch (err) {
    console.error(`Error parsing string ${contentStr} to JSON: ${err}`)
  }
  return get(parsedStr, 'document.data.editorVersion', '1.0.0')
}

export const getPatientId = patientInfo => {
  return patientInfo.firstName + ' ' + patientInfo.lastName
}

// TODO: Convert to getFullName and change getFullName to getFirstMiddleLast
export const getCompleteName = person => {
  const userPrefix = get(person, 'prefix', '')
  const fullName = getFullName(person)
  const userSuffix = get(person, 'suffix', '')
  return `${userPrefix} ${fullName} ${userSuffix}`
}

export const getGender = patient => (
  patient.gender && patient.gender[0] + patient.gender.substring(1).toLowerCase()
)

export const getAge = dateOfBirth => {
  if (new Date(dateOfBirth).getTime() === 0) return '--'

  const ageYears = moment().diff(dateOfBirth, 'years')
  const ageMonths = moment().diff(dateOfBirth, 'months')
  return ageYears >= 2 ? ageYears
    : ageMonths >= 1 ? `${ageMonths} ${ageMonths === 1 ? 'month' : 'months'}`
      : '--'
}

// Route user to given path if not already on that path (to prevent infinite loops)
export const routeTo = path => {
  if (store.getState().router.location.pathname !== path) {
    switch (path) {
      case ROUTES.ROOT:
        store.dispatch(replace(ROUTES.ROOT))
        break
      default:
        store.dispatch(push(path))
    }
  }
}

// Generates a 64-bit identifier
export const generateSuki64bitID = () => {
  return uuidv4().replace(/-/g, '').substring(16)
}

// This function gets the `ms-users` service's user ID
// Specific to Suki managed users (which will be all users involved with Suki)
// Even ones who are being authed using SAML from their own orgs

export const getSukiUserID = () => {
  const userId = loSto.session(LO_STO.USER_ID)
  return userId
}

// Newest to Oldest
export const timeSortDesc = arr => {
  arr.sort((a, b) => {
    a = new Date(a.visitDate)
    b = new Date(b.visitDate)
    return b > a ? 1
      : b < a ? -1
        : 0
  })
  return arr
}

// Oldest to Newest
export const timeSortAsc = arr => {
  arr.sort((a, b) => {
    a = new Date(a.visitDate)
    b = new Date(b.visitDate)
    return a > b ? 1
      : a < b ? -1
        : 0
  })
  return arr
}

// Notifications
export const notifySuccess = (message, title = '') => {
  store.dispatch(success({
    title,
    message,
    position: 'bc',
    autoDismiss: 3,
    dismissible: false
  }))
}

// Sort an array of objects by key
export const sortItemsByKey = (arr, key) => {
  arr.sort((a, b) => {
    a = get(a, key, '').toUpperCase()
    b = get(b, key, '').toUpperCase()

    return a < b ? -1
      : a > b ? 1
        : 0
  })

  return arr
}

export const getLastFirstName = person => {
  if (person.middleName && person.middleName.length > 0) {
    return `${person.lastName}, ${person.firstName} ${person.middleName[0]}.`
  } else {
    return `${person.lastName}, ${person.firstName}`
  }
}

// // Check if new opsStatus flag is set
export const isProcessed = opsStatusFlag => {
  return opsStatusFlag !== OPS_STATUS.NEEDS_OPS_PROCESSING
}

export const isValidPathname = pathname => {
  // Need more robust valid pathname detection
  // wont detect incorrect id's and the such
  return pathname.includes('/note') || pathname.includes('/notes') || pathname.includes('/verbatim-transcripts') || pathname.includes('/implicit')
}

export const setError = err => store.dispatch(setServerError(err))

export const recordNoteEvent = async (event, data) => {
  const noteEventData = {
    ...data,
    timestamp: new Date().toISOString(),
    clientType: CLIENT_TYPE.WEB
  }
  return client.mutate({
    mutation: RecordNoteEvent,
    variables: {
      noteEvent: event,
      noteEventData: noteEventData
    }
  }).then((res) => {
    window.Logger.info('Record Note Event Successful')
    window.Logger.debug('Result of Record Note Event:', res)
    console.info('Record Note Event Successful')
  }).catch(err => {
    console.error('Error Recording Event Note:', err)
  })
}

export const logout = () => {
  Auth.logout()
  loSto.clearAll()
  window.location.href = '/'
}

/*
 *  Set up initial persistent local state here - probably move this elsewhere later.
 *  https://github.com/marcuswestin/store.js#user-content-installation (use this)
 */
export const initializeLoSto = async (user) => {
  loSto.session(LO_STO.USER_ID, user.userID)
  loSto.session(LO_STO.ORGANIZATION_ID, user.organizationID)
  loSto.session(LO_STO.CURRENT_USER_NAME, user.name)
  loSto.session(LO_STO.CURRENT_USER_ROLES, user.roles)
  if (!loSto.session(LO_STO.SELECTED_ORG_ID)) {
    loSto.session(LO_STO.SELECTED_ORG_ID, 'ALL')
  }
  // List of data we want to track
  window.intercomSettings = {
    app_id: getIntercomID(),
    user_id: user.userID, // User ID
    name: user.name, // Full name
    email: user.email, // Email address
    created_at: user.created_at, // Signup Date
    'Org id': user.organizationID
  }
}

export const initializeUser = async (auth) => {
  // in the future, perhaps check whether all of this is already valid
  // before initializing user.
  const jwt = await auth.getIdToken()
  const user = jwtDecode(jwt)

  // Make sure the JWT contains a user ID, org ID, and roles
  if (!user.userID || !user.organizationID || !user.roles) {
    console.error('User is missing info in the JWT')
    logout()
    return
  }

  // Make sure the user has the right role
  if (!user.roles.includes('SCRIBE')) {
    console.error('User is not authorized for this page')
    logout()
    return
  }

  await initializeLoSto(user)
}

export const copyText = text => {
  const input = document.createElement('input')
  input.style.opacity = 0
  document.body.appendChild(input)
  input.value = text
  input.focus()
  input.select()
  document.execCommand('copy')
  notifySuccess('Copied to clipboard!')
  document.body.removeChild(input)
}

export const copyScript = text => {
  notifySuccess('Script copied!')
}

export const getDiffMinsDate = (date) => {
  const today = new Date()
  const diffMs = (today - date) // milliseconds between now & submissionDate
  const diffMins = Math.floor(diffMs / 60000)

  return diffMins
}

// used in getNotesNeedReview
export const getSubmissionPriority = (date) => {
  const min = getDiffMinsDate(date)

  if (min > SUBMISSION_PRIORITY.HIGH) return SUBMISSION_PRIORITY.HIGH
  else if (min > SUBMISSION_PRIORITY.MED) return SUBMISSION_PRIORITY.MED
  else return SUBMISSION_PRIORITY.LOW
}

export const convertArrayToObject = (array, key) =>
  array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item
    }),
    {}
  )

export const getNextNoteFromQueue = (history) => {
  client
    .query({
      query: GetNoteFromQueue
    }).then(({ data }) => {
      const noteFromQueue = get(data, 'noteFromQueue')
      const { orgId, noteId, userId, status } = noteFromQueue

      if (status === NOTE_QUEUE_STATUS.NOTE_IS_AVAILABLE) {
        history.push(`/${orgId}/${userId}/note/${noteId}`)
      }
    })
}

export const lockNoteForEditing = (noteId) => {
  return client.mutate({
    mutation: LockNoteForEdit,
    variables: {
      noteId
    }
  }).then(({ data }) => {
    const response = get(data, 'lockNoteForEdit')
    const { status, scribe } = response

    const isPatientNote = status === NOTE_QUEUE_STATUS.NOTE_DOESNT_EXIST
    const allowedToEdit = status === NOTE_QUEUE_STATUS.NOTE_IS_AVAILABLE

    return {
      allowedToEdit,
      scribe,
      isPatientNote
    }
  })
}

export const releaseNoteForEditing = (noteId, releaseType = null, reviewMessage = null) => {
  return client
    .mutate({
      mutation: ReleaseNoteFromQueue,
      variables: {
        noteId,
        releaseType,
        reviewMessage
      }
    }).then(({ data }) => {})
    .catch((e) => {
      console.error('Error releasing note:', JSON.stringify(e.graphQLErrors[0].message))
    })
}

export const getSubmissionText = (date) => {
  const today = new Date()
  const diffMs = (today - date) // milliseconds between now & submissionDate
  const diffMins = Math.floor(diffMs / 60000)

  const hours = Math.floor(diffMins / 60)
  const mins = diffMins - (hours * 60)

  const displayedHours = hours > 1 ? hours + ' hrs' : hours + 'hr'
  const displayedMins = mins > 1 ? mins + ' mins' : mins + ' min'

  const hoursText = hours > 0 ? displayedHours : ''
  const minsText = mins > 0 ? displayedMins : ''

  if (hours <= 0 && mins <= 0) return 'a few seconds ago'

  return `${hoursText} ${minsText} ago`
}

export const isContentErrorS2 = (s2) => {
  // takes in s2 as input
  const plainText = convertS2ToPlainText(s2)
  return plainText.split('').some(char => {
    return isCharUnsupported(char)
  }
  )
}

export const convertS2ToPlainText = (s2String) => {
  let s2 = null
  try {
    s2 = JSON.parse(s2String)
  } catch (err) {
    console.error('Unable to parse Slate string in JSON: ', s2String)
  }

  // eslint-disable-next-line
  return s2?.total_string
}

export const isHTMLString = (str) => {
  const regex = /<[a-z][\s\S]*>/i
  return regex.test(str)
}

export const sanitizeHTML = (html) => {
  const regex = /\\n|\\/g
  return html.replace(regex, "")
}
