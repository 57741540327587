import { TYPE, EDITOR_TYPE, PERSONA, DYNAMIC_CHIP_MAPPING } from '../constants'
import { get } from 'lodash'

// Given Slate content returns a Delta representing the content
// For dynamic vitals to show, we need to use convertStoredSlateToDynamicVitalsSlate function
export const convertSlateToDelta = (slateString, persona, editorType) => {
  let slate = null
  try {
    slate = JSON.parse(slateString)
  } catch (err) {
    console.error('Unable to parse Slate string in JSON: ', slateString)
  }

  if (isEmptySection(slate)) {
    return EMPTY_DELTA
  }

  let ops = []

  // Convert slate to delta
  const document = get(slate, 'document')
  const blockNodes = get(document, 'nodes', [])

  blockNodes.forEach((bn, i) => {
    const subnodes = get(bn, 'nodes', [])
    subnodes.forEach(sn => {
      const path = sn.object === 'inline' ? 'nodes[0].leaves' : 'leaves'
      const leaves = get(sn, path, [])
      leaves.forEach(leaf => {
        if (leaf.text === '') return
        var removeNewlines = leaf.text.replace(/\n/g, '')
        if (removeNewlines === '') {
          // This is the case where the string only consists on newlines - this is a workaround for a special case
          ops.push({
            insert: leaf.text,
            attributes: {
              blockType: sn.type || TYPE.DEFAULT
            }
          })
        } else {
          // Necessary for converting corrupted notetypes and scripts
          let type = sn.type || sn.blockType

          if (type !== TYPE.DYNAMIC_VITALS_TEMPLATE) {
            if (editorType === EDITOR_TYPE.SCRIPT) {
              type = TYPE.SCRIPT_CONTENT
            } else if (editorType === EDITOR_TYPE.NOTETYPE) {
              type = TYPE.PREPOPULATED
            }
          }

          if (type === TYPE.DYNAMIC_VITALS_TEMPLATE) {
            ops.push({
              insert: {
                mention: {
                  denotationChar: '+',
                  id: '1',
                  index: '0',
                  value: extractValue(leaf.text)
                }
              }
            })
          } else {
            ops.push({
              insert: leaf.text,
              attributes: getAttributes(type, leaf.marks)
            })
          }
        }
      })
    })
    // need to add spacing for new block
    if (i !== blockNodes.length - 1) {
      ops.push({
        insert: '\n\n',
        attributes: { type: TYPE.DEFAULT }
      })
    }
  })

  ops.push({ insert: '\n' })
  return { ops: ops }
}

// Given the s2 representation of a chip, returns the user-readable Chip value
const extractValue = chipStr => {
  const chipStrVal = chipStr.split('::')[1]
  const val = chipStrVal.substring(0, chipStrVal.length - 3)
  return val
}

// Given the user-readable Chip value, returns the s2 representation of said chip
const getChipInput = mention => {
  // eg. <<<TEMPERATURE:Temperature>>> or <<<BLOOD_PRESSURE::B.P>>>
  return `<<<${DYNAMIC_CHIP_MAPPING[mention.value]}::${mention.value}>>>`
}

// Given the Delta editor content, returns the equivalent Slate content
export const convertDeltaToSlate = (deltaContent, contentLength, metadata, editorType) => {
  if (contentLength === 1) {
    return V2_DEFAULT_CONTENT
  }

  let slate = {
    object: 'value',
    document: {
      object: 'document',
      data: { editorVersion: '2.0.0', ...metadata },
      nodes: [
        {
          object: 'block',
          type: 'section',
          data: {},
          nodes: []
        }
      ]
    }
  }

  const ops = get(deltaContent, 'ops', [])

  let inlines = []
  for (let i = 0; i < ops.length - 1; i++) {
    const op = ops[i]

    const mention = op.insert && op.insert.mention

    if (mention) {
      inlines.push(
        newInline(
          getChipInput(mention),
          TYPE.DYNAMIC_VITALS_TEMPLATE
        )
      )
    } else {
      inlines.push(
        newInline(
          op.insert,
          getSlateType(op.attributes, editorType),
          getMarksFromAttributes(op.attributes)
        )
      )
    }
  }

  let subnodes = []
  inlines.forEach(i => {
    subnodes.push(EMPTY_TEXT_NODE)
    subnodes.push(i)
  })

  subnodes.push(EMPTY_TEXT_NODE)

  const finalInsert = ops[ops.length - 1].insert
  if (finalInsert !== '\n') {
    const lastInline = finalInsert.substring(0, finalInsert.length - 1)
    let finalSlateType = TYPE.DEFAULT
    if (editorType === EDITOR_TYPE.SCRIPT) {
      finalSlateType = TYPE.SCRIPT_CONTENT
    }
    if (editorType === EDITOR_TYPE.NOTETYPE) {
      finalSlateType = TYPE.PREPOPULATED
    }

    subnodes.push(
      newInline(
        lastInline,
        finalSlateType
      )
    )
    subnodes.push(EMPTY_TEXT_NODE)
  }

  slate.document.nodes[0].nodes = subnodes
  return slate
}

// Maps Delta attributes back to Slate marks
export const getMarksFromAttributes = attributes => {
  let marks = []
  if (!attributes) return marks
  if (attributes.bold) {
    marks.push({
      object: 'mark',
      type: 'bold',
      data: {}
    })
  }
  if (attributes.italic) {
    marks.push({
      object: 'mark',
      type: 'italic',
      data: {}
    })
  }
  return marks
}

// Gets the attributes associated with a particular Slate type and user/editor context
export const getAttributes = (slateType, marks) => {
  let attributes = { type: slateType || TYPE.DEFAULT }

  // add marks
  marks && marks.forEach(m => {
    attributes[m.type] = true
  })

  return attributes
}

// Maps Delta attribute "type" back to Slate "type"
export const getSlateType = (attributes, editorType) => {
  const deltaType = get(attributes, 'type')

  if (
    deltaType === TYPE.DEFAULT ||
    (!deltaType && !get(attributes, 'blockType'))
  ) {
    if (editorType === EDITOR_TYPE.SCRIPT) {
      return TYPE.SCRIPT_CONTENT
    }

    if (editorType === EDITOR_TYPE.NOTETYPE) {
      return TYPE.PREPOPULATED
    }

    return TYPE.DEFAULT
  }

  return attributes.type || attributes.blockType
}

export const newInline = (content, type, marks = []) => ({
  object: 'inline',
  type: type,
  data: {},
  nodes: [
    {
      object: 'text',
      leaves: [
        {
          object: 'leaf',
          text: content,
          marks: marks
        }
      ]
    }
  ]
})

export const getDataField = value => {
  const jsonValue = JSON.parse(value)
  return get(jsonValue, 'document.data', {})
}

// Utility for extracting plain text from a text node
export const getTextFromTextNode = tn => {
  const leaves = get(tn, 'leaves', [])
  let text = ''
  leaves.forEach(l => {
    text += l.text
  })
  return text
}

// Utility for determining whether Slate content represents an empty section
export const isEmptySection = content => {
  const blockNodes = get(content, 'document.nodes', [])
  if (blockNodes.length !== 1) return false
  const subNodes = get(blockNodes, '[0].nodes', [])
  if (subNodes.length !== 1) return false
  const textNode = get(subNodes, '[0]')
  if (textNode.object !== 'text') return false
  if (getTextFromTextNode(textNode) !== '') return false
  return true
}

// Given the user's role and the type of editor, returns the type that
// the new edits should be tagged with
export const getType = (persona, editorType) => {
  if (editorType === EDITOR_TYPE.NOTETYPE) {
    return TYPE.PREPOPULATED
  }

  if (editorType === EDITOR_TYPE.SCRIPT) {
    return TYPE.SCRIPT_CONTENT
  }

  if (persona === PERSONA.SCRIBE) {
    return TYPE.OPS_TYPED
  }

  if (persona === PERSONA.PHYSICIAN) {
    return TYPE.DOCTOR_TYPED
  }
}

const V2_DEFAULT_CONTENT = {
  'object': 'value',
  'document': {
    'object': 'document',
    'data': { 'editorVersion': '2.0.0' },
    'nodes': [
      {
        'object': 'block',
        'type': 'section',
        'data': {},
        'nodes': [
          {
            'object': 'text',
            'leaves': [
              {
                'object': 'leaf',
                'text': '',
                'marks': []
              }
            ]
          }
        ]
      }
    ]
  }
}

const EMPTY_TEXT_NODE = {
  'object': 'text',
  'leaves': [
    {
      'object': 'leaf',
      'text': '',
      'marks': []
    }
  ]
}

export const EMPTY_DELTA = {
  ops: [{ insert: '\n' }]
}
