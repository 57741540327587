import gql from 'graphql-tag'

export const RecordNoteEvent = gql`
  mutation RecordNoteEvent($noteEvent: NoteEvent!, $noteEventData: NoteEventData) {
    recordNoteEvent(input: { 
        noteEvent: $noteEvent,
        noteEventData: $noteEventData 
    }) {
        noteEvent
      }
  }
`
