import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NoteHeader from '../Note/NoteHeader'
import EditableVerbatimSection from './EditableVerbatimSection'
import Paper from 'material-ui/Paper'
import Radium, { keyframes } from 'radium'
import {
  noteMarginTopAndBottom,
  navBarHeight
} from '../../styles/dimensions'
import { scribeGreen } from '../../styles/colors'

const noteAnim = keyframes({
  '0%': {
    marginTop: '100vh'
  },
  '100%': {
    marginTop: 0
  }
}, 'noteAnim')

const styles = {
  readOnly: {
    position: 'relative',
    margin: `100vh 0 ${noteMarginTopAndBottom} 0`,
    animation: 'x 0.5s ease-out 0.5s forwards',
    animationName: noteAnim,
    height: '100%',
    width: '100%'
  },
  editable: {
    margin: `100vh 0 ${noteMarginTopAndBottom} 0`,
    animation: 'x 0.5s ease-out 0.5s forwards',
    animationName: noteAnim
  },
  paper: {
    minHeight: `calc(100vh - ${navBarHeight} - ${noteMarginTopAndBottom})`,
    paddingBottom: '10px'
  },
  statusMessage: {
    position: 'absolute',
    color: scribeGreen,
    fontWeight: 'bold',
    right: 12,
    top: 8
  }
}

class VerbatimNote extends Component {
  render () {
    const {
      note,
      patient,
      sections,
      enablePunct,
      refetchNote,
      scripts,
      readOnly,
      handleButtonPress,
      unprocess
    } = this.props

    const allSections = sections && sections.map(s => {
      const sectionScripts = scripts && scripts.filter(m => (
        m.blocks && m.blocks[0] && m.blocks[0].sectionId === s.id
      ))

      return (
        <EditableVerbatimSection
          key={s.id}
          note={note}
          scripts={sectionScripts}
          section={s}
          enablePunct={enablePunct}
          refetchNote={refetchNote}
          handleButtonPress={handleButtonPress}
          unprocess={unprocess}
        />
      )
    })

    return (
      <div style={readOnly ? styles.readOnly : styles.editable}>
        <Paper style={styles.paper}>
          {readOnly &&
            <div style={styles.statusMessage}>
              Read Only
            </div>}
          {note && note.metadata &&
            <NoteHeader
              patient={patient}
              dateOfService={note.metadata.appointment && note.metadata.appointment.startsAt}
              noteName={note.metadata.name}
            />}
          {allSections}
        </Paper>
      </div>
    )
  }
}

VerbatimNote.propTypes = {
  /**
   * This is the note object
   */
  note: PropTypes.object.isRequired
}

export default Radium(VerbatimNote)
