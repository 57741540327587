import React, { Component } from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import { fulvous } from '../styles/colors'
import Radium from 'radium'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  }
}

class Loading extends Component {
  render () {
    const { size } = this.props
    return (
      <div style={styles.container}>
        <CircularProgress size={size || 48} thickness={4} color={fulvous} />
      </div>
    )
  }
}

export default Radium(Loading)
