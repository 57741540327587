import React, { Component } from 'react'

class Clock extends Component {
  constructor (props) {
    super(props)

    this.state = { date: new Date() }
  }

  componentDidMount () {
    this.timerId = setInterval(
      () => this.tick(),
      1000
    )
  }

  componentWillUnmount () {
    clearInterval(this.timerId)
  }

  tick () {
    this.setState({
      date: new Date()
    })
  }

  render () {
    return (
      <div style={this.props.style} data-cy='time'>
        {this.state.date.toLocaleTimeString([], this.props.dateTimeFormat)}
      </div>
    )
  }
}

Clock.defaultProps = {
  dateTimeFormat: { hour: '2-digit', minute: '2-digit' }
}

export default Clock
