import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { fontFamily } from './typography'

const sukiMuiTheme = getMuiTheme({
  fontFamily: fontFamily,
  toolbar: {
    backgroundColor: 'white',
    height: 48
  }
})

export default sukiMuiTheme
