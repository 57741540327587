import React from 'react'
import { BLOCK_TYPE, INLINE_TYPE } from './constants'
import {
  Macro,
  ScribeToProcess,
  LowProfileText,
  LowProfileTextV2,
  Section,
  DefaultInline,
  Prepopulated,
  AsrTranscript,
  DoctorTyped,
  OpsTyped,
  OpsToProcess,
  Script
} from './blocks'
import { slateGrey } from './lib/colors'

export const doctorRenderNodeV2 = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.SECTION:
      return <Section {...attributes}>{children}</Section>

    case INLINE_TYPE.ASR_TRANSCRIPT:
    case INLINE_TYPE.ASR_GOOGLE:
    case INLINE_TYPE.ASR_NVOQ:
    case INLINE_TYPE.ASR_HOMEGROWN:
      return <AsrTranscript {...attributes}>{children}</AsrTranscript>

    case INLINE_TYPE.PREPOPULATED:
    case INLINE_TYPE.DOCTOR_TYPED:
    case INLINE_TYPE.OPS_TYPED:
    case INLINE_TYPE.SCRIPT_CONTENT:
    default:
      return <DefaultInline {...attributes}>{children}</DefaultInline>
  }
}

export const doctorRenderNodeV1 = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.MACRO:
    case BLOCK_TYPE.PREFILLED:
      return <Macro {...attributes}>{children}</Macro>
    case BLOCK_TYPE.DICTATED:
      return (
        <div style={{color: slateGrey}} {...attributes}>
          <span style={{backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: '2px'}}>
            {children}
          </span>
        </div>
      )
    case BLOCK_TYPE.SCRIBE_TO_PROCESS:
      return <ScribeToProcess {...attributes}>{children}</ScribeToProcess>
    case BLOCK_TYPE.PARAGRAPH:
    case BLOCK_TYPE.USER_TYPED:
    case BLOCK_TYPE.SCRIBE_TYPED:
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const opsRenderNodeV2 = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.SECTION:
      return <Section {...attributes}>{children}</Section>

    case INLINE_TYPE.SCRIPT_CONTENT:
      return <Script {...attributes}>{children}</Script>

    case INLINE_TYPE.DOCTOR_TYPED:
    case INLINE_TYPE.PREPOPULATED:
      return <LowProfileTextV2 {...attributes}>{children}</LowProfileTextV2>

    case INLINE_TYPE.ASR_TRANSCRIPT:
    case INLINE_TYPE.OPS_TYPED:
    case INLINE_TYPE.OPS_TO_PROCESS:
    default:
      return <DefaultInline {...attributes}>{children}</DefaultInline>
  }
}

export const opsRenderNodeV1 = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.MACRO:
    case BLOCK_TYPE.PREFILLED:
      return <Macro {...attributes}>{children}</Macro>

    case BLOCK_TYPE.SCRIBE_TO_PROCESS:
    case BLOCK_TYPE.DICTATED:
      return <ScribeToProcess {...attributes}>{children}</ScribeToProcess>

    case BLOCK_TYPE.PARAGRAPH:
    case BLOCK_TYPE.USER_TYPED:
    case BLOCK_TYPE.SCRIBE_TYPED:
    default:
      return <LowProfileText {...attributes}>{children}</LowProfileText>
  }
}

export const adminRenderNodeV2 = props => {
  const { node, attributes, children } = props

  switch (node.type) {
    case BLOCK_TYPE.SECTION:
      return <Section {...attributes}>{children}</Section>

    case INLINE_TYPE.ASR_TRANSCRIPT:
    case INLINE_TYPE.OPS_TO_PROCESS:
    case INLINE_TYPE.SCRIPT_CONTENT:
    case INLINE_TYPE.PREPOPULATED:
    case INLINE_TYPE.DOCTOR_TYPED:
    case INLINE_TYPE.OPS_TYPED:
    default:
      return <DefaultInline {...attributes}>{children}</DefaultInline>
  }
}

export const adminRenderNodeV1 = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.MACRO:
    case BLOCK_TYPE.PREFILLED:
      return <Macro {...attributes}>{children}</Macro>

    case BLOCK_TYPE.SCRIBE_TO_PROCESS:
    case BLOCK_TYPE.DICTATED:
    case BLOCK_TYPE.PARAGRAPH:
    case BLOCK_TYPE.USER_TYPED:
    case BLOCK_TYPE.SCRIBE_TYPED:
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const detailedRenderNode = props => {
  const { node, attributes, children } = props
  switch (node.type) {
    case BLOCK_TYPE.SECTION:
      return <Section {...attributes}>{children}</Section>

    case INLINE_TYPE.PREPOPULATED:
      return <Prepopulated {...attributes}>{children}</Prepopulated>
    case INLINE_TYPE.ASR_TRANSCRIPT:
      return <AsrTranscript {...attributes}>{children}</AsrTranscript>
    case INLINE_TYPE.DOCTOR_TYPED:
      return <DoctorTyped {...attributes}>{children}</DoctorTyped>
    case INLINE_TYPE.OPS_TYPED:
      return <OpsTyped {...attributes}>{children}</OpsTyped>
    case INLINE_TYPE.OPS_TO_PROCESS:
      return <OpsToProcess {...attributes}>{children}</OpsToProcess>
    case INLINE_TYPE.SCRIPT_CONTENT:
      return <Script {...attributes}>{children}</Script>

    default:
      return <DefaultInline {...attributes}>{children}</DefaultInline>
  }
}

// Render marks
export const renderMark = (props, editor) => {
  const { children, mark } = props
  switch (mark.type) {
    case 'bold':
      return <strong>{children}</strong>
    case 'italic':
      return <em>{children}</em>
    default:
  }
}
