import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NoteHeader from './NoteHeader'
import EditableSection from './EditableSection'
import ChangeScriptSection from './ChangeScriptSection'
import Paper from 'material-ui/Paper'
import Radium, { keyframes } from 'radium'
import {
  noteMarginTopAndBottom
} from '../../styles/dimensions'
import { scribeGreen } from '../../styles/colors'
import ReadonlyTranscriptSection from './ReadonlyTranscriptSection'
import get from 'lodash.get'
import { dictateCursorUsed } from '@sukiai/utils'

const noteAnim = keyframes({
  '0%': {
    marginTop: '100vh'
  },
  '100%': {
    marginTop: 0
  }
}, 'noteAnim')

const styles = {
  readOnly: {
    position: 'relative',
    margin: `100vh 0 ${noteMarginTopAndBottom} 0`,
    animation: 'x 0.5s ease-out 0.5s forwards',
    animationName: noteAnim,
    height: '100%',
    width: '100%'
  },
  editable: {
    margin: `100vh 0 ${noteMarginTopAndBottom} 0`,
    animation: 'x 0.5s ease-out 0.5s forwards',
    animationName: noteAnim
  },
  paper: {
    paddingBottom: '10px',
    margin: 'auto',
    width: '980px',
    marginBottom: '100px'
  },
  statusMessage: {
    position: 'absolute',
    color: scribeGreen,
    fontWeight: 'bold',
    right: 12,
    top: 8
  }
}

class Note extends Component {
  render () {
    const {
      note,
      patient,
      sections,
      versionedSections,
      refetchNote,
      refetchVersioned,
      scripts,
      readOnly,
      s2Mode
    } = this.props

    const allSections = sections && sections.map(s => {
      const versionedSection = versionedSections && versionedSections.find(sec => sec.id === s.id)
      if (readOnly) {
        return (
          <ReadonlyTranscriptSection
            note={note}
            section={s}
            s2Mode={s2Mode}
            dictateAtCursorUsed={versionedSection && dictateCursorUsed(versionedSection.sectionEntries, s)}
            focused
          />
        )
      }

      const sectionScripts = scripts && scripts.filter(m => (
        get(m, 'blocks[0].sectionId') === s.id || get(m, 'blocks[0].sectionId') === '11111111-1111-1111-1111-111111111111'
      ))

      return (
        <EditableSection
          key={s.id}
          note={note}
          scripts={sectionScripts}
          section={s}
          s2Mode={s2Mode}
          refetchNote={refetchNote}
          refetchVersioned={refetchVersioned}
          dictateAtCursorUsed={versionedSection && dictateCursorUsed(versionedSection.sectionEntries, s)}
          isProblemSection={!!s.diagnosisEntry}
          versionedSection={versionedSection}
        />
      )
    })
    return (
      <div style={readOnly ? styles.readOnly : styles.editable}>
        <Paper style={styles.paper}>
          {readOnly &&
            <div style={styles.statusMessage}>
              Read Only
            </div>}
          {note && note.metadata &&
            <NoteHeader
              patient={patient}
              dateOfService={note.metadata.appointment && note.metadata.appointment.startsAt}
              noteName={note.metadata.name}
            />}
          {allSections}
          <ChangeScriptSection noteId={note.id || note.compositionId} />
        </Paper>
      </div>
    )
  }
}

Note.propTypes = {
  /**
   * This is the note object
   */
  note: PropTypes.object.isRequired
}

export default Radium(Note)
