import React, { Component } from 'react'
import ScribeNoteItem from './ScribeNoteItem'
import { withRouter } from 'react-router-dom'
import { compose } from 'react-apollo'
import Loading from '../Loading.js'
import PropTypes from 'prop-types'
import { getFullName } from '@sukiai/utils'
import Radium from 'radium'
import { loSto } from '../../config'
import { LO_STO } from '../../lib/constants'
import get from 'lodash.get'
import { NoteFilter } from '../../components/NoteFilter'
import styled from 'styled-components'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { textDark } from '../../styles/colors'

const TableHeader = styled.header`
  width:100%;
  background: transparent;
  
  display:flex;
  justify-content: space-between;
 `

const ColumnHeader = styled.div`
  width:33%;
  padding:20px;
 `

const EmptyStateContainer = styled.div`
  width: 100%;
  background: white;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-around;
  color: ${textDark};
  padding: 100px;  
  box-sizing: border-box;

  h4 {
    font-size: 20px;
    margin:5px;
  }

  p {
    font-size: 16px;
    margin: 5px;
  }
 `

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    height: 'calc(100vh - 100px)'
  },
  half: {
    width: '50vw',
    height: '100vh',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  listHeading: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 24,
    fontSize: 30,
    color: 'white',
    backgroundColor: '#4A6244'
  },
  title: {
    cursor: 'pointer'
  },
  listContainer: {
    minWidth: 400,
    width: 1000,
    height: 'calc(100vh - 300px)'
  },
  paper: {
    backgroundColor: 'transparent'
  },
  list: {
    padding: 0,
    borderTop: '1px solid #e2e2e2'
  },
  scribeText: {
    color: '#fff',
    fontSize: '50px',
    marginTop: '-50px'
  },
  filterDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    width: '300px'
  },
  search: {
    marginRight: 10,
    fontSize: '32px'
  },
  filterWrapper: {
    width: '100%'
  }
}

class NotesToProcessList extends Component {
  static propTypes = {
    notes: PropTypes.array,
    notesCount: PropTypes.number,
    history: PropTypes.object,
    notesLoading: PropTypes.bool,
    notesError: PropTypes.object
  }

  state = {
    filterVal: loSto.session(LO_STO.NTP_FILTER) || ''
  }

  componentWillReceiveProps (nextProps) {
    const { notesCount, currentOrgId } = this.props
    const { notesCount: nextNotesCount, currentOrgId: nextOrgId } = nextProps

    if (currentOrgId === nextOrgId && !!notesCount && notesCount !== nextNotesCount) {
      const newNotes = nextNotesCount - notesCount
      if (newNotes === 1) {
        // eslint-disable-next-line
        new Notification('New Note to Process!')
      } else if (newNotes > 1) {
        // eslint-disable-next-line
        new Notification(`${newNotes} New Notes to Process!`)
      }
    }
  }

  handleSetFilter = val => {
    this.setState({ filterVal: val })
    loSto.session(LO_STO.NTP_FILTER, val)
    this.scrollToTop()
  }

  filterNotes = notes => {
    // Have to compare uppercase versions to make case insensitive filter
    const filterVal = get(this.state, 'filterVal', '').toUpperCase()
    return notes.filter((n, i) => {
      const patient = getFullName(get(n, 'metadata.patient.person', null)).toUpperCase()
      const user = getFullName(get(n, 'metadata.user.person', null)).toUpperCase()
      const compositionId = get(n, 'id', '').toUpperCase()
      return patient.includes(filterVal) || user.includes(filterVal) || compositionId.includes(filterVal)
    })
  }

  renderEmptyState = () => {
    const hasFilter = !!this.state.filterVal
    const emptyStateText = `You currently have no notes to process${hasFilter ? ' with this filter' : ''}.`
    return (
      <EmptyStateContainer>
        <h4> No Notes </h4>
        <p>{emptyStateText}</p>
      </EmptyStateContainer>
    )
  }

  scrollToTop = () => {
    const list = document.getElementById('ntp-list')
    if (list) list.scrollTop = 0
  }

  render () {
    const {
      notes,
      history,
      notesLoading,
      orgsDictionary,
      currentOrgId,
      orgsNoteCount,
      notesCount
    } = this.props

    const filteredNotes = this.filterNotes(notes)
    const Row = ({ index, style }) => {
      const n = filteredNotes[index]
      return (
        <div style={style} key={n.id}>
          <ScribeNoteItem
            currentOrgId={currentOrgId}
            org={orgsDictionary[n.organizationId]}
            history={history}
            note={n}
          />
        </div>
      )
    }
    return (
      <div style={styles.container}>
        <div style={styles.listContainer}>
          <NoteFilter
            orgs={this.props.orgs}
            onSearchChange={this.handleSetFilter}
            setOrgId={this.props.setOrgId}
            orgsNoteCount={orgsNoteCount}
            currentOrgId={currentOrgId}
            notesCount={notesCount}
          />
          <TableHeader>
            <ColumnHeader>Name</ColumnHeader>
            <ColumnHeader>Org. Name</ColumnHeader>
            <ColumnHeader>Submission by Doctor</ColumnHeader>
          </TableHeader>

          {filteredNotes.length === 0 && !notesLoading && this.renderEmptyState()}

          {notesLoading ? <Loading />
            : (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    height={height}
                    itemCount={filteredNotes.length}
                    itemSize={100}
                    width={width}
                    data-cy='list-ntp'
                    overscanCount={50}

                  >
                    {Row}
                  </List>
                )}
              </AutoSizer>
            )}

        </div>
      </div>
    )
  }
}

export default compose(
  withRouter,
  Radium
)(NotesToProcessList)
