import { USER_LOGIN } from '../actions/user'

const INITIAL = {}

const user = (state = INITIAL, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        id: action.id,
        email: action.email,
        family_name: action.family_name,
        given_name: action.given_name,
        name: action.name,
        nickname: action.nickname,
        picture: action.picture
      }
    default:
      return state
  }
}

export default user
