import React, { Component } from 'react'
import Radium from 'radium'
import Dialog from 'material-ui/Dialog'
import { NOTE_EVENT_METRICS, LO_STO, INTENT_TYPE } from '../../lib/constants'
import { scribeGreen } from '../../styles/colors'
import { recordNoteEvent } from '../../lib/util'
import { loSto } from '../../config'
import { get } from 'lodash'
import StyledButton from '../StyledButton'

const styles = {
  reviewEditor: {
    border: '1px solid #F0F0F0',
    padding: '8px'
  },
  needsReviewEditor: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    border: 'none',
    ':focus': {
      outline: 'none'
    }
  }
}

class AddReviewModal extends Component {
  state = {
    open: false,
    initialContent: this.props.reviewMessage,
    content: this.props.reviewMessage
  }

  componentWillReceiveProps (nextProps) {
    nextProps.actionName && this.setState({
      open: nextProps.actionName === INTENT_TYPE.ADD_REVIEW
    })
  }

  handleOnRequestCancel = () => {
    this.setState({ open: false })
    this.props.clearActionRequested()
  }

  handleOnRequestSubmit = () => {
    const { content } = this.state
    const { action, clearActionRequested } = this.props

    if (content === '') {
      window.alert('Review message cannot be empty. Please cancel or retry')
      return
    }

    action(content)
    clearActionRequested()

    recordNoteEvent(NOTE_EVENT_METRICS.NEEDS_REVIEW, {
      noteId: loSto.session(LO_STO.CURRENT_NOTE_ID),
      userId: loSto.session(LO_STO.USER_ID),
      organizationId: loSto.session(LO_STO.ORGANIZATION_ID),
      tabId: window.sessionStorage.TAB_ID
    })
    console.info('NEEDS REVIEW Record Note Event Submitted')
  }

  handleChange = e => {
    const reviewMessage = get(e, 'target.value', '')
    this.setState({ content: reviewMessage })
  }

  handleResolve = async () => {
    const { resolveReview, refetchNote } = this.props
    await resolveReview()
    this.handleOnRequestCancel()
    refetchNote()
  }

  render () {
    const { initialContent, content } = this.state

    const title = 'Request a review'

    const hasReview = initialContent !== ''
    const actions = [
      hasReview && (
        <StyledButton
          label='Cancel'
          secondary
          onClick={this.handleOnRequestCancel}
          style={{ color: scribeGreen, marginRight: 30 }}
          key='cancel'
          data-cy='modal-btn-cancel'
        >Cancel
        </StyledButton>
      ),
      <StyledButton
        label={hasReview ? 'Resolve' : 'Cancel'}
        primary
        onClick={hasReview ? this.handleResolve : this.handleOnRequestCancel}
        style={{ color: scribeGreen, marginRight: 30 }}
        key='resolve-cancel'
        data-cy={hasReview ? 'modal-btn-resolve-review' : 'modal-btn-cancel'}
      >{hasReview ? 'Resolve' : 'Cancel'}
      </StyledButton>,
      <StyledButton
        label='Needs Review'
        primary
        onClick={this.handleOnRequestSubmit}
        style={{ color: scribeGreen }}
        key='needs-review'
        data-cy='modal-btn-needs-review'
      >Needs Review
      </StyledButton>
    ]

    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.state.open}
        title={title}
        titleStyle={{ fontWeight: 600 }}
        style={{ minWidth: '500px' }}
      >
        <div style={styles.reviewEditor}>
          <textarea
            id='needs-review'
            name='needs-review'
            style={styles.needsReviewEditor}
            onChange={this.handleChange}
            value={content}
          />
        </div>
      </Dialog>
    )
  }
}

export default Radium(AddReviewModal)
