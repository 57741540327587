import loSto from 'store'
import eventsPlugin from 'store/plugins/events'

// eventually put in default states via library
// CANNOT just loSto.set them here because this is called
// on every refresh

// The events plugin allows us to watch
// changes to certain values in loSto
loSto.addPlugin(eventsPlugin)

export default loSto
