import React, { Component } from 'react'
import ErrorAlert from './ErrorAlert'
import { clearServerError } from '../../actions'
import { connect } from 'react-redux'

class ErrorModal extends Component {
  state = {
    error: false,
    errorText: ''
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.errorText && nextProps.errorText.message) {
      this.setState({
        error: true,
        errorText: nextProps.errorText.message
      })
    }
  }

  dismissError = () => {
    this.props.clearServerError()
    this.setState({ error: false })
  }

  render () {
    return (
      this.state.error
        ? (
          <ErrorAlert
            open={this.state.error}
            error={this.state.errorText}
            handler={this.dismissError}
          />
        )
        : null
    )
  }
}

const mapStateToProps = ({ general }) => ({
  errorText: general.error
})

const mapDispatchToProps = dispatch => ({
  clearServerError: () => dispatch(clearServerError())
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal)
