import gql from 'graphql-tag'

export const DeleteSections = gql`
  mutation DeleteSections($organizationId: ID, $ids: [ID!]!) {
    deleteSections(input: { 
        organizationId: $organizationId,
        ids: $ids 
    }) {
        success
      }
  }
`
