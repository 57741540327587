import React from 'react'
import setup from './setup'
import ReactDOM from 'react-dom'
import Routes from './routes'
import store from './store'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import sukiMuiTheme from './styles/sukiMuiTheme'
import { StyleRoot } from 'radium'
import { Provider } from 'react-redux'
import { unregister } from './registerServiceWorker'
import uuid from 'uuid/v4'
import './styles.css'
import 'regenerator-runtime/runtime.js'

// Setup logger and get Frontend Version
setup()

// Logic to uniquely identify browser tab. Accounts for duplicated tabs.
// https://stackoverflow.com/questions/11896160/any-way-to-identify-browser-tab-in-javascript/36807854
const { sessionStorage } = window
if (!sessionStorage.TAB_ID || sessionStorage.closedLastTab === '2') {
  sessionStorage.TAB_ID = uuid()
}
sessionStorage.closedLastTab = '2'
window.addEventListener('beforeunload', () => { sessionStorage.closedLastTab = '1' })
window.addEventListener('unload', () => { sessionStorage.closedLastTab = '1' })

// Force deploy app #1
ReactDOM.render(
  <StyleRoot>
    <MuiThemeProvider muiTheme={sukiMuiTheme}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </MuiThemeProvider>
  </StyleRoot>,
  document.getElementById('root')
)

// SEE: RM-164 https://robinai.atlassian.net/browse/RM-164
// Until we can reproduce this issue locally with platform running in local namespace
// Let's disable service workers as outlined here:
// https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#opting-out-of-caching
unregister()
