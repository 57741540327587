import history from './history'
import rootReducer from './reducers'
import { routerMiddleware } from 'react-router-redux'
import { applyMiddleware, createStore } from 'redux'
import logger from 'redux-logger'

// Build the middleware for intercepting and dispatching navigation actions
const router = routerMiddleware(history)

// Set up Redux store
const store = createStore(
  rootReducer,
  applyMiddleware(logger, router)
)

export default store
