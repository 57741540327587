// Sidebar
export const sideBarWidth = '320px'

// Note
export const noteMarginTopAndBottom = '50px'
export const noteSectionPadding = 24
export const sectionNameSize = 22
export const sectionContentSize = 20

// Title bar
export const navBarHeight = '40px'

// filter menus
export const filterMenuWidth = '400px'
export const filterMenuExpanded = {
  width: '370px',
  maxHeight: '500px'
}

// note queue table
export const queueColumnContentWidth = '280px'
