import React from 'react'
import Dialog from 'material-ui/Dialog'
import StyledButton from '../StyledButton'

const ErrorAlert = props => {
  const {
    handler,
    open,
    error
  } = props

  const actions = [
    <StyledButton
      key='dismiss'
      label='Dismiss'
      primary
      onClick={handler}
    >Dismiss
    </StyledButton>
  ]

  return (
    <Dialog
      actions={actions}
      style={{ zIndex: 10000 }}
      modal={false}
      open={open}
      onRequestClose={handler}
    >
      {error}
    </Dialog>
  )
}

export default ErrorAlert
