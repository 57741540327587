import React, { Component } from 'react'
import Radium from 'radium'
import TopNavBar from './TopNavBar'
import { navBarHeight, noteMarginTopAndBottom, sideBarWidth } from '../styles/dimensions'

const styles = {
  container: {
    '@media print': {
      display: 'none'
    }
  },
  body: {
    width: '100vw',
    display: 'flex',
    paddingTop: `calc(${navBarHeight} + 30px)`,
    justifyContent: 'center',
    overflow: 'auto',
    height: '100vh'
  },
  side: {
    height: `calc(100vh - ${navBarHeight})`,
    width: '20vw',
    minWidth: 320
  },
  main: {
    height: `calc(100vh - ${navBarHeight})`,
    width: '80vw',
    boxSizing: 'border-box',
    padding: `${noteMarginTopAndBottom} 8vw`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'overlay'
  },
  paper: {
    height: `calc(100vh - ${navBarHeight} - ${noteMarginTopAndBottom})`,
    width: '100%',
    minWidth: 500
  },
  noPaperMain: {
    position: 'fixed',
    top: navBarHeight,
    left: sideBarWidth,
    width: `calc(100vw - ${sideBarWidth})`,
    '@media (min-width: 1600px)': {
      left: '20vw',
      width: '80vw'
    },
    height: `calc(100vh - ${navBarHeight})`,
    boxSizing: 'border-box',
    overflow: 'scroll'
  }
}

class Frame extends Component {
  render () {
    const {
      children
    } = this.props
    return (
      <div style={styles.container}>
        <TopNavBar />
        <div style={styles.body}>
          <div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

export default Radium(Frame)
