import { SET_CURRENT_PATIENT } from '../actions/patient'

const initialState = {
  info: null
  /** Should have the following in a patient object:
    id, firstName, middleName, lastName, mrn, dateOfBirth */
}

const patient = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PATIENT:
      return { ...state, info: action.patient }
    default:
      return state
  }
}

export default patient
