import React, { Component } from 'react'
import Checkbox from 'material-ui/Checkbox'
import { PlaybackList } from '../Audio'
import { compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { AudioFilesBySection } from '@sukiai/gql/scribe'
import { noteSectionPadding, sectionContentSize, sectionNameSize } from '../../styles/dimensions'
import { scribeSectionBorder } from '../../styles/colors.js'
import { setError, isHTMLString, sanitizeHTML } from '../../lib/util'
import {
  FETCH_POLICY,
  USER_PERSONA
} from '../../lib/constants'
import get from 'lodash.get'
import Loading from '../Loading'
import Editor from '@sukiai/quill-editor'

// STYLES of TRANSCRIPT data|text
const styles = {
  button: {
    height: 45,
    lineHeight: '45px'
  },
  label: {
    fontSize: 22,
    fontWeight: 'normal',
    textTransform: 'none',
    flex: 1
  },
  sectionTextStyle: {
    display: 'flex',
    flexFlow: 'column',
    fontSize: sectionContentSize,
    wordWrap: 'break-word',
    paddingLeft: noteSectionPadding,
    boxSizing: 'border-box'
  },
  sectionAudioControls: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '82%'
  },
  unprocessedEditor: {
    border: `1px solid ${scribeSectionBorder}`,
    cursor: 'text',
    padding: '10px'
  },
  sectionActions: {
    position: 'relative',
    margin: '12px 0px'
  },
  sectionContainer: {
    padding: '0px 50px 20px 50px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer'
  },
  sectionName: {
    fontSize: sectionNameSize,
    fontWeight: 800,
    marginBottom: 12,
    width: '100%'
  },
  editorContainer: {
    paddingLeft: '24px'
  },
  checkbox: {
    position: 'absolute',
    right: 0,
    width: 'auto',
    fontSize: 21,
    wordWrap: 'normal'
  },
  combineAudioCheckbox: {
    fontSize: 18,
    marginBottom: '15px'
  },
  loadingContainer: {
    width: '200px'
  },
  audioContainer: {
    width: 250
  }
}

class ReadonlyTranscriptSection extends Component {
  state = {
    waitingForAudio: false,
    audioTranscripts: null,
    isAudioCombined: false
  }

  componentDidMount () {
    const { client, note, section, match } = this.props
    const { orgId } = get(match, 'params')
    if (!note || !section.id || !orgId) return

    client.query({
      query: AudioFilesBySection,
      variables: {
        organizationId: orgId,
        compositionId: note.compositionId || note.id,
        sectionId: section.id,
        concatAudio: false
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }).then(({ data }) => {
      const audioTranscripts = get(data, 'transcripts.results')
      this.setState({
        errorFetchingAudio: data.error,
        loadingAudio: data.loading,
        audioTranscripts: audioTranscripts
      })
    }).catch(err => {
      setError(err)
      console.error(err)
    })
  }

  handleToggleCombinedAudio = () => {
    const { client, note, section, match } = this.props
    const { isAudioCombined } = this.state
    const { orgId } = get(match, 'params')

    this.setState({ isAudioCombined: true, waitingForAudio: true })
    client.query({
      query: AudioFilesBySection,
      variables: {
        organizationId: orgId,
        compositionId: note.compositionId || note.id,
        sectionId: section.id,
        concatAudio: !isAudioCombined
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }).then(({ data }) => {
      const audioTranscripts = get(data, 'transcripts.results')
      this.setState({ audioTranscripts, isAudioCombined: !isAudioCombined, waitingForAudio: false })
    }).catch(err => {
      setError(err)
      console.error(err)
    })
  }

  renderEditor = s => {
    const { audioTranscripts, waitingForAudio, isAudioCombined } = this.state
    const { dictateAtCursorUsed, focused, s2Mode } = this.props

    const hasAudio = get(audioTranscripts, 'length', 0) > 0
    const sectionTag = s.name.replace(/\W/g, '-').toLowerCase()

    // [Parial dictation]: To render html section in non editable mode
    const content = get(s, 'content', '')
    const text = content && JSON.parse(content).total_string
    const isHTML = isHTMLString(text)

    return (
      <div key={s.id} style={styles.sectionContainer} id={s.id}>
        <div style={styles.sectionName}>{s.name}</div>
        <div style={hasAudio ? styles.sectionActions : { ...styles.sectionActions, paddingTop: 28 }}>
          {hasAudio &&
            <div style={styles.audioContainer}>
              <Checkbox
                label='Combined Audio'
                checked={isAudioCombined}
                onCheck={this.handleToggleCombinedAudio}
                style={styles.combineAudioCheckbox}
                data-cy={`combine-audio-${sectionTag}`}
              />
              <div style={styles.sectionAudioControls}>
                {waitingForAudio
                  ? (
                    <div style={styles.loadingContainer}>
                      <Loading />
                    </div>
                  )
                  : (
                    <PlaybackList
                      audioTranscripts={audioTranscripts}
                      sectionId={s.id}
                      isCurrentSection={focused}
                      sectionTag={sectionTag}
                      dictateAtCursorUsed={dictateAtCursorUsed}
                    />
                  )}
              </div>
            </div>}
        </div>
        <div style={styles.editorContainer}>
          {isHTML ? (
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
          ) : (
            <Editor
              value={s.content}
              persona={USER_PERSONA.SCRIBE}
              s2Mode={s2Mode}
              readOnly
            />
          )}
        </div>
      </div>
    )
  }

  render () {
    const { section } = this.props
    return (
      <div style={styles.sectionTextStyle}>
        {this.renderEditor(section)}
      </div>
    )
  }
}

export default compose(
  withApollo,
  withRouter
)(ReadonlyTranscriptSection)
