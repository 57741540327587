import React, { Component } from 'react'
import { clearActionRequested, setActionRequested } from '../actions'
import background from '../images/backgroundScribe.jpg'
import { ImDoneModal, AddReviewModal, Note, LockNoteModal, SidePanel } from '../components/Note'
import {
  AddReviewToComposition,
  GetCompositionsByIdSms,
  GetScriptsByUserId,
  GetCompositionMetadataById,
  NoteIsActive,
  GetVersionedCompositionSms
} from '@sukiai/gql/scribe'
import circleGreen from '../images/check-green.png'
import circleBlank from '../images/check-blank.png'

import needsReviewButton from '../images/needs-review-normal.png'
import hasReviewButton from '../images/needs-review-selected.png'
import { withRouter } from 'react-router-dom'

import { loSto } from '../config'
import {
  LO_STO,
  FETCH_POLICY,
  NOTE_EVENT_METRICS,
  ROUTES,
  NOTE_STATUS,
  NBP_POLL_TIME,
  INTENT_TYPE
} from '../lib/constants'
import { whiteSmoke } from '../styles/colors'
import { sideBarWidth, navBarHeight } from '../styles/dimensions'
import { compose, graphql, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import Radium, { keyframes } from 'radium'
import { setNoteId, setCurrentSection } from '../actions/note'
import Frame from './Frame'
import {
  getOrgIdFromPath,
  getNoteIdFromPath,
  getUserIdFromPath,
  isAdmin,
  getAdminNoteLink,
  recordNoteEvent,
  releaseNoteForEditing,
  lockNoteForEditing,
  isProcessed
} from '../lib/util'
import get from 'lodash.get'
import omitDeep from 'omit-deep-lodash'

const buttonAnim = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
}, 'buttonAnim')

const styles = {
  sidepanel: {
    minWidth: sideBarWidth,
    width: '20vw',
    marginTop: navBarHeight,
    zIndex: 0,
    backgroundColor: whiteSmoke,
    display: 'flex',
    flexDirection: 'column'
  },
  actionContainer: {
    width: '8vw',
    height: `calc(100vh - ${navBarHeight})`,
    position: 'absolute',
    top: navBarHeight,
    right: 0,
    padding: '20px 0',
    minWidth: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxSizing: 'border-box',
    '@media print': {
      display: 'none'
    }
  },
  button: {
    height: 40,
    width: 40,
    objectFit: 'contain',

    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0 10px',
    outline: 'none',
    border: 0,
    animation: 'x 0.5s linear forwards',
    animationName: buttonAnim
  },
  imDoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.2s',
    cursor: 'pointer'

  },
  seeInAdmin: {
    position: 'absolute',
    right: 30,
    top: 54,
    color: 'black',
    zIndex: 100,
    cursor: 'pointer',
    width: '8vw',
    textAlign: 'center'
  },
  disabledImDone: {
    opacity: 0.25,
    ':hover': {
      opacity: 0.25
    },
    cursor: 'default'
  },
  enabledImDone: {
    opacity: 1.0,
    ':hover': {
      opacity: 0.75
    },
    cursor: 'pointer'
  }
}

class NoteContainer extends Component {
  state = {
    joined: false,
    readOnly: false,
    isNoteBeingProcessed: false, // is note being processed by someone else
    scribe: null // scribe editing page
  }

  componentDidMount () {
    const { history } = this.props
    const route = history.location.pathname

    this.attemptToLockNote()
    window.addEventListener('keydown', this.keyDownListenerEvt)
    window.addEventListener('keyup', this.keyUpListenerEvt)
    window.addEventListener('beforeunload', this.handleBeforeUnload)

    this.historyListener = history.listen(location => {
      const newRoute = location.pathname
      if (route !== newRoute) {
        this.releaseNote()
      }
    })
  }

  componentWillMount () {
    const noteId = getNoteIdFromPath()
    const userId = loSto.session(LO_STO.USER_ID)

    loSto.session(LO_STO.CURRENT_NOTE_ID, noteId)

    // Captures OPS_ENTERED metric
    recordNoteEvent(NOTE_EVENT_METRICS.OPS_ENTERED, {
      noteId,
      userId,
      organizationId: loSto.session(LO_STO.ORGANIZATION_ID),
      tabId: window.sessionStorage.TAB_ID
    })
  }

  async componentWillReceiveProps (nextProps) {
    if (nextProps.noteId !== this.props.noteId) {
      this.props.setNoteId(nextProps.noteId)
      loSto.session(LO_STO.CURRENT_NOTE_ID, nextProps.noteId)
    }
  }

  componentWillUnmount () {
    clearInterval(this.noteLockHeartbeat)
    this.historyListener() // unsubscribe
    window.Logger.info(`Leaving note as scribe: [id=${this.props.noteId}]`)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    this.unsetScribeContext()
    loSto.session(LO_STO.CURRENT_NOTE_ID, null)
  }

  handleBeforeUnload = (e) => {
    this.unsetScribeContext()
    this.releaseNote()
  }

  releaseNote = (releaseType = null, reviewMessage = null, isResolve = null) => {
    // only release note if user owns note
    const { noteId } = this.props
    if (this.isCurrentUserScribe() && noteId) {
      try {
        releaseNoteForEditing(noteId, releaseType, reviewMessage).then(() => {
          // if we are resolving we need to immediately lock note
          if (isResolve) {
            lockNoteForEditing(noteId).then(() => {
              window.Logger.info('Succesfully locked note after resolving note ')
            })
          }
        })
      } catch (errorReleasingNote) {
        console.error(errorReleasingNote)
        window.Logger.error(`Error releasing note [${noteId}]: Release Type[${releaseType} | Review Message[${reviewMessage}]: ${errorReleasingNote}`)
      }
    } else {
      console.info(`NoteId is not set or empty for release note call: Release Type[${releaseType} | Review Message[${reviewMessage}]`)
      window.Logger.info(`NoteId is not set or empty for release note call: Release Type[${releaseType} | Review Message[${reviewMessage}]`)
    }
  }

  isCurrentUserScribe () {
    // if current user === scribeId AND processing in this tab
    const currentUserId = loSto.session(LO_STO.USER_ID)
    const currentScribeId = get(this.state.scribe, 'id')
    return currentUserId === currentScribeId && !this.state.isNoteBeingProcessed
  }

  unsetScribeContext = () => {
    const noteId = loSto.session(LO_STO.CURRENT_NOTE_ID)
    const userId = loSto.session(LO_STO.USER_ID)

    recordNoteEvent(NOTE_EVENT_METRICS.OPS_LEFT, {
      noteId,
      userId,
      organizationId: loSto.session(LO_STO.ORGANIZATION_ID),
      tabId: window.sessionStorage.TAB_ID
    })
  }

  goToAdminNote = (isPatientNote, actualPatientNoteId) => window.open(getAdminNoteLink(isPatientNote, actualPatientNoteId))

  attemptToLockNote = () => {
    const noteId = getNoteIdFromPath()
    const { setActionRequested } = this.props
    const currentUserId = loSto.session(LO_STO.USER_ID)

    lockNoteForEditing(noteId).then(res => {
      const { allowedToEdit, scribe, isPatientNote } = res
      if (isPatientNote) {
        // case if note is PatientNote or being processed by doctor
        // should put note in readOnly AND not show locked message (for now)
        window.Logger.info(`NBP - User: ${currentUserId} could not lock note (Note: ${noteId}) because it does not exist`)
        this.setState({ isNoteBeingProcessed: true })
      } else if (!allowedToEdit) {
        window.Logger.info(`NBP - User: ${currentUserId} attempted to access a note (Note: ${noteId}) locked by User: ${scribe.id}`)
        this.setState({ isNoteBeingProcessed: true, scribe: scribe }, () => {
          setActionRequested(INTENT_TYPE.LOCK_NOTE)
        })
      } else {
        window.Logger.info(`NBP - User: ${currentUserId} succesfully locked a note (Note: ${noteId})`)
        this.setState({ isNoteBeingProcessed: false, scribe: { id: currentUserId } }, () => {
          this.startHeartBeat()
        })
      }
    })
  }

  startHeartBeat = () => {
    const noteId = getNoteIdFromPath()
    const userId = loSto.session(LO_STO.USER_ID)
    const { setActionRequested } = this.props

    const { client } = this.props
    // make heart beat call to backend
    // backend releases note if no heartbeat for 30 seconds
    this.noteLockHeartbeat = setInterval(() => {
      if (this.isCurrentUserScribe()) {
        client.query({
          query: NoteIsActive,
          variables: { noteId },
          fetchPolicy: FETCH_POLICY.NETWORK_ONLY
        }).then(({ data }) => {
          const status = get(data, 'noteIsActive.status')
          const scribe = get(data, 'noteIsActive.scribe')
          switch (status) {
            case 'ACTIVITY_UPDATED':
              // proceed as usual
              window.Logger.info(`NBP - User: ${userId} succesfully pinged Note (Note: ${noteId})`)
              break
            case 'NOTE_IS_AVAILABLE':
              // attempt to lock silently
              lockNoteForEditing(noteId).then(res => {
                const { allowedToEdit } = res
                if (!allowedToEdit) {
                  window.Logger.info(`NBP - User: ${userId} could not lock a note (Note: ${noteId}) from heartbeat`)
                  setActionRequested(INTENT_TYPE.LOCK_NOTE)
                } else {
                  const currentUserId = loSto.session(LO_STO.USER_ID)
                  this.setState({ isNoteBeingProcessed: false, scribe: { id: currentUserId } })
                  window.Logger.info(`NBP - User: ${userId} succesfully locked a note (Note: ${noteId}) from heartbeat`)
                }
              })
              break
            case 'SCRIBE_ID_DOESNT_MATCH':
              // scribe does not own note
              // display locked modal and display read only
              clearInterval(this.noteLockHeartbeat)
              this.setState({ isNoteBeingProcessed: true, scribe }, () => {
                window.Logger.info(`NBP - User: ${userId} lost note (Note: ${noteId}) from heartbeat`)
                setActionRequested(INTENT_TYPE.LOCK_NOTE)
              })
              break
            default:
              // unknown type!
              // there should probably be some error-handling
              // here, maybe an exception
          }
        })
      }
    }, NBP_POLL_TIME.NOTE_HEARTBEAT_TIME)
  }

  handleScribeDone = () => {
    const { enableImDone, setActionRequested } = this.props

    if (!enableImDone) return
    setActionRequested(INTENT_TYPE.SUBMIT_NOTE)
    // TODO: hook up submit note from scribe API
  }

  handleNeedsReviewClick = () => {
    const { setActionRequested } = this.props
    setActionRequested(INTENT_TYPE.ADD_REVIEW)
  }

  addReviewToComposition = async reviewMessage => {
    const { client, noteId } = this.props
    const orgId = getOrgIdFromPath()
    // release note optimistically
    await this.releaseNote('ADD_REVIEW', reviewMessage)

    // We need to make this call in order to get the entire metadata object.
    // The entire object is necessary in order to edit the composition metadata.
    // Eventually we should look into a way to update a composition metadata without
    // needing the entire metadata object.
    await client.query({
      query: GetCompositionMetadataById,
      variables: {
        organizationId: orgId,
        id: noteId
      }
    }).then(({ data }) => {
      const old = get(data, 'compositionsSms.results[0]')
      const newMetadata = old && {
        ...old.metadata,
        reviewMessage: reviewMessage
      }
      return omitDeep(newMetadata, '__typename')
    }).then(newMeta => {
      return client.mutate({
        mutation: AddReviewToComposition,
        variables: {
          organizationId: orgId,
          id: noteId,
          metadata: newMeta
        }
      })
    }).then(({ data }) => {
      window.Logger.info('Added review to note with ID:', noteId)
      console.info('Added review to note with ID:', noteId)
    }).catch(err => {
      const userId = loSto.session(LO_STO.USER_ID)
      // lock the note
      lockNoteForEditing(noteId).then(res => {
        const { allowedToEdit, scribe } = res

        if (!allowedToEdit) {
          window.Logger.info(`NBP - User: ${userId} could not locked a note (Note: ${noteId}) after reviewMessage error`)
        } else {
          this.setState({ isNoteBeingProcessed: false, scribe: { id: scribe.id } })
          window.Logger.info(`NBP - User: ${userId} succesfully locked a note (Note: ${noteId}) after reviewMessage error`)
        }
      })
      console.error('Failure to add review to note', err)
    })
  }

  resolveReviewComposition = async () => {
    const { client, noteId } = this.props
    const orgId = getOrgIdFromPath()

    // release note optimistically
    this.releaseNote('ADD_REVIEW', '', true)

    // We need to make this call in order to get the entire metadata object.
    // The entire object is necessary in order to edit the composition metadata.
    // Eventually we should look into a way to update a composition metadata without
    // needing the entire metadata object.
    await client.query({
      query: GetCompositionMetadataById,
      variables: {
        organizationId: orgId,
        id: noteId
      }
    }).then(({ data }) => {
      const old = get(data, 'compositionsSms.results[0]')
      const newMetadata = old && {
        ...old.metadata,
        reviewMessage: ''
      }
      return omitDeep(newMetadata, '__typename')
    }).then(newMeta => {
      return client.mutate({
        mutation: AddReviewToComposition,
        variables: {
          organizationId: orgId,
          id: noteId,
          metadata: newMeta
        }
      })
    }).then(({ data }) => {
      window.Logger.info('Resolved review for note with ID:', noteId)
      console.info('Resolved review for note with ID:', noteId)
    }).catch(err => {
      const userId = loSto.session(LO_STO.USER_ID)
      // lock the note
      lockNoteForEditing(noteId).then(res => {
        const { allowedToEdit, scribe } = res

        if (!allowedToEdit) {
          window.Logger.info(`NBP - User: ${userId} could not locked a note (Note: ${noteId}) after reviewMessage error`)
        } else {
          this.setState({ isNoteBeingProcessed: false, scribe: { id: scribe.id } })
          window.Logger.info(`NBP - User: ${userId} succesfully locked a note (Note: ${noteId}) after reviewMessage error`)
        }
      })
      console.error('Failure to resolve review for note', err)
    })
  }

  resolveReview = async () => {
    await this.resolveReviewComposition('')
    // Don't release lock for this composition
  }

  addReview = async (reviewMessage) => {
    await this.addReviewToComposition(reviewMessage)
    this.props.history.push(ROUTES.NOTES_TO_PROCESS)
  }

  onRouteChanged () {
    // Release the note
    this.releaseNote()
  }

  // On Route Change, release the note
  componentDidUpdate () {
    window.onpopstate = (e) => {
      this.onRouteChanged(e)
    }
  }

  render () {
    const { isNoteBeingProcessed, scribe } = this.state
    const {
      loadingNote,
      doctor,
      note,
      patient,
      noteId,
      actionRequested,
      clearActionRequested,
      enableImDone,
      currentSectionId,
      refetch,
      refetchVersioned,
      readOnly,
      scripts,
      sections,
      versionedSections,
      s2Mode,
      actualPatientNoteId
    } = this.props

    if (!note) return null

    const reviewMessage = get(note, 'metadata.reviewMessage')
    const isNeedDoctorSignOff = get(note, 'metadata.status') === NOTE_STATUS.NEED_DOCTOR_SIGN_OFF

    const isNoteReadOnly = readOnly || isNoteBeingProcessed || isNeedDoctorSignOff
    return (
      <div>
        {isAdmin() &&
          <div
            style={styles.seeInAdmin}
            onClick={this.goToAdminNote.bind(this, readOnly, actualPatientNoteId)}
            data-cy='admin-view-link'
          >
            Admin View
          </div>}

        {note && doctor &&
          <Frame withNote background={background}>
            <SidePanel
              style={styles.sidepanel}
              note={note}
              doctor={doctor}
              currentSectionId={currentSectionId}
              panelId='scribeSidePanel'
            />
            <Note
              key={note.id}
              readOnly={isNoteReadOnly}
              note={note}
              patient={patient}
              sections={sections}
              versionedSections={versionedSections}
              s2Mode={s2Mode}
              scripts={scripts}
              refetchNote={refetch}
              refetchVersioned={refetchVersioned}
            />
          </Frame>}

        {/*  MODALS */}
        {note && isNoteReadOnly &&
          <LockNoteModal
            scribe={scribe}
            actionName={actionRequested && actionRequested.name}
            clearActionRequested={clearActionRequested}
            refetchNote={refetch}
          />}

        {note && !isNoteReadOnly &&
          <ImDoneModal
            noteId={noteId}
            currentPatientId={get(patient, 'id')}
            actionName={actionRequested && actionRequested.name}
            releaseNote={this.releaseNote}
            clearActionRequested={clearActionRequested}
          />}

        {note && !isNoteReadOnly &&
          <AddReviewModal
            reviewMessage={reviewMessage}
            actionName={actionRequested && actionRequested.name}
            clearActionRequested={clearActionRequested}
            action={this.addReview}
            resolveReview={this.resolveReview}
            refetchNote={refetch}
          />}

        {/*  Right Action Container */}
        {!isNoteReadOnly &&
          <div style={styles.actionContainer}>
            {!loadingNote && note &&
              <div>
                <div>
                  <div
                    style={{ ...styles.imDoneContainer, ...styles.enabledImDone }}
                    onClick={this.handleNeedsReviewClick}
                    data-cy='note-btn-add-review'
                    key='1'
                  >
                    <div key='icon-addreview' style={styles.addReview}>
                      <img
                        alt='add review'
                        src={reviewMessage === '' ? needsReviewButton : hasReviewButton}
                        height={40}
                        width={40}
                      />
                    </div>
                    <div style={{ fontSize: 14 }}>
                    Needs Review
                    </div>
                  </div>
                </div>
                <div
                  style={styles.imDoneContainer}
                  onClick={this.handleScribeDone}
                  data-cy='note-btn-im-done'
                  data-is-enabled={enableImDone}
                  key='2'
                >
                  <div key='icon-imdone' style={styles.button}>
                    <img
                      alt='im done'
                      src={enableImDone ? circleGreen : circleBlank}
                      height={40}
                      width={40}
                    />
                  </div>
                  <div style={{ color: 'black', fontSize: 14 }}>
                  I'm Done
                  </div>
                </div>
              </div>}
          </div>}
      </div>
    )
  }
}

const mapStateToProps = ({ general, note }) => ({
  noteId: getNoteIdFromPath(),
  actionRequested: general.actionRequested,
  currentSectionId: note.currentSectionId
})

const mapDispatchToProps = dispatch => ({
  setNoteId: id => dispatch(setNoteId(id)),
  clearActionRequested: () => dispatch(clearActionRequested()),
  setActionRequested: type => dispatch(setActionRequested(type)),
  setCurrentSection: id => dispatch(setCurrentSection(id))
})

// Im done is enabled if every section isProcessed
/*
Enum OPS_status
{
  NO_OPS_PROCESSING_REQUIRED = STATE_0; //INITIAL VALUE
  NEEDS_OPS_PROCESSING = STATE_1;
  OPS_PROCESSED = STATE_2;
}
*/
// As of S2 (if any section has `NEEDS_OPS_PROCESSING` then, i'm done is disabled)
const isImDoneEnabled = note => {
  const s2Sections = get(note, 'sectionsS2', [])
  return s2Sections.every(s => isProcessed(s.opsStatusFlag))
}

const mapCompositionToProps = ({ ownProps, data }) => {
  const note = get(data, 'compositionsSms.results[0]')
  if (!note) return {}

  // Get s2 sections first, if not get slate sections
  let s2Mode = true
  let sections = get(note, 'sectionsS2', [])
  if (sections.length === 0) {
    s2Mode = false
    sections = get(note, 'sections', [])
  }

  const patient = get(note, 'metadata.patient')
  const doctor = get(note, 'metadata.user')
  // Now enable I'm done checks for sectionsS2 content instead of status
  const enableImDone = isImDoneEnabled(note)
  const readOnly = get(note, 'readOnly') || get(note, '__typename') === 'PatientNote'
  const actualPatientNoteId = get(note, '__typename') === 'PatientNote' && get(note, 'noteId')
  return {
    errorNote: data.error,
    loadingNote: data.loading,
    refetch: data.refetch,
    note,
    patient,
    doctor,
    enableImDone,
    readOnly,
    s2Mode: s2Mode,
    sections: sections,
    actualPatientNoteId: actualPatientNoteId
  }
}

const mapVersionedCompositionToProps = ({ data }) => {
  return {
    refetchVersioned: data.refetch,
    errorVersionedComposition: data.error,
    loadingVersionedComposition: data.loading,
    versionedSections: get(data, 'versionedCompositionSms.note.sectionsS2')
  }
}

const mapScriptsToProps = ({ ownProps, data }) => ({
  errorScripts: data.error,
  loadingScripts: data.loading,
  scripts: data.macros && data.macros.results
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetCompositionsByIdSms, {
    skip: () => !getNoteIdFromPath() || !getOrgIdFromPath(),
    options: () => ({
      variables: {
        organizationId: getOrgIdFromPath(),
        ids: [getNoteIdFromPath()]
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapCompositionToProps
  }),
  graphql(GetVersionedCompositionSms, {
    skip: () => !getNoteIdFromPath() || !getOrgIdFromPath(),
    options: () => ({
      variables: {
        organizationId: getOrgIdFromPath(),
        id: getNoteIdFromPath()
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapVersionedCompositionToProps
  }),

  graphql(GetScriptsByUserId, {
    skip: props => !getNoteIdFromPath() || !getOrgIdFromPath() || !props.note,
    options: props => ({
      variables: {
        organizationId: getOrgIdFromPath(),
        userId: getUserIdFromPath()
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapScriptsToProps
  }),
  withApollo,
  withRouter,
  Radium
)(NoteContainer)
