import gql from 'graphql-tag'

// USED IN: SCRIBE_NOTE
export const AudioFilesBySection = gql`
  query AudioFilesBySection($organizationId: ID!, $compositionId: ID!, $sectionId: ID!, $concatAudio: Boolean) {
    transcripts(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      sectionId: $sectionId,
      concatAudio: $concatAudio
    }) {
      results {
        audioUri,
        duration
      }
    }
  }
`

export const AudioFileForIntent = gql`
  query AudioFileForIntent($organizationId: ID!, $compositionId: ID!, $userId: ID!, $sectionId: ID!, $startsAt: String, $endsAt: String) {
    transcripts(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      userId: $userId,
      sectionId: $sectionId,
      startsAt: $startsAt,
      endsAt: $endsAt
    }) {
      results {
        audioUri
      }
    }
  }
`

export const GetSessionDetails = gql`
  query GetSessionDetails($organizationId: ID!, $userId: ID, $noteId: ID, $sectionId: ID, $startsAt: String,
    $endsAt: String) {
    sessionDetails(input: {
      organizationId: $organizationId,
      userId: $userId,
      noteId: $noteId,
      sectionId: $sectionId,
      startsAt: $startsAt,
      endsAt: $endsAt,
      retrieveEvents: true,
      retrieveMetrics: false
    }) {
      count,
      results {
        audioUri,
        audioEncoding {
          languageCode,
          sampleRateHertz,
          type
        }
      }
    }
  }
`

// USED IN: SCRIBE_NOTE
export const GetScriptChangeAudio = gql`
  query GetScriptChangeAudio($organizationId: ID!, $compositionId: ID!) {
    transcripts(input: {
      organizationId: $organizationId,
      compositionId: $compositionId,
      isScriptChange: true,
      withTranscripts: true
    }) {
      results {
        audioUri,
        duration,
        transcripts {
          uri,
          rawContentType,
          rawContent,
          textContent
        }
      }
    }
  }
`
