import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import Dialog from 'material-ui/Dialog'
import StyledButton from '../StyledButton'
import { withRouter } from 'react-router-dom'
import { SubmitComposition, GetNotesToProcess, SubmitTranscript } from '@sukiai/gql/scribe'
import { setError, getOrgIdFromPath, getNoteIdFromPath, releaseNoteForEditing, lockNoteForEditing } from '../../lib/util'
import { LO_STO, ROUTES, INTENT_TYPE } from '../../lib/constants'
import { loSto } from '../../config'
import get from 'lodash.get'

class ImDoneModal extends Component {
  state = {
    open: false,
    processing: false
  }

  componentWillReceiveProps (nextProps) {
    nextProps.actionName && this.setState({
      open: nextProps.actionName === INTENT_TYPE.SUBMIT_NOTE || nextProps.actionName === INTENT_TYPE.SUBMIT_VERBATIM
    })
  }

  handleRequestCancel = () => {
    this.props.clearActionRequested()
    this.setState({ open: false })
  }

  handleSubmissionError = (error, noteId) => {
    const userId = loSto.session(LO_STO.USER_ID)

    lockNoteForEditing(noteId).then(res => {
      const { allowedToEdit } = res
      if (!allowedToEdit) {
        window.Logger.info(`NBP - User: ${userId} could not lock a note (Note: ${noteId}) after imDone error`)
      } else {
        window.Logger.info(`NBP - User: ${userId} succesfully locked a note (Note: ${noteId}) after imDone error`)
      }
    })

    setError(error)
    this.setState({ open: false, processing: false })
    console.warn('Submit note failed', error)
  }

  handleRequestSubmit = () => {
    const {
      actionName,
      submitComposition,
      submitTranscript,
      history,
      clearActionRequested,
      transcribedSections,
      noteId,
      verbatimNote,
      releaseNote
      // refetch
    } = this.props

    // Clears modal
    clearActionRequested()

    // Shows the loader
    this.setState({ processing: true })

    const organizationId = getOrgIdFromPath()

    // release note optimistically
    releaseNote('SUBMIT_COMPOSITION')

    if (actionName === INTENT_TYPE.SUBMIT_NOTE) {
      // set all sections status to review
      // this covers all notes that are empty
      // and was not manually processed
      submitComposition({
        variables: {
          organizationId,
          id: noteId
        },
        update: (cache, response) => {
          try {
            // We need to write a utility function to treat the 'ALL' organization id differently
            const submissionWarning = get(response, 'data.submitComposition.submissionStatus.warning')
            if (submissionWarning) {
              return
            }

            const newNTP = cache.readQuery({
              query: GetNotesToProcess,
              variables: { organizationId }
            })
            const idx = newNTP.needsReview.results.findIndex(n => {
              return n.id === getNoteIdFromPath()
            })
            if (idx === -1) return
            newNTP.needsReview.results.splice(idx, 1)
            newNTP.needsReview.count = newNTP.needsReview.count - 1
            cache.writeQuery({
              query: GetNotesToProcess,
              variables: { organizationId },
              data: newNTP
            })
          } catch (e) {
            console.error('Could not find NTP in cache')
          }
        }
      })
        .then(({ data }) => {
          const submissionWarning = get(data, 'submitComposition.submissionStatus.warning')
          const submissionWarningMessage = get(data, 'submitComposition.submissionStatus.warning.message')

          // if submission warning, throw errorModal
          if (submissionWarning) {
            this.handleSubmissionError({ message: submissionWarningMessage }, noteId)
            return
          }

          this.setState({ open: false, processing: false })
          history.push(ROUTES.NOTES_TO_PROCESS)
        }).catch(error => this.handleSubmissionError(error, noteId))
    } else if (actionName === INTENT_TYPE.SUBMIT_VERBATIM) {
      const transcriptData = []
      Object.keys(transcribedSections).forEach(sectionId => {
        const section = transcribedSections[sectionId]
        Object.keys(section).forEach(uri => {
          const tData = section[uri]
          if (tData.processed && tData.transcript !== '') {
            transcriptData.push({
              sectionId: sectionId,
              audioUri: uri,
              audioLengthMs: tData.duration,
              transcript: tData.transcript,
              containsPhi: tData.containsPHI,
              cannotTranscribe: tData.untranscribable
            })
          }
        })
      })

      submitTranscript({
        variables: {
          compositionId: verbatimNote.compositionId,
          noteId: verbatimNote.noteId,
          organizationId: get(verbatimNote, 'metadata.user.organizationId'),
          userId: get(verbatimNote, 'metadata.user.id'),
          transcriptData
        }
      }).then(() => {
        console.info('Submitted transcripts')
        window.location.href = ROUTES.VERBATIM_TRANSCRIPTS
        // FIXME: Having issues with audio & transcript rendering properly
        // return refetch()
      }).then(() => {
        this.setState({ open: false, processing: false })
      })
        .catch(err => {
          this.setState({ open: false, processing: false })
          setError(err)
          console.error('Cannot submit transcripts:', err)
        })
    }
  }

  releaseNote = () => {
    // only release note if user owns note
    const { noteId } = this.props
    if (this.isCurrentUserScribe()) {
      releaseNoteForEditing(noteId, 'SUBMIT_COMPOSITION')
    }
  }

  render () {
    const { processing } = this.state

    let modalText, actions
    const title = processing ? 'Submitting...' : 'Done editing this note?'
    if (!processing) {
      modalText = 'Please confirm...'
      actions = [
        <StyledButton
          label='Continue Editing'
          primary
          onClick={this.handleRequestCancel}
          key='continue-editing'
        > Continue Editing
        </StyledButton>,
        <StyledButton
          label={processing ? 'Submitting...' : "I'M DONE"}
          primary
          onClick={this.handleRequestSubmit.bind(this)}
          data-cy='modal-btn-im-done'
          key='modal-btn-im-done'
        > {processing ? 'Submitting...' : "I'M DONE"}
        </StyledButton>
      ]
    }

    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.state.open}
        title={title}
        titleStyle={{ fontWeight: 600 }}
      >
        {modalText}
      </Dialog>
    )
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(SubmitComposition, { name: 'submitComposition' }),
  graphql(SubmitTranscript, { name: 'submitTranscript' })
)(ImDoneModal)
