import {
  CLEAR_ACTION_REQUESTED,
  CLEAR_SERVER_ERROR,
  SET_ACTION_REQUESTED,
  SET_SERVER_ERROR
} from '../actions'

const initialState = {
  actionRequested: null,
  disconnected: false,
  error: ''
}

const general = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ACTION_REQUESTED:
      return { ...state, actionRequested: null }
    case CLEAR_SERVER_ERROR:
      return { ...state, error: '' }
    case SET_ACTION_REQUESTED:
      return { ...state, actionRequested: { name: action.name, data: action.data } }
    case SET_SERVER_ERROR:
      return { ...state, error: action.error }
    default:
      return state
  }
}

export default general
