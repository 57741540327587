export const DICTATION_MODE = {
  SHOW: 'SHOW',
  BLUR: 'BLUR',
  RING: 'RING'
}

// 60 minute timeout.
export const INACTIVITY_TIMEOUT = 1000 * 60 * 59.5

export const USER_PERSONA = {
  ADMIN: 'ADMIN',
  SCRIBE: 'SCRIBE',
  USER: 'USER'
}

export const NOTE_STATUS = {
  NEED_DOCTOR_SIGN_OFF: 'NEED_DOCTOR_SIGN_OFF'
}

// Used (as of S2) to set processed button states
export const OPS_STATUS = {
  NO_OPS_PROCESSING_REQUIRED: 'NO_OPS_PROCESSING_REQUIRED',
  NEEDS_OPS_PROCESSING: 'NEEDS_OPS_PROCESSING',
  OPS_PROCESSED: 'OPS_PROCESSED'
}

export const EDITOR_TYPE = {
  OPS: 'OPS',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  VERBATIM: 'VERBATIM'
}

export const LO_STO = {
  SESSION_ID: 'SESSION_ID',
  SCHEDULE_DATE: 'SCHEDULE_DATE',
  USER_ID: 'USER_ID',
  SELECTED_ORG_ID: 'SELECTED_ORG_ID',
  USER_PERSONA: 'USER_PERSONA',
  USER_ROLES: 'USER_ROLES',
  VERSION: 'VERSION',
  AGENT_RECORDING_STATE: 'AGENT_RECORDING_STATE',
  CURRENT_APPOINTMENT: 'CURRENT_APPOINTMENT',
  CURRENT_NOTE_ID: 'CURRENT_NOTE_ID',
  CURRENT_USER_NAME: 'CURRENT_USER_NAME',
  USER_VERIFIED: 'USER_VERIFIED',
  SESSION_TOKEN: 'SESSION_TOKEN',
  DOCTOR_NOTE_ID: 'DOCTOR_NOTE_ID',
  DOCTOR_SECTION_ID: 'DOCTOR_SECTION_ID',
  CURRENT_USER_ROLES: 'CURRENT_USER_ROLES',
  SCRIPT_CLIPBOARD: 'SCRIPT_CLIPBOARD',
  NTP_SCROLL: 'NTP_SCROLL',
  NTP_FILTER: 'NTP_FILTER',
  NTP_SORT: 'NTP_SORT',
  ORGANIZATION_ID: 'ORGANIZATION_ID',
  ORG_LIST_SCROLL: 'ORG_LIST_SCROLL',
  ALTAIS_ERROR: 'ALTAIS_ERROR'
}

export const MIC_PROFILE = {
  CUSTOM: 'custom',
  MACBOOK_PRO: 'macbook_pro',
  REVOLABS_LAPEL: 'revolabs_lapel'
}

export const ROUTES = {
  ROOT: '/',
  // AUTH0_CALLBACK: '/callback',
  IMPLICIT_CALLBACK: '/implicit/callback',
  SCRIBE_VIEW: '/:view',
  NOTES_TO_PROCESS: '/notes',
  VERBATIM_TRANSCRIPTS: '/verbatim-transcripts',
  NOTE: '/note'
}

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'dev',
  TEST: 'test',
  STAGE: 'stage',
  PROD: 'prod'
}

export const Z_INDEX = {
  HAMBURGER: 4000,
  VEIL: 9999
}

export const KEY_CODES = {
  DOWN_ARROW_KEY: 40,
  UP_ARROW_KEY: 38,
  ENTER: 13
}

export const KEYS = {
  ENTER: 'Enter'
}

export const INTENTS = {
  AGENT_CANCELLED: { value: 'AGENT_CANCELLED', name: 'N/A' },
  ABDOMINAL_CT_SCAN: { value: 'ABDOMINAL_CT_SCAN', name: 'Abdominal CT Scan' },
  COPY_FORWARD: { value: 'COPY_FORWARD', name: 'Copy Forward' },
  CREATE_NOTE_FROM_NOTETYPE_FOR_PATIENT: { value: 'CREATE_NOTE_FROM_NOTETYPE_FOR_PATIENT', name: 'Create note' },
  DELETE_NOTE: { value: 'DELETE_NOTE', name: 'Delete Note' },
  DISMISS: { value: 'DISMISS', name: 'Dismiss' },
  GO_BACK: { value: 'GO_BACK', name: 'Go Back' },
  HELP: { value: 'HELP', name: 'Help' },
  HEMOGLOBIN_A1C: { value: 'HEMOGLOBIN_A1C', name: 'Hemoglobin A1C' },
  LOG_OUT: { value: 'LOG_OUT', name: 'Log Out' },
  MACRO: { value: 'MACRO', name: 'Macro' },
  ORDER_5MG: { value: 'ORDER_5MG', name: 'Order 5mg' },
  ORDER_10MG: { value: 'ORDER_10MG', name: 'Order 10mg' },
  PRESCRIBE_NICODERM: { value: 'PRESCRIBE_NICODERM', name: 'Prescribe Nicoderm' },
  PRINT_NOTE: { value: 'PRINT_NOTE', name: 'Print Note' },
  PROFILE: { value: 'PROFILE', name: 'Profile' },
  SCRIPT_CHANGE: { value: 'SCRIPT_CHANGE', name: 'Script Change' },
  SCHEDULE: { value: 'SCHEDULE', name: 'Schedule' },
  SECTION: { value: 'SECTION', name: 'Section' },
  SHOW_SCRIPTS: { value: 'SHOW_SCRIPTS', name: 'Show Scripts' },
  SHOW_MEDICATIONS: { value: 'SHOW_MEDICATIONS', name: 'Show Medications' },
  START_DICATION: { value: 'START_DICATION', name: 'Start Dictation' },
  STOP_DICTATION: { value: 'STOP_DICATION', name: 'Stop Dictation' },
  SUBMIT_NOTE: { value: 'SUBMIT_NOTE', name: 'Submit Note' },
  UNFINISHED_NOTES: { value: 'UNFINISHED_NOTES', name: 'Unfinished Notes' },
  URINE_CULTURE: { value: 'URINE_CULTURE', name: 'Urine Culture' },
  VEIN_MAPPING: { value: 'VEIN_MAPPING', name: 'Vein Mapping' },
  XRAY: { value: 'XRAY', name: 'X-Ray' }
}

export const VALIDATION_STATE = {
  DEFAULT: 'DEFAULT',
  UNVALIDATED: 'UNVALIDATED',
  VALIDATED: 'VALIDATED',
  REJECTED: 'REJECTED'
}

export const NOTE_EVENT_METRICS = {
  OPS_ENTERED: 'OPS_ENTERED',
  OPS_LEFT: 'OPS_LEFT',
  OPS_PROCESSED_SECTION: 'OPS_PROCESSED_SECTION',
  NEEDS_REVIEW: 'NEEDS_REVIEW'
}

export const CLIENT_TYPE = {
  WEB: 'WEB'
}

// Minimum length of audio file that will be displayed in ops note, any less
// can still be seen in lighthouse in admin view
export const MIN_AUDIO_DURATION = 500

// More information on these fetch policies can be found at:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
export const FETCH_POLICY = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache'
}

export const UNKNOWN_PATIENT_NAME = 'Unknown Patient Name'

export const SUKI_ORG_ID = '11111111-1111-1111-1111-111111111111'

export const SUBMISSION_PRIORITY = {
  HIGH: 90, // HIGH > 90
  MED: 60, // MED < 90 && > 60
  LOW: 0 // LOW < 60
}
export const NOTE_QUEUE_STATUS = {
  NOTE_IS_AVAILABLE: 'NOTE_IS_AVAILABLE',
  SCRIBE_ID_DOESNT_MATCH: 'SCRIBE_ID_DOESNT_MATCH',
  ACTIVITY_UPDATED: 'ACTIVITY_UPDATED',
  NOTE_DOESNT_EXIST: 'NOTE_DOESNT_EXIST'
}

export const NBP_POLL_TIME = {
  NOTES_TO_PROCESS_POLL_TIME: 5000,
  NOTE_HEARTBEAT_TIME: 9000
}

// https://github.com/apollographql/apollo-client/blob/master/src/core/networkStatus.ts
export const APOLLO_NETWORK_STATUS = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8
}

export const INTENT_TYPE = {
  ADD_REVIEW: 'ADD_REVIEW',
  AGENT_CANCELLED: 'AGENT_CANCELLED',
  AGENT_WAKEUP: 'AGENT_WAKEUP',
  CREATE_NOTE_FROM_TEMPLATE: 'CREATE_NOTE_FROM_TEMPLATE',
  CREATE_NOTE: 'CREATE_NOTE',
  DISMISS: 'DISMISS',
  FINAL: 'FINAL',
  INTERIM: 'INTERIM',
  LOCK_NOTE: 'LOCK_NOTE',
  MACRO: 'MACRO',
  MACRO_EXCEPT: 'MACRO_EXCEPT',
  PRINT_NOTE: 'PRINT_NOTE',
  PROFILE: 'PROFILE',
  RESTART_STREAM: 'RESTART_STREAM',
  SCHEDULE: 'SCHEDULE',
  SECTION: 'SECTION',
  START_DICTATION: 'START_DICTATION',
  STOP_DICTATION: 'STOP_DICTATION',
  SUBMIT_NOTE: 'SUBMIT_NOTE',
  SUBMIT_VERBATIM: 'SUBMIT_VERBATIM',
  URINE_CULTURE: 'URINE_CULTURE',
  VEIN_MAPPING: 'VEIN_MAPPING',
  XRAY: 'XRAY'
}
