import gql from 'graphql-tag'

// USED IN: SCRIBE_NOTE and SUKI-TRAINING
export const GetScriptsByUserId = gql`
  query GetScriptsByUserId($organizationId: ID!, $userId: ID!) {
    macros(input: {
      organizationId: $organizationId,
      userId: $userId,
      includeFields: true
    }) {
      count,
      results {
        id,
        userId,
        tags {
          name {
            value,
            aliases
          },
          type
        },
        blocks {
          sectionId,
          content,
          fields {
            name {
              value,
              aliases
            },
            type
          }
        }
      }
    }
  }
`
