import {
  SET_CURRENT_SECTION,
  SET_NOTE_ID
} from '../actions/note'

const initialState = {
  /** note id is tracked as `id`: & doesn't need an initial state */
  currentSectionId: '',
  id: ''
}

const note = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SECTION:
      return { ...state, currentSectionId: action.id }
    case SET_NOTE_ID:
      return { ...state, id: action.id }
    default:
      return state
  }
}

export default note
