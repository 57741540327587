import React, { Component } from 'react'
import helpIcon from '../lib/assets/icons/help-icon.png'

const styles = {
  iconStyle: {
    width: '50px',
    height: '50px',
    marginTop: '5px',
    ':hover': {
      opacity: 0.25
    }
  }
}

class InfoButton extends Component {
  render () {
    return (
      <div onClick={this.props.handleClick} data-cy='note-info-button'>
        <img
          src={helpIcon}
          alt='Information'
          style={styles.iconStyle}
        />
      </div>
    )
  }
}

export default InfoButton
