import React, { Component } from 'react'
import { PlaybackList } from '../Audio'
import { compose, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { AudioFilesBySection } from '@sukiai/gql/scribe'
import { noteSectionPadding, sectionContentSize } from '../../styles/dimensions'
import { scribeSectionBorder } from '../../styles/colors.js'
import { USER_PERSONA, FETCH_POLICY, EDITOR_TYPE } from '../../lib/constants'
import { setError } from '../../lib/util'
import get from 'lodash.get'
import set from 'lodash.set'
import Loading from '../Loading'
import FancyCheckbox from '../FancyCheckbox'
import Editor, { V2_DEFAULT_CONTENT } from '@sukiai/editor'

// TODO: Eventually transcript data should be received from the container VerbatimNote.js, instead of stored
// seperately in this component's own state

// STYLES of TRANSCRIPT data|text
const styles = {
  sectionTextStyle: {
    display: 'flex',
    flexFlow: 'column',
    fontSize: sectionContentSize,
    wordWrap: 'break-word',
    paddingLeft: noteSectionPadding,
    boxSizing: 'border-box',
    paddingTop: '18px'
  },
  sectionAudioControls: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '82%'
  },
  unprocessedEditor: {
    border: `1px solid ${scribeSectionBorder}`,
    cursor: 'text',
    padding: '10px'
  },
  sectionActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    margin: '12px 0px'
  },
  loadingContainer: {
    width: '200px'
  },
  audioContainer: {
    width: 250
  },
  checkboxSeperator: {
    width: '12px'
  }
}

class EditableVerbatimTranscriptSection extends Component {
  state = {
    waitingForAudio: false,
    audioTranscripts: null,

    editedValue: null,
    markup: V2_DEFAULT_CONTENT,

    transcriptData: {}
  }

  componentWillMount () {
    const { client, note, id } = this.props
    if (!note || !id) return

    client.query({
      query: AudioFilesBySection,
      variables: {
        organizationId: note.metadata.user.organizationId,
        compositionId: note.compositionId,
        sectionId: id,
        concatAudio: false
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }).then(({ data }) => {
      const audioTranscripts = get(data, 'transcripts.results')
      this.setState({
        errorFetchingAudio: data.error,
        loadingAudio: data.loading,
        audioTranscripts: audioTranscripts
      })
    }).catch(err => {
      setError(err)
      console.error(err)
    })
  }

  // Editor calls this on changes
  handleChange = (newValue, transcript) => {
    const { id, unprocess } = this.props
    const { markup, transcriptData } = this.state

    if (markup === newValue) return
    set(transcriptData, `["${transcript.audioUri}"].transcript`, newValue)
    set(transcriptData, `["${transcript.audioUri}"].processed`, false)

    this.setState({ transcriptData, markup: newValue })
    unprocess(id, transcript)
  }

  renderEditor = (hasFocus, transcript) => {
    const { enablePunct, focused } = this.props
    return (
      <div
        style={hasFocus ? styles.unprocessedEditor : {}}
        onFocus={() => {}}
      >
        <Editor
          defaultValue={V2_DEFAULT_CONTENT}
          onChange={value => this.handleChange(value, transcript)}
          persona={USER_PERSONA.ADMIN}
          editorType={EDITOR_TYPE.VERBATIM}
          enablePunct={enablePunct}
          focused={focused}
        />
      </div>
    )
  }

  handleProcessed = transcript => {
    const { id, handleButtonPress } = this.props
    const { transcriptData } = this.state
    const nextProcessedState = !get(transcriptData, `["${transcript.audioUri}"].processed`, false)
    if (nextProcessedState) {
      set(transcriptData, `["${transcript.audioUri}"].untranscribable`, false)
    }
    const transcriptText = get(transcriptData, `["${transcript.audioUri}"].transcript`, '')
    set(transcriptData, `["${transcript.audioUri}"].processed`, nextProcessedState)
    this.setState({ transcriptData })
    handleButtonPress(id, 'processed', nextProcessedState, transcript, transcriptText)
    handleButtonPress(id, 'untranscribable', false, transcript, transcriptText)
  }

  handleContainsPHI = transcript => {
    const { id, handleButtonPress } = this.props
    const { transcriptData } = this.state
    const toggledContainsPHI = !get(transcriptData, `["${transcript.audioUri}"].containsPHI`, false)
    const transcriptText = get(transcriptData, `["${transcript.audioUri}"].transcript`, '')
    set(transcriptData, `["${transcript.audioUri}"].containsPHI`, toggledContainsPHI)
    this.setState({ transcriptData })
    handleButtonPress(id, 'containsPHI', toggledContainsPHI, transcript, transcriptText)
  }

  handleUntranscribable = transcript => {
    const { id, handleButtonPress } = this.props
    const { transcriptData } = this.state
    const toggledUntranscribable = !get(transcriptData, `["${transcript.audioUri}"].untranscribable`, false)
    set(transcriptData, `["${transcript.audioUri}"].untranscribable`, toggledUntranscribable)
    if (toggledUntranscribable) {
      set(transcriptData, `["${transcript.audioUri}"].processed`, false)
      set(transcriptData, `["${transcript.audioUri}"].containsPHI`, false)
    }
    this.setState({ transcriptData })
    handleButtonPress(id, 'untranscribable', toggledUntranscribable, transcript, '')
    handleButtonPress(id, 'processed', false, transcript, '')
  }

  render () {
    const { audioTranscripts, waitingForAudio, transcriptData } = this.state
    const { focused, section } = this.props

    const hasAudio = get(audioTranscripts, 'length', 0) > 0
    const hasFocus = hasAudio || focused
    const sectionTag = section.name.replace(/\W/g, '-').toLowerCase()

    let audioAndEditors = []
    if (hasAudio) {
      audioAndEditors = audioTranscripts.map((t, i) => {
        const containsPHI = get(transcriptData, `["${t.audioUri}"].containsPHI`, false)
        const isProcessed = get(transcriptData, `["${t.audioUri}"].processed`, false)
        const isUntranscribable = get(transcriptData, `["${t.audioUri}"].untranscribable`, false)
        return (
          <div style={styles.sectionTextStyle} key={i}>
            <div style={styles.sectionActions}>
              <FancyCheckbox
                text='Contains PHI'
                handleClick={() => this.handleContainsPHI(t)}
                checked={containsPHI}
              />
              <div style={styles.checkboxSeperator} />
              <FancyCheckbox
                text='Untranscribable'
                handleClick={() => this.handleUntranscribable(t)}
                checked={isUntranscribable}
              />
              <div style={styles.checkboxSeperator} />
              <FancyCheckbox
                text='Processed'
                handleClick={() => this.handleProcessed(t)}
                checked={isProcessed}
              />
            </div>
            {waitingForAudio
              ? (
                <div style={styles.loadingContainer}>
                  <Loading />
                </div>
              )
              : (
                <PlaybackList
                  audioTranscripts={[t]}
                  sectionId={section.id}
                  isCurrentSection={focused}
                  sectionTag={sectionTag}
                />
              )}
            {this.renderEditor(hasFocus, t)}
          </div>
        )
      })
    }

    return (
      <div>
        {audioAndEditors}
      </div>
    )
  }
}

export default compose(
  withApollo,
  withRouter
)(EditableVerbatimTranscriptSection)
