import styled from 'styled-components'
import ArrowDropdown from '../../images/arrow_dropdown.svg'
import Search from '../../images/search.svg'

import { filterMenuWidth, filterMenuExpanded } from '../../styles/dimensions'
import { backgroundWhite, opsTeal, textDark } from '../../styles/colors'
import { textTiny, textMedium, weightBold } from '../../styles/typography'
import { boxShadows, zIndexValues } from '../../styles/shared'

export const NoteFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  height: 70px;
  width: 100%;
  margin-bottom: 50px;
`

/* Search Bar Components */

export const SearchInput = styled.input`
  border: 0;
  border-radius: 5px;
  outline: 0;

  width: 100%;

  /* temp */
  padding: 15px 30px;
  font-size: 18px;
  background: #E9EAEF;
  color: #626369;
`

export const DropDownArrow = styled.img.attrs({
  src: ArrowDropdown
})`
`

export const FilterMenuButton = styled.div`
  position: relative;
  background: ${backgroundWhite};
  height: 100%;
  width: ${filterMenuWidth};
  border-radius: 10px;
  margin-right: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 30px;

  font-size: ${textMedium};
  color: ${textDark};
  font-weight: ${weightBold};

  &:hover {
    cursor: pointer;
  }
`

export const FilterMenuButtonText = styled.div`
  width: 280px;
  word-wrap: break-word;
`

export const FilterMenu = styled.div`
  background: ${backgroundWhite};
  position: absolute;
  margin-top: 10px;
  z-index: ${zIndexValues.dropdown};
  padding: 10px 0;
  width: ${filterMenuExpanded.width};
  max-height: ${filterMenuExpanded.maxHeight};
  box-shadow: ${boxShadows.med};
  display:flex;
  flex-direction: column;
`

export const FilterMenuItem = styled.div`
  padding: 15px 25px;
  position: relative;
  display: flex;
  background: ${props => props.selected === true ? opsTeal : 'white'};
  &:hover {
    cursor: pointer;
    background: ${opsTeal};
  }
`

export const OrgCount = styled.div`
  position: absolute;
  right:  ${props => props.location === 'btn' ? '60px' : '20px'};
  padding: 5px;
  background: orange;
  font-weight: ${weightBold};
  border-radius: 50%;
  height: 15px;
  width: 15px;
  display: flex;
  font-size: ${textTiny};
  justify-content: center;
  text-align: center;
  align-items: center;
`

export const FilterDropdownSearch = styled.input`
  box-sizing: border-box;
  margin: auto;
  width: calc(100% - 10px);
  outline: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(19,124,189,0), 0 0 0 0 rgba(19,124,189,0), inset 0 0 0 1px rgba(16,22,26,.15), inset 0 1px 1px rgba(16,22,26,.2);
  background: #fff;
  height: 30px;
  padding: 0 10px 0 30px;
  vertical-align: middle;
  line-height: 30px;
  color: #182026;
  font-size: 14px;
  font-weight: 400;
  
  &:focus {
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19,124,189,.3), inset 0 1px 1px rgba(16,22,26,.2);
  }
`
export const SearchIcon = styled.img.attrs({
  src: Search
})`
    position: absolute;
    left: 12px;
    top: 16px;
    width: 18px;
`

export const FilterSearchContainer = styled.div`
  width:100%;
  display:flex;
`

export const FilterMenuItems = styled.div`
  overflow-y: scroll;
  margin: 10px 0;
`
