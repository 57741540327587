import React, { Component } from 'react'

import SearchBar from './SearchBar'
import FilterDropdown from './FilterDropdown'

import { NoteFilterContainer } from './styled'
import get from 'lodash.get'
import { loSto } from '../../config'
import { LO_STO } from '../../lib/constants'
class NoteContainer extends Component {
  state = {
    filterVal: '',
    items: [],
    filteredItems: []
  }

  render () {
    const { notesCount, onSearchChange, orgs, orgsNoteCount, setOrgId } = this.props
    const currentOrg = orgs.filter(org => org.id === loSto.session(LO_STO.SELECTED_ORG_ID))[0]
    const currentOrgText = get(currentOrg, 'name', 'ALL')
    const currentOrgId = get(currentOrg, 'id', 'ALL')
    return (
      <NoteFilterContainer>
        <FilterDropdown
          orgs={orgs}
          orgsNoteCount={orgsNoteCount}
          setOrgId={setOrgId}
          currentOrgId={currentOrgId}
          currentOrgText={currentOrgText}
          notesCount={notesCount}
        />
        <SearchBar onSearchChange={onSearchChange} />
      </NoteFilterContainer>
    )
  }
}

export default NoteContainer
