import gql from 'graphql-tag'

export const DeletePatients = gql`
  mutation DeletePatients($organizationId: ID, $ids: [ID!]!) {
    deletePatients(input: { 
        organizationId: $organizationId,
        ids: $ids 
    }) {
        success
      }
  }
`
