import React, { Component } from 'react'

import {
  DropDownArrow,
  FilterDropdownSearch,
  FilterMenu,
  FilterMenuItem,
  FilterMenuItems,
  FilterMenuButton,
  FilterSearchContainer,
  FilterMenuButtonText,
  OrgCount,
  SearchIcon
} from './styled'

class FilterDropdown extends Component {
  state = {
    isMenuOpened: false,
    selectedOrg: 'ALL',
    searchQuery: ''
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClick)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClick)
  }

  handleClick = e => {
    if (this.node.contains(e.target)) return
    this.setState({ isMenuOpened: false })
  }

  handleOrgClick = (orgId, orgName) => {
    const { setOrgId } = this.props
    this.setState({ selectedOrg: orgName, isMenuOpened: false })
    setOrgId(orgId)
  }

  handleMenuClick = () => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened, searchQuery: '' })
  }

  handleValueChanged = (e) => {
    const { value } = e.target
    this.setState({ searchQuery: value })
  }

  renderOrgNoteCount = (orgId, location) => {
    const { notesCount, orgsNoteCount } = this.props

    const count = orgId === 'ALL' ? notesCount : orgsNoteCount[orgId]

    if (count > 0) {
      return <OrgCount data-cy='note-count' location={location}>{count}</OrgCount>
    }

    return null
  }

  render () {
    const { notesCount, orgs, currentOrgId } = this.props
    const { isMenuOpened, searchQuery } = this.state
    const filteredOrgs = orgs.filter(org => org.name.toLowerCase().includes(searchQuery.toLowerCase(0)))
    const MenuItems =
      filteredOrgs.length > 0 &&
      filteredOrgs.map(org => {
        const selected = this.props.currentOrgText
        return (
          <FilterMenuItem
            data-cy='organization-name'
            key={org.name}
            selected={selected === org.name}
            onClick={() => this.handleOrgClick(org.id, org.name)}
          >
            {org.name}
            {this.renderOrgNoteCount(org.id)}
          </FilterMenuItem>
        )
      })
    return (
      <div ref={node => (this.node = node)}>
        <FilterMenuButton data-cy='organization-dropdown' onClick={this.handleMenuClick}>
          <FilterMenuButtonText>
            {this.props.currentOrgText === 'ALL' ? 'All Organizations' : this.props.currentOrgText}
          </FilterMenuButtonText>
          {this.renderOrgNoteCount(currentOrgId, 'btn')}
          <DropDownArrow />
        </FilterMenuButton>
        {isMenuOpened && (
          <FilterMenu>
            <FilterSearchContainer>
              <SearchIcon />
              <FilterDropdownSearch
                data-cy='filter-by-name' type='text' autoFocus onChange={this.handleValueChanged}
                placeholder='Filter by name...'
              />
            </FilterSearchContainer>
            <FilterMenuItems>
              <FilterMenuItem data-cy='all-org' selected={this.props.currentOrgText === 'ALL'} onClick={() => this.handleOrgClick(null, 'ALL')}>
                All Organizations
                <OrgCount>{notesCount}</OrgCount>
              </FilterMenuItem>
              {MenuItems}
            </FilterMenuItems>
          </FilterMenu>
        )}
      </div>
    )
  }
}

export default FilterDropdown
