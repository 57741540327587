import React from 'react'
import styled from 'styled-components'
import { whiteSmoke, charcoal } from '../../styles/colors'
import { Z_INDEX } from '../../lib/constants'
import { convertS2ToPlainText } from '../../lib/util'
import { isCharUnsupported } from '@sukiai/utils'
import StyledButton from '../StyledButton'

const Veil = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX.VEIL};
`

const Container = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  width: 600px;
  padding: 25px;
  background: ${whiteSmoke};
  color: ${charcoal};
  border-radius: 4px;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEX.MODAL};
  position: relative;
`

const Heading = styled.div`
  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    margin: 0;
  }

  p {
    font-size: 18px;
    margin: 0 0 20px 0;
  }
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  .button-controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`

const Section = styled.div`
  font-size: 20px;
  margin: 10px 0;
`

export default class ContentErrorsDialog extends React.Component {
    handleClose = () => {
      const { closeDialog } = this.props
      closeDialog()
    }

    highlightErrors = (text) => {
      // returns JSX with styles applied to chars unsupported
      const parts = text.split('')
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                isCharUnsupported(part)
                  ? { background: '#ffc3c1', color: '#a94948', paddingLeft: '2px', paddingRight: '2px' }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      )
    }

    renderSections = () => {
      const { content } = this.props
      const plainText = convertS2ToPlainText(content)
      return (
        <Section>
          <div>{this.highlightErrors(plainText)}</div>
        </Section>
      )
    }

    render () {
      const { open } = this.props
      return (
        open && (
          <Veil onClick={this.handleClose}>
            <Container onClick={e => e.stopPropagation()}>
              <Body>
                <Heading>
                  <h2>Error: Unsupported Characters</h2>
                  <p>We found errors regarding unsupported chracters. Please fix before saving.</p>
                </Heading>
                {this.renderSections()}

                <div className='button-controls'>
                  <StyledButton
                    label='ok'
                    secondary
                    onClick={this.handleClose}
                    key='ok'
                    data-cy='modal-btn-cancel'
                  >ok
                  </StyledButton>
                </div>

              </Body>
            </Container>
          </Veil>
        )
      )
    }
}
