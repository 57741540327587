import gql from 'graphql-tag'

// USED IN: SCRIBE_NOTE
export const GetCompositionsByIdSms = gql`
query GetCompositionsByIdSms($organizationId: ID!, $ids: [ID!]) {
    compositionsSms(
      input: {
        organizationId: $organizationId
        ids: $ids
        includeMetadata: true
        includeSections: true
      }
    ) {
      results {
        ... on Composition {
          id
          userId
          metadata {
            name
            appointment {
              id
              startsAt
            }
            partialDictation {
              partialDictationComposition,
              readOnly
            }
            status
            patient {
              id
              person {
                firstName
                middleName
                lastName
                prefix
                suffix
                gender
                dateOfBirth
              }
            }
            user {
              id
              person {
                firstName
                middleName
                lastName
              }
            }
            reviewMessage
          }
          sections {
            id
            name
            content
            status
            cursors {
              actor
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          }
          sectionsS2 {
            id
            name
            navigationKeywords
            content
            status
            cursors {
              actor
              location
            }
            hash
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
            sectionIndex
            subsectionIndex
            cursorPosition
            cursorPositionName
            editLocation
            plainText
            updateType
            opsStatusFlag
            numberOfCursorEvents
            dictationTag
            readOnly
          }
          readOnly
          createdAt
        }
        ... on PatientNote {
          noteId
          compositionId
          metadata {
            name
            appointment {
              id
              startsAt
            }
            partialDictation {
              partialDictationComposition,
              readOnly
            }
            status
            patient {
              id
              person {
                firstName
                middleName
                lastName
                prefix
                suffix
                gender
                dateOfBirth
              }
            }
            user {
              id
              person {
                firstName
                middleName
                lastName
              }
            }
            reviewMessage
          }
          sections {
            id
            name
            content
            status
            cursors {
              actor
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          }
          sectionsS2 {
            id
            name
            navigationKeywords
            content
            status
            cursors {
              actor
              location
            }
            hash
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
            sectionIndex
            subsectionIndex
            cursorPosition
            cursorPositionName
            editLocation
            plainText
            updateType
            opsStatusFlag
            numberOfCursorEvents
            dictationTag
            readOnly
          }
        }
      }
    }
  }
`

export const GetCompositionMetadataByIdSms = gql`
  query GetCompositionMetadataByIdSms($organizationId: ID!, $id: ID!) {
    compositionsSms(input: {
      organizationId: $organizationId,
      ids: [$id],
      includeMetadata: true,
      includeSections: false
    }) {
      count,
      results {
        ... on Composition {
          id,
          metadata {
            name,
            noteTypeId,
            status,
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2,
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent
            }
          }
        }
      }
    }
  }
`

// USED IN: SCRIBE_NOTES_TO_PROCESS
// We now have organizationId in the composition top-level unlike before SMS
// Sometimes the metadata.user object doesn't have organizationId set, so we fallback to the
// new organizationId field if user.organizationId is not set
export const GetCompositionOrNoteSms = gql`
 query GetCompositionOrNoteSms($id:ID!) {
  compositionOrNoteSms(input: {
    id: $id
  }) {
    composition {
      ... on Composition {
        id,
        userId,
        organizationId,
        metadata {
          user {
            id
            organizationId
          }
        }
      }
      ... on PatientNote {
        noteId,
        organizationId,
        metadata {
          name,
          user {
            id
            organizationId
          }
        }
      }
    }
  }
 }
`

// USED IN: SCRIBE_NOTE
export const GetVersionedCompositionSms = gql`
  query GetVersionedCompositionSms($organizationId: ID!, $id: ID!) {
    versionedCompositionSms(input: {
      organizationId: $organizationId,
      id: $id
    }) {
      note {
        ... on VersionedComposition {
          id
          sectionsS2 {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                content
                name
                navigationKeywords
                status
                cursors {
                  actor
                  location
                }
                cursorPosition
                hash
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
                sectionIndex
                subsectionIndex
                cursorPosition
                cursorPositionName
                editLocation
                plainText
                updateType
                opsStatusFlag
                numberOfCursorEvents
              }
            }
          }
        }
        ... on VersionedNote {
          noteId
          compositionId
          sectionsS2 {
            id
            sectionEntries {
              author {
                organizationId
                userId
                type
                role {
                  type
                }
                userName
                organizationName
              }
              createdAt
              section {
                name
                navigationKeywords
                content
                status
                cursors {
                  actor
                  location
                }
                diagnosisEntry {
                  diagnosis {
                    icdCode
                    billable
                    snomedCode
                    description
                    snomedDescription
                    hccCode
                    panelRanking
                    suggestionType
                  }
                  detailedDisplay {
                    tags {
                      text
                    }
                  }
                  confirmStatus
                }
                pbcSectionFlag
                sectionIndex
                subsectionIndex
                cursorPosition
                cursorPositionName
                editLocation
                plainText
                updateType
                opsStatusFlag
                numberOfCursorEvents
              }
            }
          }
        }
      }
    }
  }
`
