import get from 'lodash/get'

export const getFullName = person => {
  if (!person) return ''
  let middleName = ' '
  if (get(person, 'middleName.length', 0) > 0) {
    middleName = ` ${person.middleName[0]}. `
  }
  return person.firstName + middleName + person.lastName
}
