import moment from 'moment'
import loSto from './loSto'

export const isAmwellTheme = () => loSto.get('themeMode') === 'dark'

export const getPagetype = () => loSto.get('pageType')

export const getShouldHideToast = _type => loSto.get(_type)

export const destroyOobeToast = _type => loSto.set(_type, 'true')

export const getFullName = (person, userType) => {
  let name = ''
  if (person) {
    if (person.middleName && person.middleName.length > 0) {
      name = `${person.firstName} ${person.middleName[0]}. ${person.lastName}`
    } else {
      name = `${person.firstName} ${person.lastName}`
    }
  }
  if (userType) {
    name += `, ${userType}`
  }
  return name
}

export const getGender = person => (
  person && person.gender && person.gender !== 'UNKNOWN'
    ? person.gender[0] + person.gender.substring(1).toLowerCase()
    : null
)

export const getAge = person => {
  if (person && person.dateOfBirth) {
    if (new Date(person.dateOfBirth).getTime() === 0) return '--'

    const ageYears = moment().diff(person.dateOfBirth, 'years')
    const ageMonths = moment().diff(person.dateOfBirth, 'months')
    return ageYears >= 2
      ? ageYears
      : ageMonths >= 1 && `${ageMonths} ${ageMonths === 1 ? 'month' : 'months'}`
  }
  return null
}

export const displayAgeGender = (age, gender) => {
  return `${age ? `Age ${age}` : ''}${age && gender ? '  •  ' : ''}${gender || ''}`
}

export const displayAge = age => {
  return `${age ? `Age ${age}` : ''}`
}
