import React, { Component } from 'react'

const styles = {
  fancyCheckbox: {
    position: 'relative',
    border: '1px solid black',
    borderRadius: '20px',
    padding: '8px 16px'
  }
}

class FancyCheckbox extends Component {
  handleToggleCheckbox = () => {
    this.props.handleClick()
  }

  render () {
    const { checked, text } = this.props
    return (
      <div style={Object.assign({}, styles.fancyCheckbox, checked && { backgroundColor: 'green' })} onClick={this.handleToggleCheckbox}>
        <div />
        <div>{text}</div>
      </div>
    )
  }
}

export default FancyCheckbox
