import { Value } from 'slate'
import {
  KEYCODES,
  V2_DEFAULT_CONTENT,
  V2_DEFAULT_BLOCK,
  EDITOR_TYPE
} from './constants'
import get from 'lodash.get'

// Function that handles key press to allow Ctrl+<key> combinations and for enter key(admin view)
// Currently used for bold and italic styles and enter keys
export const MarkHotkey = options => {
  const { key, type } = options
  // Return our 'plugin' object, containing the `onKeyDown` handler.
  return {
    onKeyDown (event, change, next) {
      if (event.key === KEYCODES.ENTER && options.key === KEYCODES.ENTER) {
        return change.insertText('\n')
      }
      // Check that the key pressed matches our `key` option.
      if (!event.ctrlKey || event.key !== key) return next()

      // Prevent the default characters from being inserted.
      event.preventDefault()

      // Toggle the mark `type`.
      change.toggleMark(type)
      return next()
    }
  }
}

export const getNodeToInsert = editorType => {
  switch (editorType) {
    case EDITOR_TYPE.NOTE:
      return 'doctor_typed'
    case EDITOR_TYPE.NOTETYPE:
      return 'prepopulated'
    case EDITOR_TYPE.SCRIPT:
      return 'script_content'
    case EDITOR_TYPE.OPS:
      return 'ops_typed'
    case EDITOR_TYPE.NEEDS_REVIEW:
    case EDITOR_TYPE.VERBATIM:
      return 'default'
    default:
      return 'doctor_typed'
  }
}

export const isContentChanged = (newVal, oldVal) => {
  return get(newVal, 'document.text', '') !== get(oldVal, 'document.text', '')
}

// Very sketch logic methinks
export const isDeletion = editor => {
  const operationType = get(editor, 'operations._tail.array[1].type', '')
  return operationType === 'remove_text' || operationType === 'remove_node'
}

export const isReplaceText = editor => {
  const operations = get(editor, 'operations._tail.array', [])
  const operationTypeOne = get(operations, '[1].type', '')
  const operationTypeTwo = get(operations, `[${operations.length - 1}].type`, '')

  return (operationTypeOne === 'remove_text' || operationTypeOne === 'remove_node') &&
    (operationTypeTwo === 'insert_text' || operationTypeTwo === 'insert_node')
}

export const isSectionEmpty = value => {
  return value.document.text === ''
}

export const isBlockEmpty = block => JSON.stringify(block, null, 2) === V2_DEFAULT_BLOCK

export const isBetweenDifferentInline = (value, inlineType) => {
  const anchorInline = value.anchorInline
  return anchorInline && anchorInline.type !== inlineType
}

export const isInBufferTextNode = value => {
  return !get(value, 'anchorInline')
}

export const toJson = slateContent => {
  return JSON.stringify(slateContent.toJSON())
}

export const toSlate = jsonContent => {
  // If jsonContent is null, return a Value with default content
  if (!jsonContent) {
    return Value.fromJSON(JSON.parse(V2_DEFAULT_CONTENT))
  }

  let parsedJson
  let value

  // Parse the content into JSON
  try {
    parsedJson = JSON.parse(jsonContent)
  } catch (err) {
    console.error(`Unable to parse string [${jsonContent}] into JSON: ${err}`)
    return ''
  }

  // Parse the JSON into a Slate Value object
  try {
    value = Value.fromJSON(parsedJson)
  } catch (err) {
    console.error(`Unable to convert JSON ${parsedJson} to Slate Value object: ${err}`)
    return ''
  }

  return value
}

export const getEditorVersion = contentStr => {
  let parsedStr = ''
  try {
    parsedStr = JSON.parse(contentStr)
  } catch (err) {
    console.error(`Unable to parse string [${contentStr}] into JSON: ${err}`)
    return ''
  }
  return get(parsedStr, 'document.data.editorVersion', '1.0.0')
}

export const newEmptyTextNode = () => ({
  object: 'text',
  leaves: [{
    object: 'leaf',
    text: ''
  }]
})

export const newInlineNode = (type, text) => ({
  object: 'inline',
  type: type,
  nodes: [{
    object: 'text',
    leaves: [{
      object: 'leaf',
      text: text
    }]
  }]
})

export const newSelection = (anchor, focus) => ({
  anchor,
  focus,
  isFocused: true,
  object: 'selection'
})

export const newPoint = (offset, path) => ({ object: 'point', offset, path })
