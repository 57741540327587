import gql from 'graphql-tag'

// USED IN: SCRIBE_NOTE
export const GetCompositionsById = gql`
  query GetCompositionsById($organizationId: ID!, $ids: [ID!]) {
    compositions(input: {
      organizationId: $organizationId,
      ids: $ids,
      includeMetadata: true,
      includeSections: true
    }) {
      results {
        ... on Composition {
          id,
          userId,
          metadata {
            name,
            appointment {
              id,
              startsAt
            },
            status,
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              }
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            },
            reviewMessage
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            },
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          },
          readOnly,
          createdAt
        },
        ... on PatientNote {
          noteId,
          compositionId,
          metadata {
            name,
            appointment {
              id,
              startsAt
            },
            status,
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              }
            },
            user {
              id,
              person {
                firstName,
                middleName,
                lastName
              }
            },
            reviewMessage
          },
          sections {
            id,
            name,
            content,
            status,
            cursors {
              actor,
              location
            }
            diagnosisEntry {
              diagnosis {
                icdCode
                billable
                snomedCode
                description
                snomedDescription
                hccCode
                panelRanking
                suggestionType
              }
              detailedDisplay {
                tags {
                  text
                }
              }
              confirmStatus
            }
            pbcSectionFlag
          }
        }
      }
    }
  }
`

// USED IN: SIDEBAR
export const GetNotesToProcessCount = gql`
  query GetNotesToProcessCount($organizationIds: [ID]!) {
    needsReviewCount(input: {
      organizationIds: $organizationIds
    }) {
      results {
        organizationId,
        count
      }
    }
  }
`

// USED IN: NOTES_TO_PROCESS, SCRIBE_NOTE, RECOGNITION_STREAM
export const GetNotesToProcess = gql`
  query GetNotesToProcess {
    needsReview {
      count,
      results {
        id,
        organizationId,
        createdAt,
        scribe {
          id,
          firstName,
          middleName,
          lastName
        }
        noteBeingProcessed
        metadata {
          patient {
            id,
            person {
              firstName,
              middleName,
              lastName,
            }
          },
          user {
            id,
            person {
              firstName,
              middleName,
              lastName
            }
          },
          reviewMessage
        }
      }
    }
  }
`

// USED IN: SCRIBE_NOTES_TO_PROCESS
export const GetCompositionOrNoteById = gql`
 query GetCompositionOrNoteById($id:ID!) {
  compositionOrNote(input: {
    id: $id
  }) {
    composition {
      ... on Composition {
        id,
        userId,
        metadata {
          user {
            id
            organizationId
          }
        }
      }
      ... on PatientNote {
        noteId,
        metadata {
          name
          user {
            id,
            organizationId
          }
        }
      }
    }
  }
 }
`

// USED IN: SCRIBE_NOTE
// 06/28/2020 - notice the server side call is now calling opsUpdateCompositionSms
export const UpdateComposition = gql`
  mutation UpdateComposition($organizationId: ID!, $id: ID!, $sectionsS2: [CompositionSectionS2Input!]) {
    opsUpdateCompositionSms(input: {
      organizationId: $organizationId,
      id: $id,
      sectionsS2: $sectionsS2
    }) {
      status
    }
  }
`

// USED IN: SCRIBE_NOTE
// 06/28/2020 - notice the server side call is now calling opsUpdateCompositionSms
export const AddReviewToComposition = gql`
  mutation AddReviewToComposition($organizationId: ID!, $id: ID!, $metadata: MetadataInput!) {
    opsUpdateCompositionSms(input: {
      organizationId: $organizationId,
      id: $id,
      metadata: $metadata
    }) {
      status
    }
  }
`

// USED IN: SCRIBE_NOTE
export const SubmitComposition = gql`
  mutation SubmitComposition($organizationId: ID!, $id: ID!) {
    submitComposition(input: {
      organizationId: $organizationId,
      id: $id,
      actor: SCRIBE,
      tryAllDestinations: true,
    }) {
      noteId
      submissionStatus {
        warning {
          type
          message
        }
        finalDestination
      }
    }
  }
`

export const GetCompositionMetadataById = gql`
  query GetCompositionMetadataById($organizationId: ID!, $id: ID!) {
    compositionsSms(input: {
      organizationId: $organizationId,
      ids: [$id],
      includeMetadata: true,
      includeSections: false
    }) {
      count,
      results {
        ... on Composition {
          id,
          metadata {
            name,
            noteTypeId,
            status,
            partialDictation {
              partialDictationComposition,
              readOnly
            },
            submissionInformation {
              destinations,
              tryAllDestinations
            },
            submissionStatus {
              warning {
                type,
                message
              },
              finalDestination
            },
            clientType,
            createdWithS2,
            appointment {
              id,
              owner {
                id,
                roles {
                  type
                },
                person {
                  firstName,
                  middleName,
                  lastName,
                  prefix,
                  suffix,
                  gender,
                  dateOfBirth
                },
                authId,
                email,
                specialty
                specialties,
                timezone,
                userType,
                activationSent
              },
              patient {
                id,
                person {
                  firstName,
                  middleName,
                  lastName,
                  dateOfBirth,
                  gender,
                  prefix,
                  suffix
                },
                mrn,
                emrId,
                emrIdType
              },
              startsAt,
              endsAt,
              type,
              medicalProcedure,
              reason,
              emrId,
              emrEncounterId,
              emrDepartmentId
            },
            encounterAddress {
              line1,
              line2,
              city,
              state,
              zip,
              country
            },
            patient {
              id,
              person {
                firstName,
                middleName,
                lastName,
                dateOfBirth,
                gender,
                prefix,
                suffix
              },
              mrn,
              emrId,
              emrIdType
            },
            user {
              id,
              roles {
                type
              },
              person {
                firstName,
                middleName,
                lastName,
                prefix,
                suffix,
                gender,
                dateOfBirth
              },
              authId,
              email,
              specialty,
              specialties,
              timezone,
              userType,
              activationSent,
              organizationId
            }
          }
        }
      }
    }
  }
`

export const GetCompositionSections = gql`
  query GetCompositionSections($organizationId: ID!, $compositionId: ID!) {
    compositions(input: {
      organizationId: $organizationId
      ids: [$compositionId]
      includeSections: true
    }) {
      results {
        # If it's a composition
        ... on Composition {
          id
          sections {
            id
            name
          }
        }
        # If it's a patient note
        ... on PatientNote {
          noteId
          compositionId
          sections {
            id
            name
          }
        }
      }
    }
  }
`

export const GetNoteFromQueue = gql`
  query GetNoteFromQueue {
    noteFromQueue {
      orgId,
      userId,
      noteId,
      status
    }
  }
`

export const LockNoteForEdit = gql`
  mutation LockNoteForEdit ($noteId: ID) {
    lockNoteForEdit(input: {
      noteId: $noteId
    }) {
      scribe {
        id,
        firstName,
        middleName,
        lastName
      }
      noteId,
      status
    }
  }
`

export const ReleaseNoteFromQueue = gql`
  mutation releaseNoteFromQueue ($noteId: ID!, $releaseType: ReleaseType, $reviewMessage: String){
    releaseNoteFromQueue(input: {
      noteId: $noteId,
      releaseType: $releaseType,
      reviewMessage: $reviewMessage
    }) {
      noteId,
      status
    }
  }
`

export const NoteIsActive = gql`
  query noteIsActive ($noteId: ID){
    noteIsActive(input: {
      noteId: $noteId
    }) {
      scribe {
        id,
        firstName,
        middleName,
        lastName
      },
      status
    }
  }
`
