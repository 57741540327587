import React, { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'
import { ListItem } from 'material-ui/List'
import { setNoteId } from '../../actions/note'
import { setCurrentPatient } from '../../actions/patient'
import Radium from 'radium'
import {
  backgroundGray,
  backgroundLightGray,
  borderGray,
  borderLightGray,
  charcoal,
  scribeGreen,
  textLight,
  textLightest,
  warning
} from '../../styles/colors'
import { queueColumnContentWidth } from '../../styles/dimensions'
import { ROUTES, UNKNOWN_PATIENT_NAME, SUBMISSION_PRIORITY } from '../../lib/constants'
import { connect } from 'react-redux'
import { getFullName } from '@sukiai/utils'
import { getSubmissionPriority, getSubmissionText } from '../../lib/util'
import Divider from 'material-ui/Divider'
import needsReviewIcon from '../../images/needs-review-icon.png'
import styled from 'styled-components'

import { textMedium, textSmall } from '../../styles/typography'
import { white } from 'material-ui/styles/colors'

const EMPTY_NOTE_LIST_TEXT = 'You currently have no notes to process'

const styles = {
  date: {
    fontSize: 16,
    fontWeight: 500
  },
  patient: {
    fontSize: 20,
    fontWeight: 600
  },
  doctor: {
    fontSize: 18,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center'
  },
  itemBody: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between'
  },
  itemHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  itemNoNotes: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20
  },
  listItem: {
    background: white,
    borderBottom: '1px solid #DEDEDE',
    color: charcoal,
    padding: '10px 0'
  },
  beingProcessedLabel: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -80%)',
    color: scribeGreen
  },
  needsReview: {
    fontWeight: 'bold',
    marginBottom: '6px'
  },
  needsReviewIcon: {
    position: 'absolute',
    right: 0,
    bottom: '45%',
    transform: 'translateY(50%)',
    cursor: 'pointer'
  },
  tooltip: {
    width: '300px',
    backgroundColor: 'white',
    border: '1px solid rgb(210, 210, 210)',
    padding: '10px',
    borderRadius: '6px',
    height: '60px',
    overflow: 'auto',

    /* Position the tooltip text - see examples below! */
    position: 'absolute',
    right: '105%',
    bottom: '100%',
    wordWrap: 'break-word',
    zIndex: 10000
  },
  needsReviewEditor: {
    width: '100%',
    height: '100%',
    border: 'none',
    overflow: 'auto',
    ':focus': {
      outline: 'none'
    }
  }
}

const NoteRow = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.noteBeingProcessed === 'true' ? backgroundGray : 'white'} ;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.noteBeingProcessed === 'true' ? borderGray : borderLightGray};
  &:hover {
    cursor: pointer;
    background: ${props => props.noteBeingProcessed === 'true' ? backgroundGray : backgroundLightGray} ;
  }

`

const NoteMeta = styled.div`
  width:33%;
  padding:25px;
`

const PrimaryText = styled.h4`
  padding:0;
  margin: 0;
  margin-bottom:10px;
  font-size: ${textMedium};
  font-weight: 600;

  width: ${queueColumnContentWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SecondaryText = styled.p`
  padding:0;
  margin:0;
  font-size: ${textSmall};
`

const handleSubmissionPriority = priority => {
  switch (priority) {
    case SUBMISSION_PRIORITY.HIGH:
      return `color: ${warning}; font-weight: 600;`
    case SUBMISSION_PRIORITY.MED:
      return `color: ${textLight}; font-weight: 500;`
    case SUBMISSION_PRIORITY.LOW:
      return `color: ${textLightest}; font-weight: 400`
    default:
      return `color: ${textLightest}; font-weight:400`
  }
}

const SubmissionText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 20px;

  ${({ priority }) => handleSubmissionPriority(priority)};
`

class ScribeNoteItem extends Component {
  state = {
    showTooltip: false
  }

  handleClick = () => {
    const {
      history,
      note,
      setNoteId,
      setCurrentPatient
    } = this.props
    // This is actually a note composition and not a finalized note
    const noteId = get(note, 'id')
    const orgId = get(note, 'organizationId')
    const patient = get(note, 'metadata.patient')
    const userId = get(note, 'metadata.user.id')
    setNoteId(noteId)
    setCurrentPatient(patient)
    console.debug('Scribe Routepath', ROUTES.NOTE)
    history.push(`/${orgId}/${userId}${ROUTES.NOTE}/${noteId}`)
  }

  render () {
    const { note, org } = this.props
    const { showTooltip } = this.state
    const noteBeingProcessed = get(note, 'noteBeingProcessed', false)
    if (!note) {
      return (
        <ListItem innerDivStyle={styles.listItem}>
          <div style={styles.itemNoNotes}>
            {EMPTY_NOTE_LIST_TEXT}
          </div>
        </ListItem>
      )
    }

    const patientName = getFullName(get(note, 'metadata.patient.person'))
    const reviewMessage = get(note, 'metadata.reviewMessage')
    const scribeId = get(note, 'scribe.id')
    const scribeFullName = scribeId && get(note, 'scribe.firstName', '') + ' ' + get(note, 'scribe.lastName')

    const createdAt = get(note, 'createdAt')

    const submissionDate = new Date(createdAt)
    const submissionPriority = getSubmissionPriority(submissionDate)
    const submissionDateText = getSubmissionText(submissionDate)

    const orgName = get(org, 'name')

    return (
      <div>
        <div
          onClick={this.handleClick}
          data-cy={`item-ntp-${note.id}`}
        >
          <NoteRow noteBeingProcessed={noteBeingProcessed}>
            <NoteMeta>
              <PrimaryText>{patientName || UNKNOWN_PATIENT_NAME}</PrimaryText>
              <SecondaryText data-cy='doctor-name'> {note.metadata.user && note.metadata.user.person && `Dr. ${getFullName(note.metadata.user.person)}`}</SecondaryText>
            </NoteMeta>
            <NoteMeta>
              <PrimaryText title={orgName}>{orgName}</PrimaryText>
              {scribeFullName && <SecondaryText> Being processed by: <strong>{scribeFullName}</strong></SecondaryText>}
            </NoteMeta>
            <NoteMeta>
              <SubmissionText priority={submissionPriority} data-cy='submitted-time'>{submissionDateText}</SubmissionText>
            </NoteMeta>
          </NoteRow>
          {reviewMessage &&
            <div
              style={styles.needsReviewIcon}
              onMouseLeave={() => { this.setState({ showTooltip: false }) }}
              onMouseEnter={() => { this.setState({ showTooltip: true }) }}
            >
              <img src={needsReviewIcon} width={70} height={70} alt='needsReviewIcon' data-cy='review-icon' />
              <div style={{ position: 'relative' }}>
                {showTooltip && (
                  <div style={styles.tooltip}>
                    <div style={styles.needsReview}>Needs review</div>
                    <textarea
                      data-cy='review-message'
                      readOnly
                      value={reviewMessage}
                      style={styles.needsReviewEditor}
                    />
                  </div>
                )}
              </div>
            </div>}
        </div>
        <Divider />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setNoteId: id => dispatch(setNoteId(id)),
  setCurrentPatient: patient => dispatch(setCurrentPatient(patient))
})

ScribeNoteItem.propTypes = {
  /**
   * React router history
   */
  history: PropTypes.object,

  /**
   * Summary information about the note
   */
  note: PropTypes.object
}

export default connect(null, mapDispatchToProps)(Radium(ScribeNoteItem))
