import React, { Component } from 'react'
import { getAge, getGender } from '../../lib/util'
import { getFullName } from '@sukiai/utils'
import { UNKNOWN_PATIENT_NAME } from '../../lib/constants'
import moment from 'moment'

const styles = {
  header: {
    width: '100%',
    padding: '30px 50px',
    boxSizing: 'border-box'
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  name: {
    fontSize: 24,
    fontWeight: 800,
    padding: 0,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  age: {
    fontSize: 18,
    fontWeight: 400
  },
  gender: {
    marginBottom: -3
  },
  patientInfo: {

    fontSize: 14,
    padding: 0,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1
  },
  noteName: {
    fontSize: 19,
    fontWeight: 600,
    marginBottom: 20,
    textAlign: 'center'
  }
}

class NoteHeader extends Component {
  render () {
    const {
      patient,
      noteName,
      dateOfService
    } = this.props

    const patientPerson = patient && patient.person
    const patientName = patientPerson && getFullName(patientPerson)
    const age = patientPerson && getAge(patientPerson.dateOfBirth)
    const gender = patientPerson && getGender(patientPerson)
    const dob = patientPerson && (new Date(patientPerson.dateOfBirth).getTime() !== 0) && moment(patientPerson.dateOfBirth).utc().format('MM/DD/YYYY')
    const dos = moment(dateOfService).format('MM/DD/YYYY')

    return (
      <div style={Object.assign({}, styles.header, this.props.style)}>
        {noteName && <div style={styles.noteName} data-cy='note-name'>{noteName}</div>}
        <div style={styles.info}>
          <div style={styles.name}>
            <div data-cy='patient-name'>{patientName || UNKNOWN_PATIENT_NAME}</div>
            {age && <div style={styles.age} data-cy='patient-age-and-gender'>Age {age}&nbsp;&nbsp;&bull;&nbsp;&nbsp;{gender}</div>}
          </div>
          <div style={styles.patientInfo}>
            {patient && patient.mrn && <div data-cy='patient-mrn'>MRN: {patient.mrn}</div>}
            {dob && <div data-cy='patient-dob'>Date of Birth: {dob}</div>}
            {dos && <div data-cy='patient-dos'>Date of Service: {dos}</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default NoteHeader
