import gql from 'graphql-tag'

// Gets a note to populate VerbatimNote
export const GetNoteForVerbatimTranscription = gql`
  query GetNoteForVerbatimTranscription {
    noteForTranscription(input: {}) {
      note {
        noteId,
        compositionId,
        metadata {
          name,
          appointment {
            id,
            startsAt
          },
          status,
          patient {
            id,
            person {
              firstName,
              middleName,
              lastName,
              prefix,
              suffix,
              gender,
              dateOfBirth
            }
          },
          user {
            id,
            person {
              firstName,
              middleName,
              lastName
            },
            organizationId
          },
          reviewMessage
        },
        sections {
          id,
          name,
          content,
          status,
          cursors {
            actor,
            location
          }
        }
      }
    }
  }
`

// Submits the transcript for a audio file to the backend
export const SubmitTranscript = gql`
  mutation SubmitTranscript($noteId: ID!, $compositionId: ID!, $organizationId: ID!, $userId: ID!, $transcriptData: [TranscriptDataInput]!) {
    submitTranscript(input: {
      noteId: $noteId,
      compositionId: $compositionId,
      organizationId: $organizationId,
      userId: $userId,
      transcriptData: $transcriptData
    }) {
      success
    }
  }
`

// Recycles a note that was in the proccess of being transcribed. Happens when a scribe leaves the page or the page times out.
export const RecycleNote = gql`
  mutation RecycleNote($noteId: ID!) {
    recycleNote(input: {
      noteId: $noteId
    }) {
      success
    }
  }
`
