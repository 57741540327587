import React from 'react'
import { compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { setInfoModal } from '../actions/modals'
import NoteInfoModal from '../components/NoteInfoModal'
import { isAdmin } from '../lib/util'
import get from 'lodash.get'

const styles = {
  veilContainer: {
    position: 'fixed', // remove this if it messes up z-index stuff
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 20
  }
}

class Veil extends React.Component {
  handleVeilClick = e => {
    if (get(e, 'target.id') === 'veil') {
      this.props.setInfoModal(false)
    }
  }

  render () {
    const { isInfoModalOpen } = this.props
    const isVeilActive = isInfoModalOpen

    return (
      <div>
        {isVeilActive &&
          <div style={styles.veilContainer} onClick={this.handleVeilClick} id='veil'>
            {isInfoModalOpen && <NoteInfoModal isAdmin={isAdmin()} />}
          </div>}
      </div>
    )
  }
}

const mapStateToProps = ({ modals }) => {
  return ({
    isInfoModalOpen: modals.isInfoModalOpen
  })
}

const mapDispatchToProps = dispatch => ({
  setInfoModal: isOpen => dispatch(setInfoModal(isOpen))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApollo
)(Veil)
