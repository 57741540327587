import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import { setCurrentSection } from '../../actions/note'
import { scribeGreen, backgroundGray } from '../../styles/colors'
import EditableTranscriptSection from './EditableTranscriptSection'
import { sectionNameSize } from '../../styles/dimensions'
import { connect } from 'react-redux'
import Radium from 'radium'
import { loSto } from '../../config'
import { LO_STO } from '../../lib/constants'
import { copyScript } from '../../lib/util'
import styled from 'styled-components'
import get from 'lodash.get'

const SectionContainer = styled.div`
  padding: ${props => props.isProblemSection ? '0 50px 20px 100px' : '0 50px 20px 50px'};
  box-sizing: border-box;
  word-wrap: break-word;
  border: none;
  border-radius: 0;
  cursor: pointer;
  border-left: ${props => props.isFocused ? `10px solid ${backgroundGray}` : 'none'};
`

const Code = styled.div`
  position: relative;
  margin: 0px 0px 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  outline: 1px solid rgba(240, 244, 239, 1);
  box-shadow: 0 0 3px #ddd;
  font-size: 12pt;
  background-color: rgba(240, 244, 239, 0.75);
  transition: 0.3s;
`

const styles = {
  notSelectedContainer: {
    padding: '0 50px 20px 50px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer'
  },
  sectionName: {
    fontSize: sectionNameSize,
    fontWeight: 800,
    marginBottom: 12,
    width: '100%',
    outline: 'none'
  },
  selectedContainer: {
    minHeight: 60,
    marginBottom: 20,
    padding: '0 50px 0 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    borderRadius: 0,
    cursor: 'pointer',
    borderLeft: `10px solid ${scribeGreen}`
  },
  sectionDrawer: {
    color: 'gray',
    ':hover': {
      color: 'black'
    }
  },
  scriptSelector: {
    paddingLeft: 24
  },
  sectionTitle: {
    display: 'flex'
  }
}

class EditableSection extends Component {
  handleClick = () => {
    const { currentSectionId, setCurrentSection, section } = this.props
    const sectionId = get(section, 'id')
    if (sectionId !== currentSectionId) setCurrentSection(sectionId)
  }

  handleScriptClick = (e, id) => {
    e.preventDefault()
    copyScript()
    loSto.session(LO_STO.SCRIPT_CLIPBOARD, id)
  }

  renderScriptSelector = sectionTag => {
    // TODO: Eventually will need to account for multi-section scripts
    return (
      <div style={styles.scriptSelector} data-cy={`section-scripts-${sectionTag}`}>
        <div><b>Scripts</b></div>
        <ul>
          {this.props.scripts && this.props.scripts.map(s => (
            <li
              key={s.id}
              onClick={(e) => this.handleScriptClick(e, s.id)}
              data-cy={`section-script-${sectionTag}-${s.tags[0].name.value.replace(/\W/g, '-').toLowerCase()}`}
            >
              {s.tags[0].name.value}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  formatCode = code => {
    if (!code) return ''
    if (code.length > 3) {
      return code.substring(0, 3) + '.' + code.substring(3)
    }
    return code
  }

  renderCode = () => {
    const { section } = this.props
    const icdCode = get(section, 'diagnosisEntry.diagnosis.icdCode', '')
    return (
      <Code>
        {this.formatCode(icdCode) || 'No Code'}
      </Code>
    )
  }

  render () {
    const {
      note,
      section,
      refetchNote,
      refetchVersioned,
      currentSectionId,
      isProblemSection,
      s2Mode,
      dictateAtCursorUsed,
      versionedSection
    } = this.props

    const focused = get(section, 'id') === currentSectionId
    const text = get(section, 'content', '')
    const sectionTag = section.name.replace(/\W/g, '-').toLowerCase()

    return (
      <SectionContainer
        isFocused={focused}
        isProblemSection={isProblemSection}
        onClick={this.handleClick}
        id={section.id}
        data-cy={`section-content-${sectionTag}`}
        data-is-focused={focused}
      >
        <div style={styles.sectionTitle}>
          <details styles={styles.sectionDrawer}>
            <summary style={styles.sectionName} data-cy={`section-name-${sectionTag}`}>
              {section.name}
            </summary>
            {this.renderScriptSelector(sectionTag)}
          </details>
          {isProblemSection && this.renderCode()}
        </div>
        <EditableTranscriptSection
          note={note}
          section={section}
          s2Mode={s2Mode}
          id={section.id}
          text={text}
          status={section.status}
          opsStatusFlag={section.opsStatusFlag}
          focused={focused}
          refetchNote={refetchNote}
          dictateAtCursorUsed={dictateAtCursorUsed}
          versionedSection={versionedSection}
          refetchVersioned={refetchVersioned}
        />
      </SectionContainer>
    )
  }
}

EditableSection.propTypes = {
  /**
   * This section
   */
  section: PropTypes.object.isRequired
}

const mapStateToProps = ({ note }) => ({
  currentSectionId: note.currentSectionId
})

const mapDispatchToProps = dispatch => ({
  setCurrentSection: id => dispatch(setCurrentSection(id))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Radium
)(EditableSection)
