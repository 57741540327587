import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { compose, graphql, withApollo } from 'react-apollo'
import { GetAllOrganizations, GetNotesToProcess } from '@sukiai/gql/scribe'
import { setOrgId } from '../actions/admin'
import { NotesToProcessList } from '../components/NotesToProcess'
import Frame from './Frame'
import { loSto } from '../config'
import { LO_STO, FETCH_POLICY, NBP_POLL_TIME, APOLLO_NETWORK_STATUS } from '../lib/constants'
import { convertArrayToObject } from '../lib/util'
import get from 'lodash.get'

class NotesToProcessContainer extends Component {
  state = {
    currentOrgId: loSto.session(LO_STO.SELECTED_ORG_ID)
  }

  componentDidMount () {
    document.body.style.margin = 0

    console.debug('Requesting notification permissions')
    // eslint-disable-next-line
    Notification.requestPermission()
      .then(result => {
        if (result === 'denied') {
          console.debug('Permission denied.')
        } else if (result === 'default') {
          console.debug('Notifications have been enabled.')
        }
      })
  }

  filterListByOrg = (orgId) => {
    this.setState({ currentOrgId: orgId })
    loSto.session(LO_STO.SELECTED_ORG_ID, orgId || 'ALL')
  }

  filteredNotes = notes => {
    const { currentOrgId } = this.state
    if (currentOrgId === 'ALL' || currentOrgId === null) return notes
    return notes.filter(note => note.organizationId === currentOrgId)
  }

  render () {
    const {
      currentOrgId,
      orgsData,
      orgsLoading,
      notesError,
      notesLoading,
      notesData,
      notesCount,
      orgNoteCountData,
      orgsDictionary,
      orgsNoteCount
    } = this.props

    const orgs = orgsData.length < 1
      ? []
      : orgsData

    return (
      <Frame>
        <NotesToProcessList
          orgs={orgs}
          setOrgId={this.filterListByOrg}
          orgNoteCountData={orgNoteCountData}
          loading={orgsLoading && !orgsData}
          currentOrgId={currentOrgId}
          notesError={notesError}
          notesLoading={notesLoading}
          notes={this.filteredNotes(notesData)}
          notesCount={notesCount}
          orgsDictionary={orgsDictionary}
          orgsNoteCount={orgsNoteCount}
        />

      </Frame>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  setOrgId: id => {
    // also set local storage for current context
    loSto.session(LO_STO.SELECTED_ORG_ID, id)
    dispatch(setOrgId(id))
  }
})

const mapStateToProps = ({ admin, note }) => ({
  currentOrgId: admin.orgId
})

const mapOrgsToProps = ({ data }) => {
  const orgsData = get(data, 'organizationsNBP.results', [])
  const orgsDictionary = convertArrayToObject(orgsData, 'id')
  return {
    orgsError: data.error,
    orgsLoading: data.loading,
    orgsData,
    orgsDictionary
  }
}

const mapNoteResultsToProps = ({ data }) => {
  const notesData = get(data, 'needsReview.results', [])

  const orgsNoteCount = notesData.reduce((obj, note) => {
    const { organizationId } = note

    obj[organizationId] = obj[organizationId] ? obj[organizationId] + 1 : 1
    return obj
  }, {})

  return {
    notesError: data.error,
    notesLoading: data.networkStatus === APOLLO_NETWORK_STATUS.LOADING,
    notesCount: get(data, 'needsReview.count', 0),
    notesData,
    orgsNoteCount
  }
}

// TODO: we need to componentize the welcome pages based on the ROLE/PERSONA
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetAllOrganizations, {
    options: () => ({
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapOrgsToProps
  }),
  graphql(GetNotesToProcess, {
    options: () => ({
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
      pollInterval: NBP_POLL_TIME.NOTES_TO_PROCESS_POLL_TIME
    }),
    props: mapNoteResultsToProps
  }),
  withApollo
)(Radium(NotesToProcessContainer))
