import { get } from 'lodash'
import { TYPE } from '../constants'

export const isValidEmail = email => {
  const emailRegEx = /^\S+@\S+\.\S+$/
  return emailRegEx.test(email)
}

export const hasMinimumChar = (str, len = 8) => {
  return str.length >= 8
}

export const hasNumber = (str) => {
  const regex = /(?=.*[0-9])/
  return regex.test(str)
}

export const hasUpperCase = (str) => {
  const regex = /(?=.*[A-Z])/
  return regex.test(str)
}

export const hasLowerCase = (str) => {
  const regex = /(?=.*[a-z])/
  return regex.test(str)
}

export const isKeyCodePrintable = keycode => {
  var valid =
    (keycode > 47 && keycode < 58) || // number keys
    keycode === 32 || keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
    (keycode > 64 && keycode < 91) || // letter keys
    (keycode > 95 && keycode < 112) || // numpad keys
    (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
    (keycode > 218 && keycode < 223)   // [\]' (in order)

  return valid
}

export const doesNotContainUsername = (str, username, len = 4) => {
  // 1. get all substrings of len
  const strLower = str.toLowerCase()
  const usernameLower = username.toLowerCase()

  // test for 4 consecutive
  for (let x = 0; x <= username.length - len; x++) {
    const start = x
    const end = start + len

    const substring = usernameLower.slice(start, end)
    if (strLower.includes(substring)) {
      return false
    }
  }

  return true
}

export const isValidPassword = (password, email) => {
  // * Minimum 8 characters
  // * Minimum 1 number
  // * Minimum 1 uppercase letter
  // * Minimum 1 lowercase letter
  return hasNumber(password) && hasUpperCase(password) && hasLowerCase(password) && hasMinimumChar(password) && doesNotContainUsername(password, email)
}

export const isCharUnsupported = (char) => {
  const charCode = char.charCodeAt(0)
  const unsupportedChars = charCode < 26 || charCode > 126
  return charCode !== 10 && unsupportedChars
}

export const dictateCursorUsed = (sectionEntries, section) => {
  // TODO: After a few weeks, we will remove this check for older notes
  // takes in section entries return section.numberOfCursorEvents > 0
  // check if there's at least one section where cursor dictation used
  // cursor dication usage is defined if cursor not at end
  if (section.numberOfCursorEvents === 0 && sectionEntries && sectionEntries.length > 0) {
    return sectionEntries.some((sectionEntry) => {
      const s2Content = get(sectionEntry, 'section.content')
      const cursorPosition = get(sectionEntry, 'section.cursorPosition')
      let parsedContent = null
      try {
        parsedContent = JSON.parse(s2Content)
        const source = get(parsedContent, 'source')
        const strLength = get(parsedContent, 'total_string_length')
        return cursorPosition !== strLength && source === TYPE.ASR_NVOQ
      } catch (err) {
        return false
      }
    })
  }

  // This is the only check we really need going forward
  return section.numberOfCursorEvents > 0
}
