import { SET_ORG_ID } from '../actions/admin'
import { loSto } from '../config'
import { LO_STO } from '../lib/constants'

const initialState = {
  orgId: loSto.session(LO_STO.SELECTED_ORG_ID)
}

const admin = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_ID:
      return { ...state, orgId: action.orgId }
    default:
      return state
  }
}

export default admin
