import styled from 'styled-components'

// Used for inline that needs no special styling
export const DefaultInline = styled.span`
`

// Used for block that needs no special styling
export const DefaultBlock = styled.div`
`

// A new block, contains inline nodes
export const Section = styled.div`
  margin: 1em 0;
`

// Dictated text
export const AsrTranscript = styled.span`
  border-radius: 2px;
  background-color: rgba(0,0,0,0.05);
`

// An inline for scripts. When inserted, placed in a new section
export const Script = styled.span`
  color: #707070;
`

// Section content that a doctor typed
export const DoctorTyped = styled.span`
`

// Section content that an ops typed
export const OpsTyped = styled.span`
  color: green;
`

// Prepopulated content for that note type
export const Prepopulated = styled.span`
  font-weight: 600;
`

export const LowProfileTextV2 = styled.span`
  color: #a8a8a8;
`

/**
 *  V1 Nodes
**/

export const Blurred = styled.p`
  filter: blur(4px);
`

export const Macro = styled.p`
  color: #707070;
`

export const ScribeToProcess = styled.p`
`

export const Paragraph = styled.p`
  display: block;
  margin-top: 26;
`

export const LowProfileText = styled.p`
  color: #a8a8a8;
`

export const NeedsQa = styled.span`
`
