import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import Auth from '../okta'
import { loSto } from '../config'
import { LO_STO, USER_PERSONA } from '../lib/constants'
import { generateSuki64bitID, logout } from '../lib/util'
import introspectionQueryResultData from './fragmentTypes.json'

const BASE_URL = window.location.origin.includes('ops') ? window.location.origin.replace('ops', 'api') : window.location.origin

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })

// instantiate a new client with our custom endpoint
const httpLink = new HttpLink({
  uri: BASE_URL + '/graphql',
  credentials: 'same-origin'
})

const withToken = setContext(async request => {
  const idToken = await Auth.getIdToken()
  const sessionId = loSto.get(LO_STO.SESSION_TOKEN)
  const userId = loSto.session(LO_STO.USER_ID)
  const traceId = generateSuki64bitID()
  return {
    headers: {
      authorization: idToken,
      'Suki-Session-ID': sessionId,
      'Suki-User-ID': userId,
      'Suki-Trace-ID': traceId,
      'Suki-Role': USER_PERSONA.SCRIBE
    }
  }
})

const resetToken = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    // Should we immediately logout on 401s?
    // TODO: We should wait for user input in certain cases
    logout()
  }
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([ // order of links is important
    withToken,
    resetToken,
    httpLink
  ])
})

export default client
