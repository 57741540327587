export const fontFamily = 'Open Sans, sans-serif'
export const menuFontSize = 18

/* weights */
export const weightLight = 300
export const weightNormal = 400
export const weightSemibold = 500
export const weightBold = 700

/* sizes */
export const textTiny = '12px'
export const textSmall = '14px'
export const textMedium = '18px'
export const textLarge = 600
