import React, { Component } from 'react'
import AudioControl from './AudioControl'
import PlayArrowIcon from 'material-ui/svg-icons/av/play-arrow'
import DropDownIcon from 'material-ui/svg-icons/image/details'
import { fulvous, scribeGreen } from '../../styles/colors'
import { MIN_AUDIO_DURATION } from '../../lib/constants'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FontIcon from 'material-ui/FontIcon'

const DictateAtCursorFlag = styled.div`
  position: absolute;
  left: -40px;
    top: 43px;
`

const styles = {
  playbackList: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%'
  },
  playbackItems: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    marginBottom: '10px'
  },
  playbackItem: {
    border: `2px solid ${scribeGreen}`,
    width: '40px',
    height: '40px',
    marginRight: '4px'
  },
  audioPlayer: {
    border: `2px solid ${scribeGreen}`,
    width: '100%'
  },
  icon: {
    padding: '8px',
    color: scribeGreen
  }
}

class PlaybackList extends Component {
  state = {
    shownIndex: -1
  }

  onItemSelected (idx) {
    if (idx > -1) {
      this.setState(oldState => ({
        shownIndex: oldState.shownIndex !== idx ? idx : -1
      }))
    }
  }

  render () {
    const { audioTranscripts, dictateAtCursorUsed, sectionTag } = this.props
    const { shownIndex } = this.state

    return (
      <div style={styles.playbackList} data-cy={`playback-list-${sectionTag}`}>
        {dictateAtCursorUsed && (
          <DictateAtCursorFlag>
            <FontIcon
              className='material-icons'
              color={fulvous}
              style={{ fontSize: 30 }}
            >
              priority_high
            </FontIcon>
          </DictateAtCursorFlag>)}
        <div style={styles.playbackItems}>
          {audioTranscripts.map((a, idx) => a.duration > MIN_AUDIO_DURATION ? (
            <div
              className='audio-dropdown'
              key={`transcript-${idx}`}
              style={styles.playbackItem}
              onClick={this.onItemSelected.bind(this, idx)}
            >
              {shownIndex === idx
                ? <DropDownIcon style={styles.icon} />
                : <PlayArrowIcon style={styles.icon} />}
            </div>
          ) : undefined)}
        </div>
        {audioTranscripts.map((a, idx) => {
          return idx === shownIndex ? (
            <AudioControl
              key={`audio-control-${this.props.sectionId}-${idx}`}
              wavesurferId={`wavesurfer-${this.props.sectionId}-${idx}`}
              src={a.audioUri}
              isCurrentSection={this.props.isCurrentSection}
            />
          ) : undefined
        })}
      </div>
    )
  }
}

PlaybackList.defaultProps = {
  audioTranscripts: []
}

PlaybackList.propTypes = {
  audioTranscripts: PropTypes.array
}

export default PlaybackList
