import React, { Component } from 'react'
import { sectionNameSize } from '../../styles/dimensions'
import { GetScriptChangeAudio } from '@sukiai/gql/scribe'
import { PlaybackList } from '../Audio'
import ScriptChangeRawTranscript from './ScriptChangeRawTranscript'
import { compose, graphql } from 'react-apollo'
import { getOrgIdFromPath } from '../../lib/util'
import { FETCH_POLICY } from '../../lib/constants'

const styles = {
  container: {
    minHeight: 60,
    marginBottom: 20,
    padding: '20px 50px 0 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    borderTop: '1px solid #a8a8a8',
    borderLeft: '10px'
  },
  sectionName: {
    fontSize: sectionNameSize,
    color: '#a8a8a8',
    fontWeight: 800,
    marginBottom: 12,
    width: '100%'
  },
  sectionAudioControls: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '76%'
  }
}

class ChangeScriptSection extends Component {
  state = {
    hasAudio: this.props.audioTranscripts && this.props.audioTranscripts.length > 0
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.audioTranscripts !== this.props.audioTranscripts) {
      if (nextProps.audioTranscripts.length > 0) {
        this.setState({ hasAudio: true })
      }
    }
  }

  render () {
    const { audioTranscripts, loadingData } = this.props
    const { hasAudio } = this.state
    return (
      <div style={styles.container} id='change-script-section' data-cy='change-script-section'>
        <div style={styles.sectionName}>
          Change Command Audio
        </div>
        {hasAudio &&
          <div style={styles.sectionAudioControls}>
            <PlaybackList
              audioTranscripts={audioTranscripts}
              sectionTag='change-section'
            />
          </div>}
        {!loadingData &&
          <ScriptChangeRawTranscript audioTranscripts={audioTranscripts} />}
      </div>

    )
  }
}

const mapAudioToProps = ({ data }) => ({
  errorFetching: data.error,
  loadingData: data.loading,
  audioTranscripts: data.transcripts && data.transcripts.results,
  reloadTranscripts: data.refetch
})

export default compose(
  graphql(GetScriptChangeAudio, {
    skip: ({ noteId }) => !noteId || !getOrgIdFromPath(),
    options: ({ noteId }) => ({
      variables: {
        organizationId: getOrgIdFromPath(),
        compositionId: noteId
      },
      fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK
    }),
    props: mapAudioToProps
  })
)(ChangeScriptSection)
