import React, { Component } from 'react'
import Radium from 'radium'
import IdleTimer from 'react-idle-timer'
import { Clock, UserMenu } from '../components/Nav'
import InfoButton from '../components/InfoButton'
import { Link, withRouter } from 'react-router-dom'
import { logout } from '../lib/util'
import logo from '../images/suki-logo-navbar.svg'
import { loSto } from '../config'
import { charcoal, whiteSmoke, scribeGreen } from '../styles/colors'
import { menuFontSize } from '../styles/typography'
import { navBarHeight } from '../styles/dimensions'
import { LO_STO, ROUTES, INACTIVITY_TIMEOUT } from '../lib/constants'
import { setInfoModal } from '../actions/modals'
import { compose } from 'react-apollo'
import { connect } from 'react-redux'
import styled from 'styled-components'

const styles = {
  paper: {
    zIndex: 1000,
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: whiteSmoke,
    height: navBarHeight,
    display: 'flex',
    justifyContent: 'space-between'
  },
  clock: {
    color: charcoal,
    fontSize: menuFontSize,
    margin: '0 10px',
    fontWeight: 500,
    width: 80,
    textAlign: 'center'
  },
  language: {
    color: charcoal
  },
  link: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
      color: scribeGreen,
      cursor: 'pointer'
    }
  },
  logo: {
    height: '20px',
    boxSizing: 'border-box',
    marginTop: '4px'
  },
  name: {
    color: charcoal,
    fontWeight: 600,
    fontSize: menuFontSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  navContainer: {
    height: '100%',
    flex: 1
  },
  rightSide: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  unfinishedMargin: {
    marginRight: 26
  },
  userMenu: {
    margin: '0 20px 0 10px'
  }
}

const NavContainer = styled.div`
  width: 100%;
  height: 40px;
  background: #eaeef5;
  padding: 3px 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const LeftSide = styled.div`
  height: 100%;
  display:flex;
  
  display:flex;
  align-items:center;
`

const RightSide = styled.div`
  height:100%;

  display:flex;
  align-items:center;
`

const PageTitle = styled.p`
  margin: 0 10px;
`

class TopNavBar extends Component {
  constructor (props) {
    super(props)
    this.idleTimer = null
  }

  componentDidMount () {
    // Not sure what is setting the margin to 8, but eliminate it
    document.body.style.margin = 0
  }

  onInfoButtonClick = () => {
    const { isInfoModalOpen, setInfoModal } = this.props
    setInfoModal(!isInfoModalOpen)
  }

  handleOnAction = () => {
    this.idleTimer.reset()
  }

  handleOnIdle = () => {
    window.alert('You haven’t used Suki for an hour, so to help with security we’ll sign you out.')
    logout()
  }

  render () {
    // TODO Fix name in nav bar not persisting on refresh
    const { location } = this.props

    let pageTitle = null
    let switchPath = location.pathname

    if (location.pathname.includes(ROUTES.NOTE) && !location.pathname.includes(ROUTES.NOTES_TO_PROCESS)) {
      switchPath = ROUTES.NOTE
    }

    switch (switchPath) {
      case ROUTES.NOTES_TO_PROCESS:
        pageTitle = 'Notes to Process'
        break
      default:
        break
    }

    const link = ROUTES.ROOT

    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={INACTIVITY_TIMEOUT}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <NavContainer>
          <LeftSide>
            <Link data-cy='top-nav-logo' to={link}>
              <img src={logo} style={styles.logo} alt='Suki Logo' />
            </Link>
            <PageTitle>{pageTitle}</PageTitle>
          </LeftSide>
          <RightSide>
            {switchPath === ROUTES.NOTE && <InfoButton handleClick={this.onInfoButtonClick} />}

            <Clock style={styles.clock} />
            <UserMenu
              style={styles.userMenu}
              user={loSto.session(LO_STO.CURRENT_USER_NAME)}
            />
          </RightSide>
        </NavContainer>
      </>
    )
  }
}

const mapStateToProps = ({ modals, user }) => ({
  isInfoModalOpen: modals.isInfoModalOpen,
  user: user
})

const mapDispatchToProps = dispatch => ({
  setInfoModal: isOpen => dispatch(setInfoModal(isOpen))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Radium(TopNavBar))
