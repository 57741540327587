import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'react-apollo'
import { setCurrentSection } from '../../actions/note'
import EditableVerbatimTranscriptSection from './EditableVerbatimTranscriptSection'
import { V2_DEFAULT_CONTENT } from '@sukiai/editor'
import Editor from '@sukiai/quill-editor'
import { copyScript, isHTMLString, sanitizeHTML } from '../../lib/util'
import { loSto } from '../../config'
import { LO_STO, USER_PERSONA, EDITOR_TYPE } from '../../lib/constants'
import { sectionNameSize, noteSectionPadding, sectionContentSize } from '../../styles/dimensions'
import { connect } from 'react-redux'
import Radium from 'radium'
import get from 'lodash.get'

const styles = {
  notSelectedContainer: {
    padding: '0 50px 20px 50px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    border: 'none',
    borderRadius: 0,
    cursor: 'pointer'
  },
  sectionName: {
    fontSize: sectionNameSize,
    fontWeight: 800,
    marginBottom: 12,
    width: '100%',
    outline: 'none'
  },
  selectedContainer: {
    minHeight: 60,
    marginBottom: 20,
    padding: '0 50px 0 40px',
    boxSizing: 'border-box',
    wordWrap: 'break-word',
    borderRadius: 0,
    cursor: 'pointer'
  },
  sectionDrawer: {
    color: 'gray',
    ':hover': {
      color: 'black'
    }
  },
  scriptSelector: {
    paddingLeft: 24
  },
  sectionText: {
    display: 'flex',
    flexFlow: 'column',
    fontSize: sectionContentSize,
    wordWrap: 'break-word',
    paddingLeft: noteSectionPadding,
    boxSizing: 'border-box'
  }
}

class EditableVerbatimSection extends Component {
  handleClick = () => {
    const { currentSectionId, setCurrentSection, section } = this.props
    const sectionId = get(section, 'id')
    if (sectionId !== currentSectionId) setCurrentSection(sectionId)
  }

  handleScriptClick = (e, id) => {
    e.preventDefault()
    copyScript()
    loSto.session(LO_STO.SCRIPT_CLIPBOARD, id)
  }

  renderScriptSelector = sectionTag => {
    // TODO: Eventually will need to account for multi-section scripts
    return (
      <div style={styles.scriptSelector} data-cy={`section-scripts-${sectionTag}`}>
        <div><b>Scripts</b></div>
        <ul>
          {this.props.scripts && this.props.scripts.map(s => (
            <li
              key={s.id}
              onClick={(e) => this.handleScriptClick(e, s.id)}
              data-cy={`section-script-${sectionTag}-${s.tags[0].name.value.replace(/\W/g, '-').toLowerCase()}`}
            >
              {s.tags[0].name.value}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  render () {
    const {
      note,
      section,
      enablePunct,
      refetchNote,
      currentSectionId,
      handleButtonPress,
      unprocess
    } = this.props
    const focused = get(section, 'id') === currentSectionId
    const content = get(section, 'content', '')
    const sectionTag = section.name.replace(/\W/g, '-').toLowerCase()

    // [Parial dictation]: To render html section in non editable mode
    const text = content && JSON.parse(content).total_string
    const isHTML = isHTMLString(text)

    return (
      <div
        style={styles.selectedContainer}
        onClick={this.handleClick}
        id={section.id}
        data-cy={`section-content-${sectionTag}`}
        data-is-focused={focused}
      >
        <details styles={styles.sectionDrawer}>
          <summary style={styles.sectionName} data-cy={`section-name-${sectionTag}`}>
            {section.name}
          </summary>
          {this.renderScriptSelector(sectionTag)}
        </details>
        <div style={styles.sectionText}>
          {isHTML ? (
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(text) }} style={{ 'overflow-x': 'hidden' }} />
          ) : (
            <Editor
              value={content || V2_DEFAULT_CONTENT}
              persona={USER_PERSONA.ADMIN}
              editorType={EDITOR_TYPE.VERBATIM}
              readOnly
            />
          )}
        </div>
        <EditableVerbatimTranscriptSection
          note={note}
          section={section}
          id={section.id}
          text={content}
          enablePunct={enablePunct}
          status={section.status}
          focused={focused}
          refetchNote={refetchNote}
          handleButtonPress={handleButtonPress}
          unprocess={unprocess}
        />
      </div>
    )
  }
}

EditableVerbatimSection.propTypes = {
  /**
   * This section
   */
  section: PropTypes.object.isRequired
}

const mapStateToProps = ({ note }) => ({
  currentSectionId: note.currentSectionId
})

const mapDispatchToProps = dispatch => ({
  setCurrentSection: id => dispatch(setCurrentSection(id))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Radium
)(EditableVerbatimSection)
