import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import { getFullName } from '@sukiai/utils'
import { loSto } from '../../config'
import {
  LO_STO,
  INTENT_TYPE
} from '../../lib/constants'
import get from 'lodash.get'
import StyledButton from '../StyledButton'

class LockNoteModal extends Component {
  state = {
    open: false
  }

  componentWillReceiveProps (nextProps) {
    nextProps.actionName && this.setState({
      open: nextProps.actionName === INTENT_TYPE.LOCK_NOTE
    })
  }

  handleRequestCancel = () => {
    this.setState({ open: false })
    this.props.clearActionRequested()
  }

  renderBodyText = () => {
    const { scribe } = this.props
    const scribeId = get(scribe, 'id')
    const userId = loSto.session(LO_STO.USER_ID)
    if (scribeId === userId) {
      // You are the scribe but locked (from another window)
      return 'You currently have this note opened in another tab/window. Please go back to original to edit.'
    } else if (!scribeId) {
      return 'The note is currently unavailable for editing, but will be soon.'
    } else {
      return `${getFullName(scribe)} is currently editing the note.`
    }
  }

  render () {
    const title = 'Note is currently being processed. Read Only Mode'
    const bodyText = this.renderBodyText()

    const actions = [
      <StyledButton
        label='ok'
        secondary
        onClick={this.handleRequestCancel}
        key='ok'
        data-cy='modal-btn-cancel'
      >ok
      </StyledButton>
    ]

    return (
      <Dialog
        actions={actions}
        modal={false}
        open={this.state.open}
        title={title}
        titleStyle={{ fontWeight: 600 }}
        style={{ minWidth: '500px' }}
      >
        <div>{bodyText}</div>

      </Dialog>
    )
  }
}

export default LockNoteModal
