import { TYPE, EDITOR_TYPE, PERSONA } from '../constants'
import { get, isEqual } from 'lodash'
import { isEmptySection, EMPTY_DELTA } from '../SlateConversions'

// Given S2 from backend, converts to Delta format so that web's Quill editor can
// use it
export const convertS2ToDelta = s2Str => {
  if (!s2Str) {
    console.error('No S2 content passed into converter')
  }

  let s2 = null
  try {
    if (typeof s2Str === 'string') {
      s2 = JSON.parse(s2Str)
    } else if (typeof s2Str === 'object') {
      s2 = s2Str
    }
  } catch (err) {
    console.error('Unable to parse content to JSON', err)
    return
  }

  const s2Content = s2 && s2.content
  if (!s2Content) {
    console.error('No content field, unable to convert to Delta. returning empty delta.')
    return EMPTY_DELTA
  }

  const ops = []
  s2Content.forEach(c => {
    const newOps = []

    const textArr = c.string.split(/(\n+)/)
    textArr.forEach(s => {
      if (s === '') {

      } else if (isOnlyNewline(s)) {
        newOps.push({
          insert: s,
          attributes: { blockType: getV2SourceFromS2Type(c.source) }
        })
      } else {
        const attributes = {
          type: getV2SourceFromS2Type(c.source)
        }

        if (c.is_bold) {
          attributes.bold = true
        }

        if (c.is_italic) {
          attributes.italic = true
        }

        newOps.push({
          insert: s,
          attributes: attributes
        })
      }
    })
    ops.push.apply(ops, newOps)
  })

  ops.push({ insert: '\n' })

  return { ops }
}

const newContent = (text, totalLen, attributes, id, persona) => ({
  id: id,
  start_offset: totalLen,
  end_offset: totalLen + text.length - 1,
  string: text,
  length_of_string: text.length,
  is_bold: get(attributes, 'bold', false) ? 1 : 0,
  is_italic: get(attributes, 'italic', false) ? 1 : 0,
  source: getSource(attributes, persona)
})

const newPasteContent = (text, totalLen, attributes, id) => ({
  id,
  start_offset: totalLen,
  end_offset: totalLen + text.length - 1,
  string: text,
  length_of_string: text.length,
  is_bold: get(attributes, 'bold', false) ? 1 : 0,
  is_italic: get(attributes, 'italic', false) ? 1 : 0,
  source: TYPE.TYPED_DOCTOR
})

// Given the web's Delta format, converts content to S2 to store in the
// backend
export const convertDeltaToS2 = (delta, persona) => {
  if (!delta) {
    console.error('No delta content passed into converter')
  }

  const ops = delta.ops
  if (!ops) {
    console.error('No ops field, unable to convert to S2')
  }

  const content = []
  let totalLen = 0
  let totalString = ''
  ops.forEach((op, i) => {
    const text = op.insert
    const attributes = op.attributes

    if (i === ops.length - 1) {
      if (text.length > 1) {
        // This is the case where there is other text in the last
        // entry. Often when pasting content.
        const lastContent = text.substr(0, text.length - 1)
        content.push(
          // The source of pasted content is TYPED_DOCTOR
          newPasteContent(lastContent, totalLen, attributes, i)
        )

        totalLen += lastContent.length
        totalString += lastContent
      }
      return
    }
    content.push(
      newContent(text, totalLen, attributes, i, persona)
    )
    totalLen += text.length
    totalString += text
  })

  // need to condense the contents so merge adjacent content with same attributes

  const val = {
    number_of_strings: content.length,
    total_string_length: totalString.length,
    total_string: totalString,
    content: content
  }

  return val
}

// May be needed later
// Given the source enum in S2, returns the quill type attribute
// const getTypeFromSourceEnum = source => {
//   switch (source) {
//     case 0:
//       return TYPE.ASR_NVOQ
//     case 1:
//       return TYPE.ASR_GOOGLE
//     case 2:
//       return TYPE.ASR_HOMEGROWN
//     case 3:
//       return TYPE.TYPED_DOCTOR
//     case 4:
//       return TYPE.TYPED_ADMIN
//     case 5:
//       return TYPE.TYPED_SCRIBE
//     case 6:
//       return TYPE.SCRIPT
//     case 7:
//       return TYPE.COPY_FORWARD
//     case 8:
//       return TYPE.NOTETYPE_PREFILLED
//     case 9:
//     default:
//       return TYPE.UNKNOWN
//   }
// }

// Given Slate content convert to S2 format
// This is just used for converting in the backend V2 Slate to S2 Format
// No need to consider web UI client editor use cases
// Purely needed for Mobile clients to support S2
// We can generate the final proto from this S2 Json that the clients can use

// Params: (slate string or JSON, editorType eg. SCRIPT or NOTETYPE or NOTE)
export const convertSlateToS2 = (slate, editorType) => {
  if (!slate) {
    console.error('No slate content passed into converter')
  }

  let s2 = {
    number_of_strings: 0,
    total_string_length: 0,
    total_string: '',
    content: []
  }

  let slateJSON = null
  try {
    if (typeof slate === 'string') {
      slateJSON = JSON.parse(slate)
    } else if (typeof slate === 'object') {
      slateJSON = slate
    }
  } catch (err) {
    console.error('Unable to parse Slate string in JSON: ', slate)
  }

  if (isEmptySection(slateJSON)) {
    // empty s2
    return s2
  }

  // Convert slate to s2
  const document = get(slateJSON, 'document')
  const blockNodes = get(document, 'nodes', [])
  let totalString = ''
  let currIdx = -1
  let totalLen = 0
  let currentChanges = {
    isBold: 0,
    isItalic: 0,
    source: null
  }
  // initialize prevChanges with UNKNOWN
  let prevChanges = {
    isBold: 0,
    isItalic: 0,
    source: TYPE.UNKNOWN
  }
  blockNodes.forEach((bn, i) => {
    const subnodes = get(bn, 'nodes', [])
    subnodes.forEach(sn => {
      const path = sn.object === 'inline' ? 'nodes[0].leaves' : 'leaves'
      const leaves = get(sn, path, [])
      leaves.forEach((leaf, idx) => {
        if (leaf.text === '') return
        // Necessary for converting corrupted notetypes and scripts
        let type
        if (editorType === EDITOR_TYPE.SCRIPT) {
          type = TYPE.SCRIPT_CONTENT
        } else if (editorType === EDITOR_TYPE.NOTETYPE) {
          type = TYPE.PREPOPULATED
        } else {
          type = sn.type || sn.blockType
        }

        // set changes
        currentChanges.isBold = getS2Bold(leaf.marks)
        currentChanges.isItalic = getS2Italic(leaf.marks)
        currentChanges.source = getSourceFromType(type, editorType)

        if (isEqual(currentChanges, prevChanges)) {
          // if source, is_bold & is_italic are same as previous block
          // currIdx is always > 0, since source is null to begin with
          // prevChanges has source with UNKNOWN type
          // This will be true only when these three attributes are really
          // equal to each other. We just need to append to the currIdx
          const currentContentBlock = s2.content[currIdx]
          currentContentBlock.end_offset = totalLen + leaf.text.length - 1
          currentContentBlock.string += leaf.text
          currentContentBlock.length_of_string += leaf.text.length
        } else {
          currIdx++
          // if source or is_bold or is_italic values change
          s2.content.push({
            id: currIdx,
            start_offset: totalLen,
            end_offset: totalLen + leaf.text.length - 1,
            string: leaf.text,
            length_of_string: leaf.text.length,
            is_bold: currentChanges.isBold,
            is_italic: currentChanges.isItalic,
            // section node type, or block type or fallback type
            source: currentChanges.source
          })
          Object.assign(prevChanges, currentChanges)
        }

        totalString += leaf.text
        totalLen += leaf.text.length
      })
    })
  })

  s2.number_of_strings = s2.content.length
  s2.total_string = totalString
  s2.total_string_length = totalString.length

  return s2
}

// Params: (slate string or JSON, editorType eg. SCRIPT or NOTETYPE or NOTE)
export const simpleConvertSlateToS2 = (slate, editorType) => {
  if (!slate) {
    console.error('No slate content passed into simple slate to s2 converter')
  }

  let s2 = {
    number_of_strings: 0,
    total_string_length: 0,
    total_string: '',
    content: []
  }

  let slateJSON = null
  try {
    if (typeof slate === 'string') {
      slateJSON = JSON.parse(slate)
    } else if (typeof slate === 'object') {
      slateJSON = slate
    }
  } catch (err) {
    console.error('Unable to parse Slate string in JSON: ', slate)
  }

  if (isEmptySection(slateJSON)) {
    // empty s2
    return s2
  }

  // Convert slate to s2
  const document = get(slateJSON, 'document')
  const blockNodes = get(document, 'nodes', [])
  let totalString = ''
  let currIdx = 0
  let totalLen = 0
  let type

  blockNodes.forEach((bn, blockIdx) => {
    const subnodes = get(bn, 'nodes', [])
    subnodes.forEach(sn => {
      const path = sn.object === 'inline' ? 'nodes[0].leaves' : 'leaves'
      const leaves = get(sn, path, [])
      leaves.forEach(leaf => {
        if (leaf.text === '') return
        // Necessary for converting corrupted notetypes and scripts
        if (editorType === EDITOR_TYPE.SCRIPT) {
          type = TYPE.SCRIPT_CONTENT
        } else if (editorType === EDITOR_TYPE.NOTETYPE) {
          type = TYPE.PREPOPULATED
        } else {
          type = sn.type || sn.blockType
        }

        // set flags
        const flags = {
          isBold: getS2Bold(leaf.marks),
          isItalic: getS2Italic(leaf.marks),
          source: getSourceFromType(type, editorType)
        }

        s2.content.push({
          id: currIdx,
          start_offset: totalLen,
          end_offset: totalLen + leaf.text.length - 1,
          string: leaf.text,
          length_of_string: leaf.text.length,
          is_bold: flags.isBold,
          is_italic: flags.isItalic,
          // section node type, or block type or fallback type
          source: flags.source
        })

        totalString += leaf.text
        totalLen += leaf.text.length
        currIdx++
      })
    })
    // There are more than 1 blocks, so we will also add a `\n` between blocks
    // Also if no content is present and there are more than one blocks add a `\n`
    if (blockIdx > 0 || (s2.content.length === 0 && blockNodes.length > 1)) {
      if (editorType === EDITOR_TYPE.SCRIPT) {
        type = TYPE.SCRIPT_CONTENT
      } else if (editorType === EDITOR_TYPE.NOTETYPE) {
        type = TYPE.PREPOPULATED
      }

      s2.content.push({
        id: currIdx,
        start_offset: totalLen,
        end_offset: totalLen,
        string: '\n',
        length_of_string: 1,
        is_bold: 0,
        is_italic: 0,
        // section node type, or block type or fallback type (same as prev subnode type)
        source: getSourceFromType(type, editorType)
      })
      totalString += '\n'
      currIdx++
      totalLen++
    }
  })

  s2.number_of_strings = s2.content.length
  s2.total_string = totalString
  s2.total_string_length = totalString.length

  return s2
}

export const simpleConvertS2ToSlate = s2 => {
  if (!s2) {
    console.error('No S2 content passed into simple s2 to slate converter')
  }

  const slate = {
    'object': 'value',
    'document': {
      'object': 'document',
      'data': { 'editorVersion': '2.0.0' },
      'nodes': [
        {
          'object': 'block',
          'type': 'section',
          'data': {},
          'nodes': [
            {
              'object': 'text',
              'leaves': [
                {
                  'object': 'leaf',
                  'text': '',
                  'marks': []
                }
              ]
            }
          ]
        }
      ]
    }
  }

  let s2Json = null
  try {
    if (typeof s2 === 'string') {
      s2Json = JSON.parse(s2)
    } else if (typeof s2 === 'object') {
      s2Json = s2
    }
  } catch (err) {
    console.error('Unable to parse S2 string to JSON: ', s2)
  }

  if (get(s2Json, 'content.length') === 0) {
    return slate
  }

  const content = get(s2Json, 'content', [])
  const nodes = [
    {
      'object': 'text',
      'leaves': [
        {
          'object': 'leaf',
          'text': '',
          'marks': []
        }
      ]
    }
  ]
  content.forEach((c, i) => {
    nodes.push({
      object: 'inline',
      type: getV2SourceFromS2Type(c.source),
      data: {},
      nodes: [
        {
          object: 'text',
          leaves: [
            {
              object: 'leaf',
              text: c.string,
              marks: getMarksFromContent(c)
            }
          ]
        }
      ]
    })

    nodes.push({
      object: 'text',
      leaves: [
        {
          object: 'leaf',
          text: '',
          marks: []
        }
      ]
    })
  })

  const block = slate.document.nodes[0]
  block.nodes = nodes

  return slate
}

const getMarksFromContent = c => {
  const marks = []

  if (c.is_bold) {
    marks.push({
      object: 'mark',
      type: 'bold',
      data: {}
    })
  }

  if (c.is_italic) {
    marks.push({
      object: 'mark',
      type: 'italic',
      data: {}
    })
  }

  return marks
}

const getS2Bold = marks => {
  if (!marks) return 0
  const isBold = marks.some(m => m.type.toLowerCase() === 'bold')
  return isBold ? 1 : 0
}

const getS2Italic = marks => {
  if (!marks) return 0
  const isItalic = marks.some(m => m.type.toLowerCase() === 'italic')
  return isItalic ? 1 : 0
}

const getSource = (attributes, persona) => {
  if (!attributes) {
    if (persona === PERSONA.PHYSICIAN) {
      return TYPE.TYPED_DOCTOR
    } else {
      return TYPE.UNKNOWN
    }
  }

  return getSourceFromType(attributes.type || attributes.blockType, null, persona)
}

// Given the quill type attribute, returns the source for S2
const getSourceFromType = (type, editorType, persona) => {
  // TODO: ADD TYPE.INTERMEDIATE_TEXT
  // TODO: FIGURE OUT HOW TO ADD SCRIPT_CHANGE
  switch (type) {
    case TYPE.ASR_TRANSCRIPT:
      return TYPE.ASR_NVOQ
    case TYPE.DOCTOR_TYPED:
      return TYPE.TYPED_DOCTOR
    case TYPE.OPS_TYPED:
      return TYPE.TYPED_SCRIBE
    case TYPE.SCRIPT_CONTENT:
      return TYPE.SCRIPT
    // saw a case where there was 'none' as type in old patient note
    case 'none':
    case TYPE.PREPOPULATED:
      return TYPE.NOTETYPE_PREFILLED
    default:
      // there are cases with some V2 notes with S2 source types
      if (type === TYPE.DEFAULT) {
        if (editorType === EDITOR_TYPE.SCRIPT) return TYPE.SCRIPT
        if (editorType === EDITOR_TYPE.NOTETYPE) return TYPE.NOTETYPE_PREFILLED
        if (persona === PERSONA.PHYSICIAN) {
          return TYPE.TYPED_DOCTOR
        }
        return TYPE[type]
      }

      if (TYPE.hasOwnProperty(type)) {
        return TYPE[type]
      }

      if (persona === PERSONA.PHYSICIAN) {
        return TYPE.TYPED_DOCTOR
      } else {
        return TYPE.UNKNOWN
      }
  }
}

const getV2SourceFromS2Type = type => {
  if (!type) return TYPE.DEFAULT

  // TODO: ADD TYPE.INTERMEDIATE_TEXT
  // TODO: FIGURE OUT HOW TO ADD SCRIPT_CHANGE
  switch (type) {
    case TYPE.ASR_NVOQ:
      return TYPE.ASR_TRANSCRIPT
    case TYPE.TYPED_DOCTOR:
      return TYPE.DOCTOR_TYPED
    case TYPE.TYPED_SCRIBE:
      return TYPE.OPS_TYPED
    case TYPE.SCRIPT:
      return TYPE.SCRIPT_CONTENT
    case TYPE.NOTETYPE_PREFILLED:
      return TYPE.PREPOPULATED
    case TYPE.UNKNOWN:
      return TYPE.DEFAULT
    default:
      if (TYPE.hasOwnProperty(type)) {
        return TYPE[type]
      }
      return TYPE.DEFAULT
  }
}

const isOnlyNewline = str => {
  return /\n+/.test(str)
}
