import ReactGA from 'react-ga'
import { EVENT_NOTES } from '../analytics/categories'

export const recordFatalException = error => {
  ReactGA.exception({
    description: error,
    fatal: true
  })
}

export const recordNoteUpdated = status => {
  ReactGA.event({
    category: EVENT_NOTES,
    action: 'Updated',
    label: status
  })
}

export const setUserId = userId => {
  ReactGA.set({ userId: userId })
}
