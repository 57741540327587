import React, { Component } from 'react'
import background from '../images/backgroundScribe.jpg'
import { SidePanel, ImDoneModal } from '../components/Note'
import { VerbatimNote } from '../components/VerbatimNote'
import Loading from '../components/Loading'
import {
  GetNoteForVerbatimTranscription,
  GetScriptsByUserId
} from '@sukiai/gql/scribe'
import circleCheck from '../images/circle-check.png'
import { withRouter } from 'react-router-dom'
import {
  FETCH_POLICY,
  INTENT_TYPE
} from '../lib/constants'
import { whiteSmoke, buttonShadow } from '../styles/colors'
import { sideBarWidth, navBarHeight } from '../styles/dimensions'
import { compose, graphql, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import Radium, { keyframes } from 'radium'
import { setCurrentSection } from '../actions/note'
import { clearActionRequested, setActionRequested } from '../actions'
import Frame from './Frame'
import { setError } from '../lib/util'
import get from 'lodash.get'
import set from 'lodash.set'

const buttonAnim = keyframes({
  '0%': {
    opacity: 0
  },
  '100%': {
    opacity: 1
  }
}, 'buttonAnim')

const styles = {
  sidepanel: {
    minWidth: sideBarWidth,
    width: '20vw',
    marginTop: navBarHeight,
    zIndex: 0,
    backgroundColor: whiteSmoke,
    display: 'flex',
    flexDirection: 'column'
  },
  actionContainer: {
    width: '8vw',
    height: `calc(100vh - ${navBarHeight})`,
    position: 'absolute',
    top: navBarHeight,
    right: 0,
    padding: '20px 0',
    minWidth: 50,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    boxSizing: 'border-box',
    '@media print': {
      display: 'none'
    }
  },
  button: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0 6px',
    boxShadow: `0px 0px 10px ${buttonShadow}`,
    outline: 'none',
    border: 0,
    animation: 'x 0.5s linear forwards',
    animationName: buttonAnim
  },
  imDoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: '0.2s'
  },
  disabledImDone: {
    opacity: 0.25,
    ':hover': {
      opacity: 0.25
    },
    cursor: 'default'
  },
  enabledImDone: {
    opacity: 1.0,
    ':hover': {
      opacity: 0.75
    },
    cursor: 'pointer'
  }
}

class VerbatimNoteContainer extends Component {
  state = {
    joined: false,
    transcribedSections: {}
  }

  handleVerbatimDone = () => {
    const { setActionRequested } = this.props
    setActionRequested(INTENT_TYPE.SUBMIT_VERBATIM)
  }

  onButtonPress = (sectionId, action, isSet, transcript, transcriptText) => {
    const { transcribedSections } = this.state
    set(transcribedSections, `${sectionId}["${transcript.audioUri}"]${action}`, isSet)
    set(transcribedSections, `${sectionId}["${transcript.audioUri}"].duration`, transcript.duration)
    set(transcribedSections, `${sectionId}["${transcript.audioUri}"].transcript`, transcriptText)
    this.setState(transcribedSections)
  }

  unprocess = (sectionId, transcript) => {
    const { transcribedSections } = this.state
    set(transcribedSections, `${sectionId}["${transcript.audioUri}"].processed`, false)
    this.setState(transcribedSections)
  }

  updateState = () => {
    this.setState({ open: !this.state.open })
  }

  render () {
    const {
      outOfNotes,
      loadingNote,
      note,
      enableImDone,
      currentSectionId,
      refetch,
      scripts,
      actionRequested,
      history,
      clearActionRequested
    } = this.props

    const { transcribedSections } = this.state

    if (outOfNotes) {
      setError({ message: 'No notes available to transcribe.' })
      return <Frame withNote background={background} />
    }

    if (!note) return <Loading />

    return (
      <div>
        {note &&
          <Frame withNote background={background}>
            <SidePanel
              style={styles.sidepanel}
              note={note}
              doctor={get(note, 'metadata.user')}
              currentSectionId={currentSectionId}
              panelId='scribeSidePanel'
              hasIcons={false}
            />
            <VerbatimNote
              note={note}
              patient={get(note, 'metadata.patient')}
              sections={note.sections}
              scripts={scripts}
              refetchNote={refetch}
              handleButtonPress={this.onButtonPress}
              unprocess={this.unprocess}
            />
          </Frame>}

        {note && (
          <ImDoneModal
            verbatimNote={note}
            actionName={actionRequested && actionRequested.name}
            clearActionRequested={clearActionRequested}
            refetch={refetch}
            transcribedSections={transcribedSections}
            history={history}
            noteId={note.id}
            forceUpdate={this.updateState}
          />
        )}

        <div style={styles.actionContainer}>
          {!loadingNote && note &&
            <div
              style={Object.assign(styles.imDoneContainer, styles.enabledImDone)}
              onClick={this.handleVerbatimDone}
              data-cy='note-btn-im-done'
              data-is-enabled={enableImDone}
              key='2'
            >
              <div key='icon-imdone' style={styles.button}>
                <img
                  alt='im done'
                  src={circleCheck}
                  height={40}
                  width={40}
                />
              </div>
              <div style={{ color: whiteSmoke, fontSize: 14 }}>
                I'm Done
              </div>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ note, general }) => {
  return ({ currentSectionId: note.currentSectionId, actionRequested: general.actionRequested })
}

const mapDispatchToProps = dispatch => ({
  setCurrentSection: id => dispatch(setCurrentSection(id)),
  clearActionRequested: () => dispatch(clearActionRequested()),
  setActionRequested: type => dispatch(setActionRequested(type))
})

const mapVerbatimNoteToProps = ({ ownProps, data }) => {
  const note = get(data, 'noteForTranscription.note')
  if (!note && !data.loading) {
    return {
      outOfNotes: true
    }
  }
  return {
    outOfNotes: false,
    errorNote: data.error,
    loadingNote: data.loading,
    refetch: data.refetch,
    note
  }
}

const mapScriptsToProps = ({ ownProps, data }) => ({
  errorScripts: data.error,
  loadingScripts: data.loading,
  scripts: data.macros && data.macros.results
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  graphql(GetNoteForVerbatimTranscription, {
    options: () => ({
      variables: {},
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapVerbatimNoteToProps
  }),
  graphql(GetScriptsByUserId, {
    skip: props => !props.note,
    options: props => ({
      variables: {
        organizationId: props.note.metadata.user.organizationId,
        userId: props.note.metadata.user.id
      },
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }),
    props: mapScriptsToProps
  }),
  withApollo,
  Radium
)(VerbatimNoteContainer)
