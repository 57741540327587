import styled from 'styled-components'

// Used for inline that needs no special styling
export const UnsupportedContent = styled.div`
  &:hover {
    cursor: not-allowed
  }
`

export const UnsupportedLabel = styled.div`
  margin-top: 10px;
  font-size: 10pt;
`
