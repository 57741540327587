export const charcoal = '#29404F'
export const buttonShadow = 'rgba(0,0,0,0.5)'
export const slate = '#ACBCC3'
export const slateGrey = '#5E798E'
export const platinum = '#D9E2EC'
export const fulvous = '#DF6B06'
export const casal = '#2D5E60'
export const whiteSmoke = '#F5F7FA'
export const sideBarVeil = 'rgba(0,0,0,0.12)'
export const scribeGreen = '#417505'
export const scribeSectionBorder = '#707070'
export const scribeLightGreen = '#88B48B'
export const scribeLightRed = '#FF8B8B'
export const sukiWhite = '#FFFFFF'
export const subtleGrey = '#D3D3D3'

/* Ops Evo */
export const textDarker = '#212121'
export const textDark = '#29404E'
export const textLight = '#446375'
export const textLightest = '#AEAEAF'

export const warning = '#FF6209'
export const opsTeal = '#90FDDE'
export const opsGreen = '#A3CA73'
export const adminOrange = '#FF9E14'

export const backgroundWhite = 'white'
export const backgroundLightGray = '#f9fafb'
export const backgroundGray = '#D9E3ED'

export const borderLightGray = '#E0E0E0'
export const borderGray = '#BFC9D3'
