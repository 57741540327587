import gql from 'graphql-tag'

export const ValidateScripts = gql`
  query ValidateScripts($organizationId: ID) {
    validateScripts(input: { 
      organizationId: $organizationId
    }) {
      count
      invalid {
        id
        userId
        sectionId
      }
    }
  }
`

export const ValidateNoteTypes = gql`
  query ValidateNoteTypes($organizationId: ID) {
    validateNoteTypes(input: {
      organizationId: $organizationId
    }) {
      count
      invalid {
        id
        userId
        sectionId
      }
    }
  }
`

export const CleanupNoteTypes = gql`
  query CleanupNoteTypes($organizationId: ID) {
    cleanupNoteTypes(input: {
      organizationId: $organizationId
    }) {
      count
      invalid {
        id
        userId
      }
    }
  }
`
