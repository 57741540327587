/*
 * Collection of configuration which would be impractical to retrieve from the server, and should not contain
 * any sensitive information.
 */
import { ENVIRONMENTS } from '../lib/constants'
import history from '../history'
export { default as loSto } from './loSto'

const isEnvironmentDev = () => window.location.origin.match(/dev/i)
const isEnvironmentTest = () => window.location.origin.match(/test/i)
const isEnvironmentStage = () => window.location.origin.match(/stage/i)
const isEnvironmentProd = () => window.location.origin.match(/suki\.ai/i)

const PROD_OKTA_URL = 'https://suki-api.okta.com'
const PROD_OKTA_OAUTH = 'https://suki-api.okta.com/oauth2/default'

const SANDBOX_OKTA_URL = 'https://suki-sandbox.oktapreview.com'
const SANDBOX_OKTA_OAUTH = 'https://suki-sandbox.oktapreview.com/oauth2/default'

let environment
let oktaClientId

if (isEnvironmentDev()) {
  environment = ENVIRONMENTS.DEV
} else if (isEnvironmentTest()) {
  environment = ENVIRONMENTS.TEST
} else if (isEnvironmentStage()) {
  environment = ENVIRONMENTS.STAGE
} else if (isEnvironmentProd()) {
  environment = ENVIRONMENTS.PROD
} else {
  environment = ENVIRONMENTS.LOCAL
}

switch (environment) {
  case ENVIRONMENTS.TEST:
    oktaClientId = '0oanr3o0oCl1Pt10N296'
    break
  case ENVIRONMENTS.STAGE:
    oktaClientId = '0oao631r02pkumbg9296'
    break
  case ENVIRONMENTS.PROD:
    oktaClientId = '0oao6vviukKraQDa1296'
    break
  case ENVIRONMENTS.DEV:
  case ENVIRONMENTS.LOCAL:
  default:
    oktaClientId = '0oanh5ksoyJoJT03o0h7'
    break
}

export const OktaConfig = {
  // the auth config data used to initialize the okta Auth object
  auth: {
    history: history,
    issuer: isEnvironmentProd() || isEnvironmentStage() || isEnvironmentTest() ? PROD_OKTA_OAUTH : SANDBOX_OKTA_OAUTH,
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: oktaClientId,
    pkce: false,
    onAuthRequired: () => history.push('/')
  },
  baseURL: isEnvironmentProd() || isEnvironmentStage() || isEnvironmentTest() ? PROD_OKTA_URL : SANDBOX_OKTA_URL
}

export const getIntercomID = () => {
  return isEnvironmentProd() ? 'ft0cgnvb' : 'sonf2jx4'
}

export const ENVIRONMENT = environment

export const BASE_URL = window.location.origin.includes('ops') ? window.location.origin.replace('ops', 'api') : window.location.origin

export const CONFIG = {
  analytics: {
    trackingId: 'UA-100205469-2'
  },

  auth: {
    oktaClientId
  },

  environment,

  graphql: {
    uri: `${BASE_URL}/graphql`
  },

  hacks: {
    forceDebug: false
  },

  // TODO: Get a more permanent MSFT key. The one below is just a free trial key.
  msftKey: '2fe916ad3203415783f5dc598d12748e'
}
