import React, { Component } from 'react'
import { LoginModal } from '../components/Login'
import logo from '../images/suki-vertical.svg'
import { whiteSmoke } from '../styles/colors'

const styles = {
  wallpaper: {
    background: whiteSmoke,
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    overflow: 'hidden',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  half: {
    width: '50vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-10vh'
  },
  login: {
    width: 350,
    marginRight: 200,
    marginTop: 50
  },
  logo: {
    height: 360,
    width: 280,
    marginBottom: -60,
    marginLeft: 100
  },
  secondaryText: {
    fontSize: 48,
    fontWeight: 300,
    marginTop: 50,
    marginLeft: 100
  }
}

class Login extends Component {
  render () {
    return (
      <div style={styles.wallpaper}>
        <div style={styles.half}>
          <img src={logo} alt='Suki Logo' style={styles.logo} />
          <div style={styles.secondaryText}>Ops</div>
        </div>
        <div style={styles.half}>
          <LoginModal style={styles.login} />
        </div>
      </div>
    )
  }
}

export default Login
