import React from 'react'
import AltOption from '../images/alt-option_double_tap.svg'
import ArrowBack from '../images/arrow_back.svg'
import ArrowForward from '../images/arrow_right.svg'
import ArrowUp from '../images/arrow_up.svg'
import ArrowDown from '../images/arrow_down.svg'
import Shift from '../images/shift_left.svg'
import EnterReturn from '../images/enter_return.svg'
import Space from '../images/space.svg'
import Plus from '../images/plus.svg'
import J from '../images/J.svg'
import K from '../images/K.svg'
import ToAdminView from '../images/admin_view_img.png'
import CombineAudio from '../images/combine_audio_img.png'
import ScriptsImg from '../images/script_img.png'
import { whiteSmoke } from '../styles/colors'

// TODO: Put these in a string package eventually.
const STRING = {
  PLAY_PAUSE: 'Play and pause audio',
  JUMP_FORWARD: 'Jump forward 3 seconds',
  JUMP_BACKWARD: 'Jump backward 3 seconds',
  INCREASE_SPEED: 'Increase playback speed',
  DECREASE_SPEED: 'Decrease playback speed',
  PREV_SECTION: 'Go to previous section',
  NEXT_SECTION: 'Go to next section',
  ENTER_TEXT_EDITOR: 'To enter the text editor',
  TO_ADMIN_VIEW: 'This is available for users with the Admin role. Click this to open the note in Admin view',
  COMBINE_AUDIO: 'When multiple audio files are present, click this checkbox to combine them into a single audio file. This can make it easier to control the audio.',
  SCRIPT_SHORTCUT: 'Click this button to reveal a list of available scripts for the section.'
}

const styles = {
  infoHeader: {
    fontSize: '20px',
    fontWeight: 600,
    margin: '22px 0px 32px 0px'
  },
  infoModalContainer: {
    position: 'absolute',
    margin: '0px auto 30px auto',
    left: 0,
    right: 0,
    bottom: 0,
    top: 80,
    width: '60vw',
    height: '88vh',
    backgroundColor: whiteSmoke,
    padding: '30px 24px 30px 24px',
    overflow: 'scroll'
  },
  infoTitle: {
    fontSize: '22px',
    fontWeight: 600,
    marginBottom: '20px'
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '300px'
  },
  featureDesc: {
    margin: '20px'
  },
  featuresSect: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  plusKey: {
    margin: '0px 4px'
  },
  shortcutKeys: {
    width: '252px',
    margin: '0px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  shortcutTip: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px'
  },
  subheader: {
    fontSize: '16px',
    fontWeight: 600,
    marginLeft: '300px'
  }
}

class NoteInfoModal extends React.Component {
  renderShortcutTip = (desc, firstKey, secondKey) => (
    <div style={styles.shortcutTip}>
      <div style={styles.shortcutKeys}>
        <img src={firstKey.src} alt={firstKey.src} style={firstKey.style} />
        {secondKey && <img src={Plus} alt='plus' style={styles.plusKey} />}
        {secondKey && <img src={secondKey.src} alt={secondKey.src} style={secondKey.style} />}
      </div>
      <span>{desc}</span>
    </div>
  )

  render () {
    const { isAdmin } = this.props
    return (
      <div
        style={styles.infoModalContainer}
        id='info-modal'
        data-cy='info-modal'
      >
        <div style={styles.infoTitle}>Help and Info</div>
        <hr />
        <div style={styles.infoHeader}>Audio Controls</div>
        {this.renderShortcutTip(
          STRING.PLAY_PAUSE,
          { src: Shift },
          { src: Space })}
        <span style={styles.subheader}>Jump Audio 3 Sec</span>
        {this.renderShortcutTip(
          STRING.JUMP_FORWARD,
          { src: Shift },
          { src: ArrowForward })}
        {this.renderShortcutTip(
          STRING.JUMP_BACKWARD,
          { src: Shift },
          { src: ArrowBack })}
        <span style={styles.subheader}>Control Audio Speed</span>
        {this.renderShortcutTip(
          STRING.INCREASE_SPEED,
          { src: Shift },
          { src: ArrowUp })}
        {this.renderShortcutTip(
          STRING.DECREASE_SPEED,
          { src: Shift },
          { src: ArrowDown })}
        <hr />
        <div style={styles.infoHeader}>Basic Navigation</div>
        <span style={styles.subheader}>Move Focus</span>
        {this.renderShortcutTip(
          STRING.PREV_SECTION,
          { src: AltOption },
          { src: J })}
        {
          this.renderShortcutTip(
            STRING.NEXT_SECTION,
            { src: AltOption },
            { src: K })
        }
        <span style={styles.subheader}>While section in focus</span>
        {
          this.renderShortcutTip(
            STRING.ENTER_TEXT_EDITOR,
            { src: EnterReturn })
        }
        <hr />
        <div style={styles.infoHeader}>Features</div>
        <div style={styles.featuresSect}>
          {isAdmin && (
            <div style={styles.feature} data-cy='admin-view-feature-tip'>
              <img src={ToAdminView} alt='How to access the admin view' />
              <div style={styles.featureDesc}>
                {STRING.TO_ADMIN_VIEW}
              </div>
            </div>
          )}
          <div style={styles.feature}>
            <img src={CombineAudio} alt='How to combine audio' />
            <div style={styles.featureDesc}>
              {STRING.COMBINE_AUDIO}
            </div>
          </div>
          <div style={styles.feature}>
            <img src={ScriptsImg} alt='How to access scripts' />
            <div style={styles.featureDesc}>
              {STRING.SCRIPT_SHORTCUT}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NoteInfoModal
